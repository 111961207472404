/* eslint-disable no-return-assign */
import React, { useEffect, useState, memo, useRef, useCallback } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Form } from '@unform/web';
import { debounce } from 'lodash';

import { Select, MainContainer } from '~/components';
import { LoadingCard } from '~/components/Shimmer';
import { mountOptionList } from '~/functions';
import * as routes from '~/routes/config';
import api from '~/services/api';
import { updateEvaluationCycleOptions, updateEvaluationCycleInUse } from '~/store/modules/cycle/actions';
import { updateBreadcrumb } from '~/store/modules/user/actions';

import { DepartmentContainer, ChartContainer, ReportContainer, UpContainer, Container } from './styles';

function Results() {
  const dispatch = useDispatch();
  const [departmentList, setDepartmentList] = useState([]);
  const [doesNotAttendList, setDoesNotAttendList] = useState([]);
  const [partiallyAttendList, setPartiallyAttendList] = useState([]);
  const [attendList, setAttendList] = useState([]);
  const [overcomesList, setOverComesList] = useState([]);
  const [chartData, setChartData] = useState([]);
  const optionsSetted = useSelector((state) => state.user.options);
  const [cycleList, setCycleList] = useState([]);
  const cycle = useSelector((state) => state.cycle);
  const formRef = useRef(null);

  const chartOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Colaboradores por Tipo de Classificação',
        position: 'top',
        color: '#666',
      },
      legend: {
        position: 'bottom',
      },
    },
  };

  useEffect(() => {
    if (optionsSetted?.resultType === 'all') {
      dispatch(
        updateBreadcrumb([
          { name: 'Início', path: '/' },
          { name: 'Configurações', path: '/sys-config' },
          { name: 'Ciclo de Avaliação', path: '/evaluation-cycle' },
          { name: 'Resultados', path: '/results' },
        ]),
      );
    } else {
      dispatch(
        updateBreadcrumb([
          { name: 'Início', path: '/' },
          { name: 'Resultados', path: '/results' },
        ]),
      );
    }

    mountOptionList('evaluationcycles', setCycleList);
  }, [dispatch, optionsSetted?.resultType]);

  useEffect(() => {
    if (cycle.id && cycleList.length > 0 && formRef.current) {
      formRef.current.setData({ cycle_id: cycle.id });
    }
  }, [cycle.id, cycleList.length]);

  useEffect(() => {
    async function getData() {
      if (!cycle.id) return;
      setDepartmentList([]);
      setDoesNotAttendList([]);
      setPartiallyAttendList([]);
      setAttendList([]);
      setOverComesList([]);

      const { data: employeesData } = await api.post(`results`, { type: optionsSetted.resultType, cycle: cycle.id });

      const {
        data: { data: classifications },
      } = await api.get('classifications');

      const classificationsValue = classifications.reduce((ac, { description: x, ...rest }) => ((ac[x] = rest), ac), {});

      const withoutIncompletes = employeesData.filter(
        (employee) =>
          employee.evaluationCycleAnswers.filter((answer) => answer.user_answer === null).length === 0 ||
          employee.evaluationCycleAnswers.filter((answer) => answer.leader_answer === null).length === 0,
      );

      const calculatedList = withoutIncompletes.map((employee) => ({
        id: employee.id,
        name: employee.name,
        department_id: employee.department_id,
        department_name: employee.departments.name,
        totalEmployee:
          employee.evaluationCycleAnswers.reduce((prev, cur) => {
            return prev + (cur.user_answer ?? 0);
          }, 0) / employee.evaluationCycleAnswers.length,
        totalLeader:
          employee.evaluationCycleAnswers.reduce((prev, cur) => {
            return prev + (cur.leader_answer ?? 0);
          }, 0) / employee.evaluationCycleAnswers.length,
        mainTotal:
          (employee.evaluationCycleAnswers.reduce((prev, cur) => {
            return prev + (cur.user_answer ?? 0);
          }, 0) /
            employee.evaluationCycleAnswers.length +
            employee.evaluationCycleAnswers.reduce((prev, cur) => {
              return prev + (cur.leader_answer ?? 0);
            }, 0) /
              employee.evaluationCycleAnswers.length) /
          2,
      }));

      let doesNotAttend = 0;
      let partiallyAttend = 0;
      let attend = 0;
      let overcomes = 0;
      calculatedList.forEach((employee) => {
        const mainTotal = Number(employee.mainTotal).toFixed(2);

        if (
          mainTotal >= classificationsValue['Não Atende']?.initial_value &&
          mainTotal <= classificationsValue['Não Atende']?.final_value
        ) {
          setDoesNotAttendList((list) => [...list, employee]);
          doesNotAttend += 1;
        }
        if (
          mainTotal >= classificationsValue['Atende Parcialmente']?.initial_value &&
          mainTotal <= classificationsValue['Atende Parcialmente']?.final_value
        ) {
          setPartiallyAttendList((list) => [...list, employee]);
          partiallyAttend += 1;
        }
        if (mainTotal >= classificationsValue.Atende?.initial_value && mainTotal <= classificationsValue.Atende?.final_value) {
          setAttendList((list) => [...list, employee]);
          attend += 1;
        }
        if (mainTotal >= classificationsValue.Supera?.initial_value && mainTotal <= classificationsValue.Supera?.final_value) {
          setOverComesList((list) => [...list, employee]);
          overcomes += 1;
        }
      });

      setChartData({
        labels: ['Não Atende', 'Atende Parcialmente', 'Atende', 'Supera'],
        datasets: [
          {
            label: 'Dados Principais',
            data: [doesNotAttend, partiallyAttend, attend, overcomes],
            backgroundColor: ['#ed1651', '#ffcb08', '#00995d', '#0a5f55'],
            hoverOffset: 4,
          },
        ],
      });

      const deparmentList = withoutIncompletes.map((employee) => employee.departments?.name);
      const uniqueDeparmentList = new Set([...deparmentList]);
      const sortedDeparments = [...uniqueDeparmentList].sort((areaA, areaB) => areaA.localeCompare(areaB, 'pt-BR'));
      setDepartmentList(sortedDeparments);
    }

    getData();
  }, [cycle.id, optionsSetted.resultType]);

  const setEmployeeToView = (employeeId) => {
    dispatch(updateEvaluationCycleOptions({ departmentId: cycle.options.departmentId, employeeId, singleView: true }));
  };

  const handleChangeSubmit = useCallback(
    // eslint-disable-line
    debounce(
      () => {
        formRef.current.submitForm();
      },
      10,
      { trailing: true },
    ),
    [],
  );

  const submitForm = ({ cycle_id }) => {
    dispatch(updateEvaluationCycleInUse({ id: cycle_id }));
  };

  return (
    <MainContainer>
      <Container>
        <Form onSubmit={submitForm} onChange={handleChangeSubmit} className='form' ref={formRef}>
          <Select
            name='cycle_id'
            placeholder='Escolha o ciclo'
            label='Escolha o Ciclo'
            options={cycleList}
            isMulti={false}
            closeMenuOnSelect
            onChange={handleChangeSubmit}
          />
        </Form>
        <h1>Resultados</h1>
        {departmentList.length > 0 && (
          <ChartContainer>
            <Doughnut data={chartData} width={300} height={300} options={chartOptions} />
          </ChartContainer>
        )}
        {departmentList.length > 0 ? (
          departmentList.map((department) => (
            <DepartmentContainer key={`${department.id}.${Math.random(1000)}.${Math.random(1000)}`}>
              <h3>{department}</h3>
              <div>
                <section className='does-not-attend'>
                  <h4>Não atende - {doesNotAttendList.filter((employee) => employee.department_name === department).length}</h4>
                  <div>
                    {doesNotAttendList.map((employee) => {
                      if (employee.department_name === department) {
                        return (
                          <Link
                            onClick={() => setEmployeeToView(employee.id)}
                            to={routes.SUMMARY_EVALUATION_EMPLOYEE}
                            target='_blank'
                            className='employee-data'
                            key={`${employee.id}.${Math.random(1000)}.${Math.random(1000)}`}>
                            {employee.name}
                          </Link>
                        );
                      }
                      return null;
                    })}
                  </div>
                </section>
                <section className='partially-attend'>
                  <h4>
                    Atende Parcialmente -{' '}
                    {partiallyAttendList.filter((employee) => employee.department_name === department).length}
                  </h4>
                  <div>
                    {partiallyAttendList.map((employee) => {
                      if (employee.department_name === department) {
                        return (
                          <Link
                            onClick={() => setEmployeeToView(employee.id)}
                            to={routes.SUMMARY_EVALUATION_EMPLOYEE}
                            target='_blank'
                            className='employee-data'
                            key={`${employee.id}.${Math.random(1000)}.${Math.random(1000)}`}>
                            {employee.name}
                          </Link>
                        );
                      }
                      return null;
                    })}
                  </div>
                </section>
                <section className='attend'>
                  <h4>Atende - {attendList.filter((employee) => employee.department_name === department).length}</h4>
                  <div>
                    {attendList.map((employee) => {
                      if (employee.department_name === department) {
                        return (
                          <Link
                            onClick={() => setEmployeeToView(employee.id)}
                            to={routes.SUMMARY_EVALUATION_EMPLOYEE}
                            target='_blank'
                            className='employee-data'
                            key={`${employee.id}.${Math.random(1000)}.${Math.random(1000)}`}>
                            {employee.name}
                          </Link>
                        );
                      }
                      return null;
                    })}
                  </div>
                </section>
                <section className='overcomes'>
                  <h4>Supera - {overcomesList.filter((employee) => employee.department_name === department).length}</h4>
                  <div>
                    {overcomesList.map((employee) => {
                      if (employee.department_name === department) {
                        return (
                          <Link
                            onClick={() => setEmployeeToView(employee.id)}
                            to={routes.SUMMARY_EVALUATION_EMPLOYEE}
                            target='_blank'
                            className='employee-data'
                            key={`${employee.id}.${Math.random(1000)}.${Math.random(1000)}`}>
                            {employee.name}
                          </Link>
                        );
                      }
                      return null;
                    })}
                  </div>
                </section>
              </div>
            </DepartmentContainer>
          ))
        ) : (
          <ReportContainer>
            <UpContainer>
              <div>
                <LoadingCard />
              </div>
            </UpContainer>
          </ReportContainer>
        )}
      </Container>
    </MainContainer>
  );
}

export default memo(Results);
