import React, { memo } from 'react';

import Skeleton from '~/components/Skeleton';

import { Container } from './styles';

function LoadingCard() {
  return (
    <Container>
      <Skeleton className='card-skeleton' />
    </Container>
  );
}

export default memo(LoadingCard);
