import { toast } from 'react-toastify';

import { put, all, takeLatest } from 'redux-saga/effects';

import { toggleModalRequest } from './actions';

export function* toggleModal({ payload }) {
  try {
    const { title, content, entity_description, visible } = payload.data;

    yield put(toggleModalRequest({ title, content, entity_description, visible }));
  } catch (err) {
    toast.error(`Não foi possível abrir o item solicitado ${err.message}`);
  }
}

export default all([takeLatest('@user/TOGGLE_MODAL_REQUEST', toggleModal)]);
