import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  div {
    width: 100%;
  }

  p {
    width: 100%;

    &:hover {
      background: #c4cbcf;
    }
  }
`;

export const StyledInput = styled.input`
  background: transparent;
  border: 0;
  border-radius: 4px;
  padding: 0 15px 0 0px;
  color: #333;
  width: 100%;

  &::placeholder {
    color: #888;
  }

  &:focus {
    background: #fff0c7;
  }
`;
