import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #0a5f55, #00995d);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
