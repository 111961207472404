import React, { memo, useEffect, useState, useRef, useCallback } from 'react';
import { MdDateRange } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';

import { Form } from '@unform/web';
import * as func from 'functions';
import { debounce } from 'lodash';

import { Select, MainContainer } from '~/components';
import enumDevelopmentPlans from '~/enums/developmentPlans';
import { mountOptionList } from '~/functions';
import api from '~/services/api';
import { updateEvaluationCycleInUse } from '~/store/modules/cycle/actions';
import { updateBreadcrumb } from '~/store/modules/user/actions';

import { Container, PlanContainer, StatusPill } from './styles';

function DevelopmentPlan() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { name: 'Início', path: '/' },
        { name: 'Seu Plano de Desenvolvimento Individual', path: '/employee-development-plan' },
      ]),
    );
  }, [dispatch]);

  const user = useSelector((state) => state.user);
  const cycle = useSelector((state) => state.cycle);
  const [cycleList, setCycleList] = useState([]);
  const [developmentPlanList, setDevelopmentPlanList] = useState([]);
  // const [continuousDevelopmentPlan, setContinuousDevelopmentPlan] = useState([]);

  const formRef = useRef();

  useEffect(() => {
    if (cycle.id && cycleList.length) {
      formRef.current.setData({ cycle_id: cycle.id });
    }
  }, [user.id, cycleList.length, cycle.id]);

  const submitForm = ({ cycle_id }) => {
    dispatch(updateEvaluationCycleInUse({ id: cycle_id }));
  };

  const handleChangeSubmit = useCallback( // eslint-disable-line
    debounce(
      () => {
        formRef.current.submitForm();
      },
      10,
      { trailing: true },
    ),
    [],
  );

  useEffect(() => {
    mountOptionList('evaluationcycles', setCycleList);
  }, []);

  useEffect(() => {
    async function getData() {
      const { data: evaluationDevelopment } = await api.get(
        `evaluationcycledevelopmentplans?evaluation_cycle_id=${cycle.id}&employee_id=${user.id}`,
      );
      const { data: continuousDevelopment } = await api.get(`continuousfeedbackdevelopmentplan/${user.id}`);

      setDevelopmentPlanList([...evaluationDevelopment, ...continuousDevelopment]);
    }

    if (cycle.id && user.id) getData();
  }, [cycle.id, user.id]);

  return (
    <MainContainer>
      <Container>
        <Form onSubmit={submitForm} onChange={handleChangeSubmit} className='form' ref={formRef}>
          <Select
            name='cycle_id'
            placeholder='Ciclo'
            label='Escolha o Ciclo'
            options={cycleList}
            isMulti={false}
            closeMenuOnSelect
            onChange={handleChangeSubmit}
          />
        </Form>
        <PlanContainer planListLength={developmentPlanList.length}>
          {developmentPlanList.length > 0 &&
            developmentPlanList.map((plan) => (
              <div key={plan.id}>
                <header>
                  <h3>
                    {plan.developmentPlans?.action ?? 'Desenvolvimento contínuo'}
                    {plan.developmentPlans?.action === 'Outros' && ` - ${plan.others}`}
                  </h3>
                  <StatusPill color={Number(plan.status)}>{enumDevelopmentPlans[Number(plan.status)]}</StatusPill>
                </header>
                <section className='section-body'>{plan.action}</section>
                <section className='section-period'>
                  <MdDateRange size={20} color='#ed1651' /> Período: {func.formatDate(plan.initial_date)} à{' '}
                  {func.formatDate(plan.final_date)}
                </section>
              </div>
            ))}
        </PlanContainer>
      </Container>
    </MainContainer>
  );
}

export default memo(DevelopmentPlan);
