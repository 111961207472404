import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100% -20px);
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    max-width: 100%;

    .area-description {
      h4 {
        margin: 0 20px;
        border-bottom: 1px dotted #ccc;
        color: #666;
      }
    }
  }
  section {
    width: 100%;
  }

  h1 {
    display: flex;
    align-items: left;
    flex-direction: column;
    font-size: 25px;
    text-align: left;
  }
  input {
    display: flex;
    align-items: center;
    border-radius: 30px;
    border: solid 1px;
    width: 30rem;
    flex-direction: column;
    margin: 50px;
    padding: 0.5rem;
  }
  .treinamentos {
    align-items: center;
    display: flex;
    text-align: center;
  }
  .react-select-unimed__value-container css-g1d714-ValueContainer {
    height: 30px !important;
  }
  .form {
    height: min-content !important;
  }

  .treinamentos1 {
    margin-bottom: 20px;
    flex-direction: row;
  }
  .Cursos {
    width: 100%;
    font-weight: bold;
    margin-top: 3rem;
    font-size: 1.5rem;
    border-radius: 10px;
    border: solid 2px #aaa;
    padding: 15px;
    font-family: 'Roboto', sans-serif;
    color: #0a5f55;
    float: left;
    height: 50px;
  }

  .curso1 {
    min-width: 36rem;
    font-weight: bold;
    font-size: 1.5rem;
    height: 20rem;
    border-radius: 10px;
    color: #0a5f55;
    padding: 10px;
    border: solid 2px #aaa;
    float: left;
    font-family: 'Roboto', sans-serif;
  }
  .curso2 {
    width: 36rem;
    font-weight: bold;
    font-size: 1.5rem;
    height: 20rem;
    border-radius: 10px;
    color: #0a5f55;
    border: solid 2px #aaa;
    padding: 10px;
    float: right;
    font-family: 'Roboto', sans-serif;
  }

  .title {
    font-family: 'Roboto', sans-serif;
    color: #0a5f55;
  }
  .firtOption {
    color: #ffff;
  }

  .Setores {
    width: 19%;
    font: 500 1.6rem 'Roboto', sans-serif;
    border-radius: 10px;
    border: solid 2px #00995d;
    padding: 5px;
    color: #0a5f55;
    float: left;
    margin-right: 39%;
    margin-left: 6%;
    margin-top: 9%;
    margin-bottom: 3%;
  }
  .footer {
    height: 10px;
  }

  .cycle_id {
    width: 100%;
    margin-bottom: 3rem;
    margin-top: 3rem;
    height: 3rem !important;
  }
  .data {
    float: left;
    margin-top: 4rem;
    padding: 0px;
    border: none;
    font-family: 'Roboto', sans-serif;
    width: 11%;
    flex-direction: row;
    color: #0a5f55;
    display: flex;
    background: #fff;
    opacity: 1;
    transition: all 0.2s ease 0s;
    align-items: center;
  }
  .button {
    flex-direction: column !important;
  }
  .buttonAdicionar {
    margin: 85px 8px 0px 140px;
    align-items: center;
    float: left;
    font-family: 'Roboto', sans-serif;
    color: #0a5f55;
    padding: 0.5em;
    border: solid 2px #00995d;
    border-radius: 10px;
    background-color: #ffffff;
    flex-direction: column;
  }
  .buttonRemover {
    margin: 85px 8px 0px 0px;
    align-items: center;
    float: left;
    font-family: 'Roboto', sans-serif;
    color: #0a5f55;
    padding: 0.5em;
    border: solid 2px #00995d;
    border-radius: 10px;
    background-color: #ffffff;
    flex-direction: column;
  }
  .buttonCadastrar {
    margin-top: 3rem;
    align-items: center;
    float: left;
    font-family: 'Roboto', sans-serif;
    color: #0a5f55;
    padding: 0.5em;
    border: solid 2px #00995d;
    border-radius: 10px;
    background-color: #ffffff;
    flex-direction: column;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 8px 15px 12px 15px;
  border-radius: 5px;
  margin-bottom: 10px;
  min-height: 68px;

  h1 {
    color: #222;
    display: flex;
    align-items: initial;

    @media (max-width: 768px) {
      font-size: 2rem;
    }

    small {
      color: #0a5f55;
      font-size: 1.2rem;
      margin-left: 10px;
    }
  }

  b {
    span {
      color: #411564;
    }
  }

  span {
    color: #aaa;
    font-weight: 200;
  }

  @media (max-width: 768px) {
    margin: 1px 20px 20px 20px;
    padding: 10px 15px 12px 15px;
    flex-direction: column;
  }
`;
export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
