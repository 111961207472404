import axios from 'axios';

export const baseUrl =
  process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: baseUrl,
  timeout: 180000,
});

export default api;
