import styled, { keyframes } from 'styled-components';

const donutfade = keyframes`
    /* this applies to the whole svg item wrapper */
    0% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
`;

const donutfadelong = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`;

const dasharray = '99 1';

const donut1 = keyframes`
  0% {
    stroke-dasharray: 0, 100;
  }
  100% {
    stroke-dasharray: ${dasharray};
  }
`;

export const Container = styled.div`
  width: ${(props) => props.width};
  font-size: 16px;
  margin: 0 auto;
  animation: ${donutfade} 1s;
  max-width: 100%;
  display: inline-block;

  @media (min-width: 992px) {
    .svg-item {
      width: 80%;
    }
  }

  .donut-ring {
    stroke: #1e212b;
  }

  .donut-segment {
    transform-origin: center;
    stroke: #ff6200;
  }

  .donut-segment-2 {
    stroke: ${(props) => props.color};
    animation: ${donut1} 3s;
  }

  .segment-1 {
    fill: #ccc;
  }
  .segment-2 {
    fill: ${(props) => props.color};
  }

  .donut-percent {
    animation: ${donutfadelong} 1s;
  }

  .donut-text {
    font-family: Arial, Helvetica, sans-serif;
    fill: #ff6200;
  }
  .donut-text-1 {
    fill: ${(props) => props.color};
  }

  .donut-label {
    font-size: 0.28em;
    font-weight: 700;
    line-height: 1;
    fill: #000;
    transform: translateY(0.25em);
  }

  .donut-percent {
    font-size: 0.6rem;
    line-height: 1;
    transform: translateY(0.5em);
    font-weight: bold;
  }

  .donut-data {
    font-size: 0.38rem;
    line-height: 2;
    transform: translateY(0.5em);
    text-align: center;
    text-anchor: middle;
    color: #49576d;
    fill: #49576d;
    animation: ${donutfadelong} 1s;
  }
`;
