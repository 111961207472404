import styled from 'styled-components';

export const Container = styled.div``;

export const TitleStyled = styled.span`
  font-size: 1.35rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.002 !important;
  color: #ffcb08;
`;

export const EntityNameConfirmation = styled.span`
  color: #b1d34b !important;
  font-size: 1.35rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.002 !important;
`;

export const AnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 10px;

  h4 {
    font-size: 0.8em;
    text-align: justify;
    font-weight: 300;
    color: #111;
    /* margin-bottom: 10px; */
    /* border-left: 3px solid #444; */
    padding: 7px 10px 6px 10px;
    background: rgba(200, 200, 200, 0.6);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  label {
    font-size: 1.1em;
    color: #000;
  }
`;

export const RatingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0 0 10px;
  background: #efefef;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;
