export const Types = {
  UPDATE_EVALUATION_CYCLE_IN_USE: '@cycle/UPDATE_EVALUATION_CYCLE_IN_USE',
  UPDATE_EVALUATION_CYCLE_OPTIONS: '@cycle/UPDATE_EVALUATION_CYCLE_OPTIONS',
};

export function updateEvaluationCycleInUse(data) {
  return {
    type: Types.UPDATE_EVALUATION_CYCLE_IN_USE,
    payload: data,
  };
}

export function updateEvaluationCycleOptions(data) {
  return {
    type: Types.UPDATE_EVALUATION_CYCLE_OPTIONS,
    payload: data,
  };
}
