import { createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import { Types as WsTypes } from './actions';

export const INITIAL_STATE = Immutable({});

export default createReducer(INITIAL_STATE, {
  [WsTypes.SUBSCRIBE_USER_REQUEST]: (state) => state,
});
