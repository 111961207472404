import { Link } from 'react-router-dom';

import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  @media (max-width: 1224px) {
    padding: 0 15px;
  }

  @media (max-width: 1200px) {
    padding: 0 20px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 0 15px;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fff;
  padding: 8px 15px 10px 15px;
  border-radius: 5px;
  margin-bottom: 8px;
  min-height: 51px;

  h3 {
    color: #222;
  }

  span {
    color: #444;
  }

  div.area-summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    span {
      text-align: right;

      @media (max-width: 768px) {
        min-width: 115px;
      }
    }
  }
`;

export const EmployeesContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`;

export const UpContainer = styled.div`
  width: 100%;
  padding: 10px 0;
  overflow: hidden;

  @media (max-width: 768px) {
    max-width: 100%;
  }

  & > div {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 1fr 1fr;
    grid-template-rows: minmax(auto-fill, 130px);
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    min-height: 130px; /* NEW */
    min-width: 0; /* NEW; needed for Firefox */

    @media (max-width: 1040px) {
      grid-template-columns: minmax(0, 1fr) 1fr;
    }

    @media (max-height: 825px) {
      grid-template-rows: minmax(auto-fill, 130px);
    }

    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: minmax(auto-fill, 130px);
      grid-row-gap: 20px;
      min-height: 130px; /* NEW */
      min-width: 0; /* NEW; needed for Firefox */
    }
  }
`;

export const CheckedCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: rotate(25deg);
  width: 90px;
  height: 90px;
  margin-left: 35px;
  border: 3px dashed #444;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  color: #444;
  font-size: 18px;
  font-weight: 700;
`;

export const CardContainer = styled(Link)`
  text-decoration: none;

  a {
    text-decoration: none;
  }

  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  opacity: 1;
  border-radius: 8px;
  transition: all 0.2s ease 0s;
  padding: 10px 10px 10px 20px;
  /* border: 2px solid rgb(0, 0, 0, 0) !important; */
  border-image: initial;
  /* box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12); */
  position: relative;

  img {
    width: 56px;
    height: 56px;
  }

  &:hover {
    /* border: 1px solid #b1d34b !important; */
    cursor: pointer;
    transform: translate3d(0, -2%, 0);
    background: #ddd;
  }

  h1 {
    font-size: 30px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }

  h4 {
    color: #333;
    margin-bottom: 5px;
  }

  div.employee-description {
    display: inline-block;
    width: 98%;

    .card-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      align-content: center;
      width: 100%;
      background-image: linear-gradient(to right, #0a5f55, #999);
      background-position: bottom left;
      background-repeat: no-repeat;
      background-size: 80% 1px;
      transition: background-size 2s ease;
      margin-bottom: 5px;

      h4 {
        color: #444;
        margin: 0;
      }

      @media (max-width: 768px) {
        padding-bottom: 5px;
      }
    }

    h4 {
      text-transform: uppercase;
    }

    span {
      display: block;
      font-size: 12px;
      line-height: 1.5;
      color: rgba(91, 92, 101, 1);
      text-transform: capitalize;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  b {
    color: #555;
  }

  @media (max-width: 768px) {
    padding: 15px !important;
  }

  &:after {
    content: '';
    display: ${(props) => (props.finished === 'true' ? 'block' : 'none')};
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 40px 40px !important;
    border-color: #f2f3f7 #f2f3f7 #f2f3f7 #0a5f55;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &:after {
    content: '';
    display: ${(props) => (props.withoutform === 'true' ? 'block' : 'none')};
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 40px 40px !important;
    border-color: #f2f3f7 #f2f3f7 #f2f3f7 #ed1651;
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;
