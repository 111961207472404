import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  max-width: 100em;

  button {
    border: none;
    background: none;

    &:hover {
      opacity: 1;
    }
  }

  .tree {
    float: left;
    width: 100%;

    @media (max-width: 1325px) {
      zoom: 85%;
    }
  }

  ol.organizational-chart,
  ol.organizational-chart ol,
  ol.organizational-chart li,
  ol.organizational-chart li > button {
    position: relative;
    font-size: 12px;
    font-weight: 300 !important;
  }

  ol.organizational-chart,
  ol.organizational-chart ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ol.organizational-chart {
    text-align: center;
  }

  ol.organizational-chart ol {
    padding-top: 1em;
  }

  ol.organizational-chart ol:before,
  ol.organizational-chart ol:after,
  ol.organizational-chart li:before,
  ol.organizational-chart li:after,
  ol.organizational-chart > li > button:before,
  ol.organizational-chart > li > button:after {
    background-color: #333;
    content: '';
    position: absolute;
  }

  ol.organizational-chart ol > li {
    padding: 1em 0 0 0.9em;
  }

  ol.organizational-chart > li ol:before {
    height: 1em;
    left: 50%;
    top: 0;
    width: 3px;
  }

  ol.organizational-chart > li ol:after {
    height: 3px;
    left: 3px;
    top: 1em;
    width: 50%;
  }

  ol.organizational-chart > li ol > li:not(:last-of-type):before {
    height: 3px;
    left: 0;
    top: 2em;
    width: 1em;
  }

  ol.organizational-chart > li ol > li:not(:last-of-type):after {
    height: 100%;
    left: 0;
    top: 0;
    width: 3px;
  }

  ol.organizational-chart > li ol > li:last-of-type:before {
    height: 3px;
    left: 0;
    top: 2em;
    width: 1em;
  }

  ol.organizational-chart > li ol > li:last-of-type:after {
    height: 2em;
    left: 0;
    top: 0;
    width: 3px;
  }

  ol.organizational-chart li > button {
    background-color: #fff;
    border-radius: 3px;
    min-height: 4em;
    width: 100%;
    padding: 0.5em;
  }

  /*** PRIMARY ***/
  ol.organizational-chart > li > button {
    background-color: rgba(10, 95, 85, 0.5);
    border: 2px solid #0a5f55;
    margin-right: 1em;
    max-width: 200px;
  }

  ol.organizational-chart > li > button:before {
    bottom: 2em;
    height: 3px;
    right: -1em;
    width: 1em;
  }

  ol.organizational-chart > li > button:first-of-type:after {
    bottom: 0;
    height: 2em;
    right: -1em;
    width: 3px;
  }

  ol.organizational-chart > li > button + button {
    margin-top: 1em;
  }

  ol.organizational-chart > li > button + button:after {
    height: calc(100% + 1em);
    right: -1em;
    top: -1em;
    width: 3px;
  }

  /*** SECONDARY ***/
  ol.organizational-chart > li > ol:before {
    left: inherit;
    right: 0;
  }

  ol.organizational-chart > li > ol:after {
    left: 0;
    width: 100%;
  }

  ol.organizational-chart > li > ol > li > button {
    background-color: rgba(0, 153, 93, 0.5);
    border: 2px solid #00995d;
  }

  /*** TERTIARY ***/
  ol.organizational-chart > li > ol > li > ol > li > button {
    background-color: rgba(65, 21, 100, 0.4);
    border: 2px solid #411564;
  }

  /*** QUATERNARY ***/
  ol.organizational-chart > li > ol > li > ol > li > ol > li > button {
    background-color: rgba(244, 121, 32, 0.5);
    border: 2px solid #f47920;
  }

  /*** QUINARY ***/
  ol.organizational-chart > li > ol > li > ol > li > ol > li > ol > li > button {
    background-color: rgba(255, 203, 8, 0.5);
    border: 2px solid #ffcb08;
  }

  /*** MEDIA QUERIES ***/
  @media only screen and (min-width: 64em) {
    ol.organizational-chart {
      margin-left: -1em;
      margin-right: -1em;
    }

    /* PRIMARY */
    ol.organizational-chart > li > button {
      display: inline-block;
      float: none;
      margin: 0 1em 1em 1em;
      vertical-align: bottom;
    }

    ol.organizational-chart > li > button:only-of-type {
      margin-bottom: 0;
      width: calc((100% / 1) - 2em - 4px);
    }

    ol.organizational-chart > li > button:first-of-type:nth-last-of-type(2),
    ol.organizational-chart > li > button:first-of-type:nth-last-of-type(2) ~ button {
      width: calc((100% / 2) - 2em - 4px);
    }

    ol.organizational-chart > li > button:first-of-type:nth-last-of-type(3),
    ol.organizational-chart > li > button:first-of-type:nth-last-of-type(3) ~ button {
      width: calc((100% / 3) - 2em - 4px);
    }

    ol.organizational-chart > li > button:first-of-type:nth-last-of-type(4),
    ol.organizational-chart > li > button:first-of-type:nth-last-of-type(4) ~ button {
      width: calc((100% / 4) - 2em - 4px);
    }

    ol.organizational-chart > li > button:first-of-type:nth-last-of-type(5),
    ol.organizational-chart > li > button:first-of-type:nth-last-of-type(5) ~ button {
      width: calc((100% / 5) - 2em - 4px);
    }

    ol.organizational-chart > li > button:before,
    ol.organizational-chart > li > button:after {
      bottom: -1em !important;
      top: inherit !important;
    }

    ol.organizational-chart > li > button:before {
      height: 1em !important;
      left: 50% !important;
      width: 3px !important;
    }

    ol.organizational-chart > li > button:only-of-type:after {
      display: none;
    }

    ol.organizational-chart > li > button:first-of-type:not(:only-of-type):after,
    ol.organizational-chart > li > button:last-of-type:not(:only-of-type):after {
      bottom: -1em;
      height: 3px;
      width: calc(50% + 1em + 3px);
    }

    ol.organizational-chart > li > button:first-of-type:not(:only-of-type):after {
      left: calc(50% + 3px);
    }

    ol.organizational-chart > li > button:last-of-type:not(:only-of-type):after {
      left: calc(-1em - 3px);
    }

    ol.organizational-chart > li > button + button:not(:last-of-type):after {
      height: 3px;
      left: -2em;
      width: calc(100% + 4em);
    }

    /* SECONDARY */
    ol.organizational-chart > li > ol {
      display: flex;
      flex-wrap: nowrap;
    }

    ol.organizational-chart > li > ol:before,
    ol.organizational-chart > li > ol > li:before {
      height: 1em !important;
      left: 50% !important;
      top: 0 !important;
      width: 3px !important;
    }

    ol.organizational-chart > li > ol:after {
      display: none;
    }

    ol.organizational-chart > li > ol > li {
      flex-grow: 1;
      /* padding-left: 1em; */
      /* padding-right: 1em; */
      padding-top: 1em;
    }

    ol.organizational-chart > li > ol > li:only-of-type {
      padding-top: 0;
    }

    ol.organizational-chart > li > ol > li:only-of-type:before,
    ol.organizational-chart > li > ol > li:only-of-type:after {
      display: none;
    }

    ol.organizational-chart > li > ol > li:first-of-type:not(:only-of-type):after,
    ol.organizational-chart > li > ol > li:last-of-type:not(:only-of-type):after {
      height: 3px;
      top: 0;
      width: 50%;
    }

    ol.organizational-chart > li > ol > li:first-of-type:not(:only-of-type):after {
      left: 50%;
    }

    ol.organizational-chart > li > ol > li:last-of-type:not(:only-of-type):after {
      left: 0;
    }

    ol.organizational-chart > li > ol > li + li:not(:last-of-type):after {
      height: 3px;
      left: 0;
      top: 0;
      width: 100%;
    }
  }
`;

export const Ul = styled.ol``;

export const Li = styled.li``;
