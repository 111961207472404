import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

export default createGlobalStyle`
  /* @import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap'); */

  :root {
    font-size: 60%;

    --color-background: #F2F3F7;
    --color-primary-lighter: #b1d34b;
    --color-primary-light: #00995d;
    --color-primary: #0a5f55;
    --color-primary-dark: #0a5f55;
    --color-primary-darker: #00401a;
    --color-secundary: #411564;
    --color-secundary-dark: #411564;
    --color-title-in-primary: #FFFFFF;
    --color-text-in-primary: #222;
    --color-text-title: #32264D;
    --color-text-complement: #5b5c65;
    --color-text-base: #c4cbcf;
    --color-line-in-white: #E6E6F0;
    --color-input-background: #F8F8FC;
    --color-button-text: #FFFFFF;
    --color-box-base: #FFFFFF;
    --color-box-footer: #FAFAFC;
  }

  @media (min-width: 700px) {
    :root {
      font-size: 62.5%;
    }
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0 !important;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    height: 100%;
  }

  body {
    text-rendering: optimizelegibility !important;
	  -webkit-font-smoothing: antialiased !important;
    min-height: 100vh;
  }

  body, input, button, textarea {
     font: 500 1.6rem 'Roboto', sans-serif;
    /* font: 500 1.6rem Poppins; */
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  .Toastify__toast-container {
  width: 320px;
  }

  .Toastify__toast--default {
    background: #fff;
    color: #aaa;
  }
  .Toastify__toast--info {
    background: #411564;
  }
  .Toastify__toast--success {
    background: #00995d;
  }
  .Toastify__toast--warning {
    background: #ffcb08;
  }
  .Toastify__toast--error {
    background: #ed1651;
  }

  .MuiTypography-body2, .MuiButton-label, .MuiTypography-caption, .MuiTypography-body1, .MuiTypography-subtitle1, .MuiSvgIcon-root {
    font-size: 1.8rem !important;
  }

`;
