import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100% -20px);
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    max-width: 100%;

    .area-description {
      h4 {
        margin: 0 20px;
        border-bottom: 1px dotted #ccc;
        color: #666;
      }
    }
  }

  .area-description {
    h4 {
      border-bottom: 1px dotted #ccc;
      padding-bottom: 3px;
      color: #666;
    }
  }

  .form {
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    gap: 15px;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 8px 15px 12px 15px;
  border-radius: 5px;
  margin-bottom: 10px;
  min-height: 68px;

  h1 {
    color: #222;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      font-size: 2rem;
    }

    small {
      color: #0a5f55;
      font-size: 1.2rem;
      margin-left: 10px;
    }
  }

  b {
    span {
      color: #411564;
    }
  }

  span {
    color: #aaa;
    font-weight: 200;
  }

  @media (max-width: 768px) {
    margin: 1px 20px 20px 20px;
    padding: 10px 15px 12px 15px;
    flex-direction: column;
  }
`;

export const EmployeesContainer = styled.div`
  display: flex;
  flex: 1 1 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`;

export const UpContainer = styled.div`
  width: 100%;
  padding: 10px 0;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 20px;
    max-width: 100%;
  }

  & > div {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(auto-fill, 130px);
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    min-height: 130px; /* NEW */
    min-width: 0; /* NEW; needed for Firefox */

    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: minmax(auto-fill, 130px);
      grid-row-gap: 20px;
      min-height: 130px; /* NEW */
      min-width: 0; /* NEW; needed for Firefox */
    }

    @media (max-height: 825px) {
      grid-template-rows: minmax(auto-fill, 130px);
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background: #fff;
  opacity: 1;
  border-radius: 8px;
  transition: all 0.2s ease 0s;
  padding: 10px 10px 10px 15px;
  border-image: initial;
  position: relative;

  div.employee-description {
    display: inline-block;
    width: 98%;

    .card-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      align-content: center;
      width: 100%;
      margin-bottom: 5px;

      h4 {
        color: #444;
        margin: 0;
      }

      @media (max-width: 768px) {
        padding-bottom: 5px;
      }
    }
  }

  b {
    color: #555;
  }

  @media (max-width: 768px) {
    padding: 15px !important;
  }

  div.employee-data {
    .plan-description {
      display: flex;
      flex-direction: row;
      align-items: center;

      small {
        margin-left: 10px;
        color: #f47920;
        text-transform: uppercase;
      }

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    form:first-of-type {
      border-top: 1px dotted #666;
    }

    form {
      display: flex;
      flex-direction: row !important;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      border-bottom: 1px dotted #666;
      padding: 8px 0 5px 0;

      h4 {
        color: #5b5c65;
        text-transform: uppercase;
        font-size: 1.3rem;
      }

      small {
        font-weight: 300;
        color: #888;
        text-align: justify;
        display: inherit;
        padding-right: 15px;

        @media (max-width: 865px) {
          &:last-of-type {
            margin-bottom: 15px;
          }
        }
      }

      @media (max-width: 860px) {
        flex-direction: column !important;
      }
    }

    .without-pdi {
      color: #ed1651;
      border-bottom: 1px dotted #ed1651;
      font-size: 1.4rem;
    }
  }
`;

export const ResumeContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 15px;
  align-items: center;
  align-content: center;
  gap: 15px;

  @media (max-width: 768px) {
    padding: 0 20px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    width: 100%;
    background: #fff;
    padding: 10px 0 0 0;
    border-radius: 8px;

    h6 {
      font-size: 2rem;
      color: #5b5c65;
      font-weight: 900;
    }

    &:last-child {
      display: flex;
      align-items: center;
      align-content: center;
    }
  }
`;
