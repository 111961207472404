import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

export const PlanContainer = styled.div`
  flex: 1;
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(390px, 1fr));
  grid-template-rows: repeat(auto-fit);
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  min-height: 0; /* NEW */
  min-width: 0; /* NEW; needed for Firefox */

  @media (max-width: 880px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(${(props) => props.planListLength});
    grid-row-gap: 20px;
    min-height: 0;
    min-width: 0;
  }

  > div {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 6px;
    padding: 9px 12px 12px 13px;
    color: #333;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 2px;
    }

    header > h3 {
      font-weight: 600;
      color: rgb(98, 113, 121);
    }

    header > span {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 12px;
      color: rgb(112, 123, 129);
      text-transform: uppercase;
    }

    section.section-body {
      padding: 5px 0;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 18px;
      color: rgb(78, 91, 97);
    }

    section.section-period {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      font-size: 1.3rem;
      font-weight: 500;
      line-height: 12px;
      color: rgb(112, 123, 129);

      svg {
        margin-right: 5px;
      }
    }

    section.button-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    button {
      border: none;
      padding: 5px 15px;
      border-radius: 4px;
      max-height: 30px;
      min-width: 104px;

      &:hover {
        opacity: 0.8;
      }
    }

    button.complete {
      background: #00995d;
      color: #fff;
    }

    button.waiver {
      background: #ed1651;
      color: #fff;
    }
  }
`;

const colors = ['#411564', '#00995d', '#ed1651', '#f47920'];

export const StatusPill = styled.div`
  color: ${(props) => colors[props.color]};
  font-weight: 300;
  font-size: 1.4rem;
  border-bottom: 1px dotted ${(props) => colors[props.color]};
`;
