import styled from 'styled-components';

export const Container = styled.nav`
  display: flex;
  position: relative;
  height: 20px;
  margin: 0 auto;
  max-width: 1200px;
  align-items: center;
  margin-bottom: 10px;

  @media print {
    display: none;
  }

  @media (max-width: 1230px) {
    max-width: calc(100% - 40px);
  }

  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 10px;
    padding: 0 12px;
  }

  span {
    font-weight: 300;
    font-size: 15px;
    color: #f47920;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  strong {
    font-weight: 300;
    font-size: 15px;
    color: #c4cbcf;
    margin: 0 10px;
  }

  a {
    color: #555 !important;
    font-size: 15px;
    font-weight: 300;
    /* Funcionalidade para sublinhar a linha */
    /* background-image: linear-gradient(to right, #b1d34b, #b1d34b);
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 0.001% 2px;
    transition: background-size 2s ease; */

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: color 2s ease;

    &:hover {
      color: #411564;
      transition: color 2s ease;

      opacity: 0.8;
      transition: background-size 0.5s ease;
      background-size: 100% 2px;
    }
  }
`;
