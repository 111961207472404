import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  align-items: center;
  width: 100%;

  form {
    width: 100%;
    margin-bottom: 15px;
  }
`;

export const DepartmentContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  margin: 10px 0;
  border-radius: 4px;
  padding: 10px 15px 15px 15px;

  h3 {
    color: #666;
    font-size: 1.7rem;
    margin-bottom: 5px;
  }

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 15px;

    section {
      padding: 10px;
      border-radius: 5px;

      h4 {
        border-bottom: 1px solid #fff;
        margin-bottom: 2px;
      }

      a {
        font-size: 1.1rem;
        color: inherit; /* blue colors for links too */
        text-decoration: inherit; /* no underline */
        border-bottom: 1px dotted #fff;
        width: 100%;
        display: block;
        margin-top: 5px;
      }
    }

    section.does-not-attend {
      background-color: #ed1651;
      color: #fff;
      width: 100%;
    }
    section.partially-attend {
      background-color: #ffcb08;
      color: #411564;
      width: 100%;
    }
    section.attend {
      background-color: #00995d;
      color: #fff;
      width: 100%;
    }
    section.overcomes {
      background-color: #0a5f55;
      color: #fff;
      width: 100%;
    }
  }
`;

export const ChartContainer = styled.div`
  position: relative;
  width: 350px;
  height: 350px;
`;

export const ReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0 0 0;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: space-between;

  h4 {
    margin: 10px;
  }

  @media only print {
    padding: 20px 15px;
  }
`;

export const UpContainer = styled.div`
  width: 100%;
  /* max-width: calc(100vw - 129px); */
  padding: 10px 0;
  overflow: hidden;
  align-items: stretch;

  @media (max-width: 768px) {
    padding: 10px 20px;
    max-width: 100%;
    margin-bottom: 10px;
  }

  & > div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(30rem, 30rem));
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    min-height: 0; /* NEW */
    min-width: 0; /* NEW; needed for Firefox */

    @media (max-width: 1280px) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
      grid-template-rows: repeat(auto-fit, minmax(30rem, 30rem));
      grid-row-gap: 20px;
      min-height: 0;
      min-width: 0;
    }

    @media (max-width: 860px) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
      grid-template-rows: repeat(auto-fit, minmax(30rem, 30rem));
      grid-row-gap: 20px;
      min-height: 0; /* NEW */
      min-width: 0; /* NEW; needed for Firefox */
    }

    @media (max-height: 825px) {
      grid-template-rows: repeat(280px);
    }
  }
`;
