import styled, { keyframes } from 'styled-components';

const wave = keyframes`
 50% {
      transform: scale(0.5);
    }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .container {
    display: block;
    position: relative;
    padding: 0px 30px 0 30px;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #ddd;
    border-radius: 50%;
    transition: background-color 0.3s ease-out;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #411564;
    animation: ${wave} 0.4s ease;

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      background: #411564;
      display: block;
      transform: scale(0);
      opacity: 1;
      border-radius: 50%;
    }
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    top: 5px;
    left: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #ffcb08;
  }
`;
