import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 10px;

  > div {
    .header-skeleton {
      width: 100%;
      height: 34px;
      filter: brightness(80%);
    }

    span {
      display: flex;
      flex-direction: column;
      align-items: center;

      .row-table-skeleton {
        height: 34px;
        width: 100%;

        &:nth-child(2n) {
          background: #aaa;
        }
      }
    }
  }
`;
