import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import logo from '~/assets/logo_unimed.png';
import Notifications from '~/components/Notifications';
import * as routes from '~/routes/config';
import { checkIfUserIsAdminRequest } from '~/store/modules/user/actions';

import { Container, Content, Profile } from './styles';

function Header() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const { roles } = profile ?? [];

  useEffect(() => {
    const verifyUser = async (userProfile) => {
      await dispatch(checkIfUserIsAdminRequest(userProfile));
    };

    verifyUser(profile);
  }, [dispatch, profile]);

  return (
    <Container background={roles?.includes('administrator') ? '#411564' : '#0a5f55'}>
      <Content>
        <nav>
          <Link to={routes.DASHBOARD}>
            <img className='logo-header' src={logo} alt='Crescer Unimed' />
          </Link>
          <span className='header-gestao'>
            <b>Gestão de pessoas</b> por competências
          </span>
        </nav>
        <aside>
          <Notifications />
          <Profile>
            <div>
              <strong>{profile && profile.name}</strong>
              <Link to={routes.PROFILE}>Meu Perfil</Link>
              {roles?.includes('administrator') && <Link to={routes.SYSTEM_CONFIG}>Configurar Sistema</Link>}
            </div>
          </Profile>
        </aside>
      </Content>
    </Container>
  );
}

export default memo(Header);
