import React, { memo, useState, useEffect, useRef } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Form } from '@unform/web';
import PropTypes from 'prop-types';

import { Rating } from '~/components';
import { mountOptionList } from '~/functions';

import { Container, AnswerContainer, RatingContainer } from './styles';

function CalibartionDialog({ open, setOpen, confirmAction, actionName, entityData }) {
  const [optionList, setOptionList] = useState([]);
  const formRef = useRef();

  useEffect(() => {
    mountOptionList('ratingscales', setOptionList);
  }, []);

  const handleSubmit = (data) => {
    const id = Object.keys(data)[0];
    const value = data[id][0];

    confirmAction(id, value);
  };

  return (
    <Container>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <AnswerContainer>
              <h4>{entityData?.behavior}</h4>
              <RatingContainer>
                <Form onSubmit={handleSubmit} ref={formRef}>
                  <Rating name={entityData?.id} options={optionList} />
                </Form>
              </RatingContainer>
            </AnswerContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color='secondary'>
            Cancelar
          </Button>
          <Button onClick={() => formRef.current.submitForm()} color='primary-contrastText' autoFocus>
            {actionName}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

CalibartionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired,
  entityData: PropTypes.instanceOf(Object).isRequired,
  actionName: PropTypes.string.isRequired,
};

export default memo(CalibartionDialog);
