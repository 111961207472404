import React, { useState, memo, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';

function GroupToggleButton({ text, itemId, addToList, toggled: isToggled }) {
  const [toggled, setToggled] = useState(false);

  useEffect(() => {
    setToggled(isToggled);
  }, [isToggled]);

  return (
    <Container toggled={toggled}>
      <button
        type='button'
        onClick={() => {
          setToggled(!toggled);
          addToList(itemId);
        }}>
        {text}
      </button>
    </Container>
  );
}

GroupToggleButton.propTypes = {
  text: PropTypes.string.isRequired,
  itemId: PropTypes.number.isRequired,
  addToList: PropTypes.func.isRequired,
  toggled: PropTypes.bool,
};

GroupToggleButton.defaultProps = {
  toggled: false,
};

export default memo(GroupToggleButton);
