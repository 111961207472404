import { Link } from 'react-router-dom';

import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1200px;
  height: calc(100% -20px);
  margin: 0 auto;
  display: flex;
  flex: 1 1 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  @media (max-width: 1220px) {
    max-width: calc(100% - 40px);
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const UpContainer = styled.div`
  width: 100%;
  padding: 10px 0;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 20px;
    max-width: 100%;
  }

  & > div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    grid-template-rows: 130px;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    min-height: 0; /* NEW */
    min-width: 0; /* NEW; needed for Firefox */

    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
      grid-template-rows: 160px !important;
      grid-row-gap: 20px;
      min-height: 0; /* NEW */
      min-width: 0; /* NEW; needed for Firefox */
    }

    @media (max-height: 825px) {
      grid-template-rows: 130px 130px;
    }
  }
`;

export const CardContainer = styled(Link)`
  text-decoration: none;

  a {
    text-decoration: none;
  }

  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background: #fff;
  opacity: 1;
  border-radius: 8px;
  transition: all 0.2s ease 0s;
  padding: 15px 20px;
  /* border: 2px solid rgb(0, 0, 0, 0) !important; */
  border-image: initial;
  /* box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12); */

  img {
    margin-bottom: 25px;
    width: 56px;
    height: 56px;
  }

  &:hover {
    /* border: 1px solid #b1d34b !important; */
    cursor: pointer;
    transform: translate3d(0, -1%, 0);
  }

  h1 {
    font-size: 30px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }

  h4 {
    color: #333;
  }

  span {
    display: block;
    width: 363px;
    font-size: 18px;
    line-height: 1.5;
    color: rgb(135, 134, 139);
  }

  b {
    color: #b1d34b;
    font-size: 20px;
  }

  @media (max-width: 768px) {
    padding: 30px !important;
  }
`;
