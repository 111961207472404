import produce from 'immer';

import { Types as ModalTypes } from './actions';

const INITIAL_STATE = {
  title: null,
  content: () => null,
  visible: false,
  entity_description: null,
};

export default function modal(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case ModalTypes.TOGGLE_MODAL_REQUEST: {
        draft.title = action.payload.title;
        draft.content = action.payload.content;
        draft.visible = action.payload.visible;
        draft.entity_description = action.payload.entity_description;
        break;
      }
      case ModalTypes.DESTROY_MODAL_REQUEST: {
        draft.title = null;
        draft.children = null;
        draft.visible = false;
        break;
      }
      default:
        break;
    }
  });
}
