import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1200px;
  height: calc(100% -20px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    max-width: 100%;
  }

  @media only print {
    padding: 15px;
  }

  @page {
    size: A4 portrait;
    margin: 4mm 5mm;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #ddd;
  padding: 8px 15px;
  min-height: 51px;

  .top-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo-header {
    width: 130px;
    margin-right: 15px;
  }

  h3 {
    color: #222;
  }

  span.employee-position {
    color: #888;
    font-size: 1.3rem;
  }

  div.area-summary {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    span {
      text-align: right;

      @media (max-width: 768px) {
        min-width: 115px;
      }
    }
  }

  button {
    border: none;
    padding: 0 15px;
    border-radius: 4px;
    height: 30px;
    background: #5b5c65;
    color: #fff;

    &:hover {
      opacity: 0.6;
    }
  }

  @media (max-width: 768px) {
    margin: 10px 20px 0 20px;
    padding: 10px 15px 12px 15px;
  }

  @media only print {
    width: 100%;
    margin: 0;
    button {
      display: none;
    }
  }
`;

export const ReportContainer = styled.div`
  background: #ededed;
  display: flex;
  flex-direction: column;
  padding: 20px 0 0 0;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: space-between;

  h4 {
    margin: 10px;
  }

  @media only print {
    padding: 20px 15px;
  }
`;

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;

  @media only print {
    max-width: 100%;
  }
`;

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 30px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  max-height: 130px;

  h3 {
    margin-bottom: 10px;
  }

  .label-colaborador {
    border-bottom: 0px solid #00995d;
    padding: 5px;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      margin-top: 2px;
      margin-left: -20px;
      background: #00995d;
      width: 10px;
      height: 10px;
    }
  }

  .label-lider {
    border-bottom: 0px solid #ffcb08;
    padding: 5px;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      margin-top: 2px;
      margin-left: -20px;
      background: #ffcb08;
      width: 10px;
      height: 10px;
    }
  }

  .label-conciliation {
    border-bottom: 0px solid #411564;
    padding: 5px;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      margin-top: 2px;
      margin-left: -20px;
      background: #411564;
      width: 10px;
      height: 10px;
    }
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0;
  border-top: 1px solid #ddd;

  h1 {
    margin: 0 0 10px 20px;
  }

  &:last-child {
    border-bottom: 1px solid #ddd;
  }

  div.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    width: 100%;

    @media only print {
      page-break-after: always;
    }

    h4 {
      color: #333;
    }

    b,
    p {
      font-size: 15px;
    }

    p > b {
      color: #f47920;
    }

    span {
      width: 100%;
      background: #dedede;
      padding: 15px;
      border-bottom: 1px solid #c4cbcf;
    }

    h3 {
      text-align: center;
      background: #bbb;
      padding: 10px 0;
    }

    p.justificative-description {
      margin: 10px 0;
    }

    p.employee-justificative {
      margin-bottom: 10px;
    }

    p.feedback-container {
      padding: 10px 0 0 0;
    }

    section.each-behavior {
      padding: 10px 12px;
      border-bottom: 1px solid #c4cbcf;

      p {
        margin-bottom: 10px;
        font-size: 16px;
      }

      &:nth-child(even) {
        background-color: rgba(200, 200, 200, 0.5);
      }

      &:nth-child(odd) {
        background-color: rgba(200, 200, 200, 0.2);
      }
    }

    section.score-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      align-content: center;

      .scores {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        h4 {
          margin: 0;
          border-bottom: 1px dotted #333;
        }

        p {
          margin: 0;
          color: #411564;
        }
      }

      button {
        border: none;
        background: none;
        border-bottom: 1px dotted #f47920;
        color: #333;
        padding: 5px 10px;
        font-weight: 700;

        &:hover {
          color: #f47920;
        }

        @media only print {
          display: none;
        }
      }
    }

    section.total-container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      align-content: center;
      padding: 10px 100px 10px 20px;
      background: #aaa;
      color: #333;

      b {
        color: #ddd;
        font-size: 1.6rem;
        border-bottom: 1px dotted #fff;
      }

      @media only print {
        padding: 10px 20px;
      }
    }

    section.total-container.main {
      background: #333;
      color: #fff;
    }
  }

  h3.evaluation-total {
    text-align: center;
  }

  h3.evaluation-main-total {
    text-align: center;
  }
`;

export const UpContainer = styled.div`
  width: 100%;
  /* max-width: calc(100vw - 129px); */
  padding: 10px 0;
  overflow: hidden;
  align-items: stretch;

  @media (max-width: 768px) {
    padding: 10px 0;
    max-width: 100%;
    margin-bottom: 10px;
  }

  & > div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(30rem, 30rem));
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    min-height: 0; /* NEW */
    min-width: 0; /* NEW; needed for Firefox */

    @media (max-width: 1280px) {
      display: grid;
      grid-template-columns: minmax(0, 1fr) 1fr;
      grid-template-rows: repeat(auto-fit, minmax(30rem, 30rem));
      grid-row-gap: 20px;
      min-height: 0;
      min-width: 0;
    }

    @media (max-width: 860px) {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: repeat(auto-fit, minmax(30rem, 30rem));
      grid-row-gap: 20px;
      min-height: 0; /* NEW */
      min-width: 0; /* NEW; needed for Firefox */
    }

    @media (max-height: 825px) {
      grid-template-rows: repeat(280px);
    }
  }
`;
