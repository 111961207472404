import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import history from '~/services/history';

import { subscribeUserRequest } from '../websocket/actions';
import { Types as AuthTypes, signInSuccess, signFailure } from './actions';

export function signOut() {
  api.defaults.headers.Authorization = '';
  history.push('/');
}

export function* signIn({ payload }) {
  try {
    const { username, password } = payload;
    const { data } = yield call(api.post, 'authenticate', {
      username,
      password,
    });

    const { token, user } = data;

    if (!user.active) {
      toast.error('Usuário não está ativo! Verifique sua situação com o setor de Recursos Humanos.');
      yield put(signFailure());
      signOut();
      return;
    }

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(token, user));

    yield put(subscribeUserRequest({ id: user.id }));

    if (user.roles?.includes('administrator')) {
      history.push('/dashboard');
      return;
    }

    if (user.roles?.includes('evaluator')) {
      history.push('/leader-dashboard');
      return;
    }

    if (user.roles?.includes('evaluated')) {
      history.push('/employee-dashboard');
      return;
    }
  } catch (err) {
    if (err.response) {
      const {
        response: { data },
      } = err;
      if (data.message === 'PasswordMisMatchException') {
        toast.error('Usuário ou Senha incorretos. Verifique seus dados.');
        yield put(signFailure());
        return;
      }
    }

    if (err.message === 'Network Error') {
      toast.error('Não foi possível conectar com o servidor! Verifique com o administrador do sistema.');
      yield put(signFailure());
      return;
    }

    toast.error(`Falha na autenticação, verifique seus dados! ${err.message}`);
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;
  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn),
  takeLatest(AuthTypes.SIGN_OUT_REQUEST, signOut),
]);
