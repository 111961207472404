import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative !important;
  height: 100%;
  width: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 350px;
    /* padding: 0 20px; */
  }

  h1 {
    color: #fff;
    font-size: 3rem;
    margin-top: 15px;
  }

  img {
    width: 180px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    border-bottom-left-radius: 13px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    width: 100%;
  }

  input {
    background: rgba(0, 0, 0, 0.07);
    border: 0;
    border-radius: 4px;
    height: 44px;
    padding: 0 15px;
    color: #fff;
    margin: 0 0 10px;
    width: 100%;

    &::placeholder {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    /* use animation hack, if you have hard styled input */
    transition: all 5000s ease-in-out 0s;
    transition-property: background-color, color;
    /* if input has one color, and didn't have bg-image use shadow */
    -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.1) inset;
    /* text color */
    -webkit-text-fill-color: rgba(0, 0, 0, 0.1);
    /* font weigth */
    font-weight: 300 !important;
  }

  button {
    margin: 5px 0 0;
    height: 44px;
    background: #0a5f55;
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.07, '#0a5f55')};
    }
  }

  a {
    color: #fff;
    margin-top: 15px;
    font-size: 16px;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  footer {
    position: absolute;
    margin-bottom: 10px;
    bottom: 0;
    color: #fff;
    font-weight: 300;
    font-size: 1.4rem;
  }
`;
