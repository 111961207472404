export const headers = [
  {
    title: 'Descrição',
    width: '35%',
    align: 'left',
    columnName: 'description',
    dataType: String,
  },
  {
    title: 'Trajetória / Complexidade',
    width: '35%',
    align: 'left',
    columnName: 'paths.description',
    dataType: String,
  },
  {
    title: 'Código Cargo',
    width: '20%',
    align: 'left',
    columnName: 'position_code',
    dataType: String,
  },
];
