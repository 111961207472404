import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  align-content: center;

  [type='checkbox']:checked,
  [type='checkbox']:not(:checked),
  [type='radio']:checked,
  [type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;
    visibility: hidden;
  }

  .checkbox-tools:checked + label,
  .checkbox-tools:not(:checked) + label {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    font-size: 1rem;
    line-height: 20px;
    letter-spacing: 1px;
    margin: 0 auto;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
    text-align: center;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    text-transform: uppercase;
    color: #fff;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
  }

  .checkbox-tools:not(:checked) + label {
    background-color: #5b5c65;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }

  .checkbox-tools:checked + label {
    background: linear-gradient(298deg, #ed1651, #ffcb08) !important;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }

  .checkbox-tools:not(:checked) + label:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  /* .checkbox-tools:checked + label,
  .checkbox-tools:not(:checked) + label {
    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      background-image: linear-gradient(298deg, #ed1651, #ffcb08) !important;
      z-index: -1;
    }
  } */

  .checkbox-tools:checked + label .uil,
  .checkbox-tools:not(:checked) + label .uil {
    font-size: 24px;
    line-height: 24px;
    display: block;
    padding-bottom: 10px;
  }

  .checkbox:checked ~ .section .container .row .col-12 .checkbox-tools:not(:checked) + label {
    background-color: var(--light);
    color: var(--dark-blue);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }

  label {
    svg {
      margin-right: 5px;
    }
  }
`;
