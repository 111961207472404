import { Link } from 'react-router-dom';

import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100% -20px);
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    max-width: 100%;

    .area-description {
      h3 {
        margin: 0 20px;
        border-bottom: 1px solid #ccc;
        color: #777;
      }
    }
  }

  .area-description {
    h3 {
      border-bottom: 1px solid #ccc;
      padding-bottom: 3px;
      color: #777;
    }
  }

  @media (max-width: 1250px) {
    padding: 0 10px;
  }
  @media (max-width: 1150px) {
    padding: 0 20px;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 8px 15px 8px 27px;
  border-radius: 5px;
  margin-bottom: 20px;
  min-height: 51px;

  h1 {
    color: #222;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      font-size: 2rem;
    }

    small {
      color: #0a5f55;
      font-size: 1.2rem;
      margin-left: 10px;
    }
  }

  b {
    span {
      color: #411564;
    }
  }

  span {
    color: #999;
  }

  section.area-summary {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    border: 1px solid #eee;
    padding: 10px 20px;
    border-radius: 5px;
    background: #f2f3f7;

    span {
      text-align: right;

      @media (max-width: 768px) {
        min-width: 115px;
      }
    }

    @media (max-width: 768px) {
      margin: 10px 0;
    }
  }

  section.legend {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    h4 {
      border-bottom: 1px dotted #ddd;
      width: 100%;
      text-align: center;
      margin-bottom: 5px;
      padding-bottom: 2px;
    }

    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 1px;
    }
  }

  @media (max-width: 768px) {
    margin: 1px 20px 20px 20px;
    padding: 10px 15px 12px 15px;
    flex-direction: column;
  }
`;

export const EmployeesContainer = styled.div`
  display: flex;
  flex: 1 1 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`;

export const UpContainer = styled.div`
  width: 100%;
  /* max-width: calc(100vw - 129px); */
  padding: 10px 0;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 20px;
    max-width: 100%;
  }

  & > div {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 1fr 1fr;
    grid-template-rows: minmax(auto-fill, 130px);
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    min-height: 130px; /* NEW */
    min-width: 0; /* NEW; needed for Firefox */

    @media (max-width: 1150px) {
      grid-template-columns: minmax(0, 1fr) 1fr;
    }

    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: minmax(auto-fill, 130px);
      grid-row-gap: 20px;
      min-height: 130px; /* NEW */
      min-width: 0; /* NEW; needed for Firefox */
    }

    @media (max-height: 825px) {
      grid-template-rows: minmax(auto-fill, 130px);
    }
  }
`;

export const CheckedCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: rotate(25deg);
  width: 90px;
  height: 90px;
  margin-left: 35px;
  border: 3px dashed #444;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  color: #444;
  font-size: 18px;
  font-weight: 700;
`;

const getEarColor = (process, autoevaluated, finished, confirmed) => {
  if (process === 'feedback') {
    if (finished === 'true' && (confirmed === 'false' || !confirmed)) {
      return '#00995d';
    }
    if (finished === 'true' && confirmed === 'true') {
      return '#411564';
    }

    return '#ed1651';
  }

  if (process === 'evaluation') {
    if (autoevaluated === 'true' && finished === 'true') {
      return '#00995d';
    }
    if (autoevaluated === 'true' && finished === 'false') {
      return '#411564';
    }
    return '#ffcb08';
  }

  return '#b1d34b';
};

const getFinished = (process, autoevaluated, finished) => {
  if (process === 'feedback') {
    if (finished === 'true') {
      return 'block';
    }
    return 'none';
  }
  if (process === 'evaluation') {
    if (autoevaluated === 'true' || finished === 'true') {
      return 'block';
    }
    return 'none';
  }

  return 'none';
};

export const CardContainer = styled(Link)`
  text-decoration: none;

  a {
    text-decoration: none;
  }

  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  opacity: 1;
  border-radius: 8px;
  transition: all 0.2s ease 0s;
  padding: 10px 10px 10px 20px;
  /* border: 2px solid rgb(0, 0, 0, 0) !important; */
  border-image: initial;
  /* box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12); */
  position: relative;

  img {
    width: 56px;
    height: 56px;
  }

  &:hover {
    /* border: 1px solid #b1d34b !important; */
    cursor: pointer;
    transform: translate3d(0, -2%, 0);
    background: linear-gradient(145deg, #f0f0f0, #cacaca);
  }

  h1 {
    font-size: 30px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }

  h4 {
    color: #333;
    margin-bottom: 5px;
  }

  div.employee-description {
    display: inline-block;
    width: 98%;

    .card-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      align-content: center;
      width: 100%;
      border-bottom: 1px dotted #111;
      margin-bottom: 5px;

      h4 {
        color: #444;
        margin: 0;
      }

      @media (max-width: 768px) {
        padding-bottom: 5px;
      }
    }

    h4 {
      text-transform: uppercase;
    }

    span {
      display: block;
      font-size: 12px;
      line-height: 1.5;
      color: rgba(91, 92, 101, 1);
      text-transform: capitalize;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  b {
    color: #555;
  }

  @media (max-width: 768px) {
    padding: 15px !important;
  }

  &::after {
    content: '';
    display: ${(props) => getFinished(props.process, props.autoevaluated, props.finished)};
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px !important;
    border-color: ${(props) => getEarColor(props.process, props.autoevaluated, props.finished, props.confirmed)} #f2f3f7 #f2f3f7
      ${(props) => getEarColor(props.process, props.autoevaluated, props.finished, props.confirmed)};
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 8px 0 0 0;
    box-shadow: -8px -8px 8px -2px rgba(0, 0, 0, 0.35);
  }

  &::before {
    content: '';
    display: ${(props) => (props.withoutform === 'true' ? 'block' : 'none')};
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px;
    border-color: #ed1651 #f2f3f7 #f2f3f7 #ed1651;
    width: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 8px 0 0 0;
    box-shadow: -8px -8px 8px -2px rgba(0, 0, 0, 0.35);
  }
`;

export const Square = styled.span`
  width: 8px;
  height: 8px;
  display: block;
  margin-left: 5px;
  border-style: solid;
  border-width: 8px !important;
  border-color: ${(props) => props.color} #fff #fff ${(props) => props.color};
  background: ${(props) => props.color};
`;
