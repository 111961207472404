import React, { memo, useRef, useCallback, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useSelector, useDispatch } from 'react-redux';
import { useSpring, animated } from 'react-spring';

import { toggleModalRequest } from '~/store/modules/modal/actions';

import { Container, ModalHeader, CloseModalButton, ModalWrapper, ModalContainer } from './styles';

function Modal() {
  const { content: Content, title, visible, entity_description } = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  const hideModalTest = useCallback(() => {
    dispatch(toggleModalRequest({ title: null, children: () => null, visible: false }));
  }, [dispatch]);

  const modalRef = useRef();
  const animation = useSpring({
    config: {
      duration: 250,
    },
    opacity: visible ? 1 : 0,
    transform: visible ? `translateY(0%)` : `translateY(-100%)`,
  });

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      hideModalTest();
    }
  };

  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && visible) {
        hideModalTest();
      }
    },
    [hideModalTest, visible],
  );

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress);
  }, [keyPress]);

  return (
    <>
      {visible ? (
        <Container ref={modalRef} onClick={closeModal}>
          <animated.div style={animation}>
            <ModalWrapper>
              <ModalHeader>
                <section>
                  <h3>{title}</h3>
                  <small>{entity_description}</small>
                </section>
                <CloseModalButton aria-label='Fechar Janela Modal' onClick={hideModalTest} size={30} />
              </ModalHeader>
              {Content && (
                <ModalContainer>
                  <Scrollbars style={{ width: '100%' }} autoHeight autoHeightMax='80vh'>
                    <Content />
                  </Scrollbars>
                </ModalContainer>
              )}
            </ModalWrapper>
          </animated.div>
        </Container>
      ) : (
        <></>
      )}
    </>
  );
}

export default memo(Modal);
