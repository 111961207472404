import React, { memo, useRef } from 'react';
import { BsPip } from 'react-icons/bs';
import { GrBackTen, GrForwardTen, GrPin } from 'react-icons/gr';
import { MdCrop169 } from 'react-icons/md';

import videoTest from '~/assets/Institucional.mp4';
import videoPoster from '~/assets/video-poster-unimed.png';
import { Switch } from '~/components';
import usePlayerState from '~/hooks/usePlayerState';

import { Container, PlayerContainer, ExtraCommandsContainer } from './styles';

function VideoPlayer() {
  const playerRef = useRef(null);
  const { playerState, toggleVideoPlayer, handleTimeUpdate, handleChangeVideoPercentage } = usePlayerState(playerRef);
  // const throttled = useRef();

  return (
    <Container>
      <PlayerContainer>
        <div className='bitmovinplayer-container'>
          <video src={videoTest} poster={videoPoster} width='620' ref={playerRef} onTimeUpdate={handleTimeUpdate}>
            <track kind='captions' />
            Desculpe, seu navegador não suporta vídeos embutidos, mas não se preocupe, você pode
            <a href='https://archive.org/details/BigBuckBunny_124'> baixar</a> e assistir no seu programa de vídeos favorito!
          </video>
          <div className='bitmovinplyaer-poster' />
          <div className='bmpui-ui-uicontainer'>
            <div className='bmpui-container-wrapper'>
              <div className='bmpui-ui-playbacktoggle-overlay'>
                <div className='bmpui-container-wrapper'>
                  <button type='button' className='bmpui-ui-hugeplaybacktogglebutton' onClick={toggleVideoPlayer}>
                    <span className='bmpui-label'>Play/Pause</span>
                    <div className='bmpui-image' />
                  </button>
                </div>
              </div>
              <div className='bmpui-ui-controlbar'>
                <div className='bmpui-ui-settings-panel'>1</div>
                <div className='bmpui-controlbar-top'>
                  <div className='bmpui-container-wrapper'>
                    <span className='bmpui-ui-playbacktimelabel'>{playerState.elapsedTime}</span>
                    <div className='bmpui-ui-seekbar'>
                      <input
                        type='range'
                        min='0'
                        max='100'
                        onChange={handleChangeVideoPercentage}
                        value={playerState.percentage}
                      />
                    </div>
                    <span className='bmpui-ui-playbacktimelabel text-right'>{playerState.duration}</span>
                  </div>
                </div>
                <div className='bmpui-controlbar-bottom'>1</div>
              </div>
            </div>
          </div>
        </div>
        <button type='button' className='pip'>
          <BsPip color='#fff' />
        </button>
      </PlayerContainer>
      <ExtraCommandsContainer>
        <div>
          <button type='button'>
            <GrBackTen color='#411564' size={24} />
          </button>
          <button type='button'>
            <GrForwardTen color='#411564' size={24} />
          </button>
          <button type='button'>
            <GrPin color='#411564' size={24} />
          </button>
        </div>
        <div>
          <label htmlFor='Switch' className='auto-play'>
            <span className='auto-play-text'>Autoplay</span>
            <Switch />
          </label>
          <button type='button'>
            <MdCrop169 color='#000' size={24} />
          </button>
        </div>
      </ExtraCommandsContainer>
    </Container>
  );
}

export default memo(VideoPlayer);
