import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import BehaviorIcon from '~/assets/behavior.svg';
import CiclesIcon from '~/assets/cicles.svg';
import CommentIcon from '~/assets/comments.svg';
import CaracteristicaIcon from '~/assets/complexity.svg';
import DepartmentIcon from '~/assets/department.svg';
import DevelopmentIcon from '~/assets/development.svg';
import FormIcon from '~/assets/form.svg';
import HierarchyIcon from '~/assets/hierarchy.svg';
import PerguntasIcon from '~/assets/question.svg';
import EscalcaAvaliacaoIcon from '~/assets/scales.svg';
import ScoreIcon from '~/assets/score.svg';
import CompetenciaIcon from '~/assets/skill.svg';
import UserGroupIcon from '~/assets/user-group.svg';
import UserIcon from '~/assets/user.svg';
import TreinamentoIcon from '~/assets/video-player-svgrepo-com.svg';
import * as routes from '~/routes/config';
import { updateBreadcrumb } from '~/store/modules/user/actions';

import { Container, UpContainer, CardContainer } from './styles';

function MainMenu() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { name: 'Início', path: '/' },
        { name: 'Configurações', path: '/sys-config' },
      ]),
    );
  }, [dispatch]);

  return (
    <Container>
      <UpContainer>
        <div>
          <CardContainer to={routes.DEPARTMENT}>
            <div>
              <img src={DepartmentIcon} alt='Áreas' />
              <h4>Áreas</h4>
            </div>
          </CardContainer>
          <CardContainer to={routes.POSITION}>
            <div>
              <img src={UserIcon} alt='Cargos' />
              <h4>Cargos</h4>
            </div>
          </CardContainer>
          <CardContainer to={routes.CLASSIFICATION}>
            <div>
              <img src={ScoreIcon} alt='Classificação' />
              <h4>Classificação</h4>
            </div>
          </CardContainer>
          <CardContainer to={routes.EVALUATION_CYCLE}>
            <div>
              <img src={CiclesIcon} alt='Ciclos' />
              <h4>Ciclos</h4>
            </div>
          </CardContainer>
          <CardContainer to={routes.SKILL}>
            <div>
              <img src={CompetenciaIcon} alt='Competências' />
              <h4>Competências</h4>
            </div>
          </CardContainer>
          <CardContainer to={routes.BEHAVIOR}>
            <div>
              <img src={BehaviorIcon} alt='Comportamentos' />
              <h4>Comportamentos</h4>
            </div>
          </CardContainer>
          <CardContainer to={routes.RATING_SCALE}>
            <div>
              <img src={EscalcaAvaliacaoIcon} alt='Escala de Avaliação' />
              <h4>Escala de Avaliação</h4>
            </div>
          </CardContainer>
          <CardContainer to={routes.COMMENT}>
            <div>
              <img src={CommentIcon} alt='Comentários' />
              <h4>Feedback</h4>
            </div>
          </CardContainer>
          <CardContainer to={routes.FORM}>
            <div>
              <img src={FormIcon} alt='Formulários' />
              <h4>Formulários</h4>
            </div>
          </CardContainer>
          <CardContainer to={routes.USER}>
            <div>
              <img src={UserGroupIcon} alt='Funcionários' />
              <h4>Funcionários</h4>
            </div>
          </CardContainer>
          <CardContainer to={routes.HIERARCHY}>
            <div>
              <img src={HierarchyIcon} alt='Hieraquias' />
              <h4>Hierarquias</h4>
            </div>
          </CardContainer>
          <CardContainer to={routes.DEVELOPMENT_PLAN}>
            <div>
              <img src={DevelopmentIcon} alt='Planos de Desenvolvimento' />
              <h4>Planos de Desenvolvimento</h4>
            </div>
          </CardContainer>
          <CardContainer to={routes.QUESTION}>
            <div>
              <img src={PerguntasIcon} alt='Perguntas' />
              <h4>Perguntas</h4>
            </div>
          </CardContainer>
          <CardContainer to={routes.PATH}>
            <div>
              <img src={CaracteristicaIcon} alt='Trajetória / Complexidade' />
              <h4>Trajetória / Complexidade</h4>
            </div>
          </CardContainer>
          <CardContainer to={routes.TRAINING}>
            <div>
              <img src={TreinamentoIcon} alt='Treinamentos / Cursos' />
              <h4>Treinamentos / Cursos</h4>
            </div>
          </CardContainer>
        </div>
      </UpContainer>
    </Container>
  );
}

export default memo(MainMenu);
