import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  top: 67px;
  max-width: 590px;
  width: 100%;
  padding: 0;
  margin: 0 auto;
  left: 50%;

  &:hover {
    input {
      padding-right: 40px;
      width: 300px;
      color: #333;
    }
  }

  form {
    input {
      width: 45px;
      transition: width 0.4s ease-in-out;
      color: transparent;

      &:focus {
        padding-right: 40px;
        width: 300px;
        color: #333;
      }
    }
  }

  button {
    border: none;
    background: transparent;
    margin-left: -34px;
    margin-top: 5px;
  }
`;
