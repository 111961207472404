import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import cycle from './cycle/sagas';
import modal from './modal/sagas';
import user from './user/sagas';
import websocket from './websocket/sagas';

export default function* rootSaga() {
  return yield all([auth, cycle, modal, user, websocket]);
}
