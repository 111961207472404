import produce from 'immer';

import acceptedTypes from './action-types';

const INITIAL_STATE = {
  profile: null,
  admin: false,
  id: null,
  departmentId: null,
  breadcrumb: [{ name: 'Início', path: '/' }],
  process: null,
  options: {},
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    const typeFunction = acceptedTypes[action.type];
    if (typeFunction) {
      typeFunction(draft, action.payload);
    }
  });
}
