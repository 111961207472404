import React, { Fragment, memo } from 'react';

import PropTypes from 'prop-types';

import { Container, Ul, Li } from './styles';

const Card = ({ data, openReport, isChildren }) => {
  return (
    <Ul tamanhoGrid={data.length} className={isChildren ? '' : 'organizational-chart'}>
      {data &&
        data?.map((item, index) => (
          <Fragment key={item.value}>
            <Li isChildren={isChildren} left={index}>
              <button type='button' onClick={() => openReport(item.value)}>
                <div>
                  <h4>{item.label}</h4>
                </div>
              </button>
              {item.children?.length ? <Card data={item.children} openReport={openReport} isChildren /> : ''}
            </Li>
          </Fragment>
        ))}
    </Ul>
  );
};

const Chart = ({ data, showList, handleList, openReport }) => {
  return (
    <Container>
      <div className='tree'>
        <Card data={data} showList={showList} handleList={handleList} openReport={openReport} />
      </div>
    </Container>
  );
};

Chart.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  showList: PropTypes.instanceOf(Object).isRequired,
  handleList: PropTypes.func.isRequired,
  openReport: PropTypes.func.isRequired,
};

Card.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  openReport: PropTypes.func.isRequired,
  isChildren: PropTypes.bool,
};

Card.defaultProps = {
  isChildren: false,
};

export default memo(Chart);
