import React, { memo } from 'react';
import { MdEdit, MdDeleteForever, MdAdd, MdAutorenew, MdSend, MdLockOpen } from 'react-icons/md';
import { Get } from 'react-lodash';

import { Tooltip } from '@material-ui/core';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import { Container, Table as TableComponent } from './styles';

function Table({ headers, data, actions, editing, processing }) {
  const headerTypes = {
    Date(value) {
      return format(new Date(value), 'dd/MM/yyyy');
    },
    Boolean(value) {
      return value ? 'Ativo' : 'Inativo';
    },
    Number(value) {
      return parseFloat(value).toFixed(2);
    },
    String(value) {
      return value;
    },
  };

  return (
    <Container editing={editing}>
      <TableComponent width='100%' processing={processing}>
        <thead>
          <tr>
            {headers.map((item) => (
              <th key={item.title} width={item.width} style={{ textAlign: item.align }}>
                {item.title}
              </th>
            ))}
            <th className='actions-column'>
              Ações{' '}
              {actions.add ? (
                <Tooltip arrow title='Adicionar'>
                  <button type='button'>
                    <MdAdd
                      size={24}
                      color='#fff'
                      onClick={() => {
                        actions.setProcess('add');
                        actions.clear();
                        actions.showForm();
                      }}
                    />
                  </button>
                </Tooltip>
              ) : (
                ''
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((entity) => (
              <tr key={entity.id}>
                {headers.map((header) => (
                  <td key={header.columnName} style={{ textAlign: header.align }}>
                    <Get object={entity} path={`${header.columnName}`}>
                      {(value) => {
                        const getContentFunction = headerTypes[header.dataType.name];
                        if (getContentFunction) {
                          return getContentFunction(value);
                        }
                        return '';
                      }}
                    </Get>
                  </td>
                ))}
                <td>
                  <div className='btn-group'>
                    {actions.buttons.includes('edit') && (
                      <Tooltip arrow title='Editar' style={{ fontSize: '2.6rem !important' }}>
                        <button type='button'>
                          <MdEdit
                            size={24}
                            color='#5b5c65'
                            onClick={() => {
                              actions.setProcess('edit');
                              actions.clear();
                              actions.showForm();
                              actions.edit(entity);
                              actions.setEntityInProcess(entity);
                            }}
                          />
                        </button>
                      </Tooltip>
                    )}
                    {actions.buttons.includes('reopen-autoavaliation') && (
                      <Tooltip arrow title='Reabrir Autoavaliação'>
                        <button type='button'>
                          <MdLockOpen
                            size={24}
                            color='#F47920'
                            onClick={() => {
                              actions.reopen.setEntityInProcess(entity);
                              actions.reopen.setShowReopenDialog(true);
                            }}
                          />
                        </button>
                      </Tooltip>
                    )}
                    {actions.buttons.includes('reset') && (
                      <Tooltip arrow title='Resetar Senha'>
                        <button type='button' className={processing === `reset-${entity.id}` ? 'custom-button-effect' : ''}>
                          <MdAutorenew
                            size={24}
                            color='#411564'
                            onClick={() => {
                              actions.reset.setEntityInProcess(entity);
                              actions.reset.setShowResetDialog(entity.id);
                            }}
                          />
                        </button>
                      </Tooltip>
                    )}
                    {actions.buttons.includes('send') && (
                      <Tooltip arrow title='Enviar'>
                        <button type='button' className={processing === `send-${entity.id}` ? 'custom-button-effect' : ''}>
                          <MdSend size={24} color='#f47920' onClick={() => actions.customFunction(entity.id)} />
                        </button>
                      </Tooltip>
                    )}
                    {actions.buttons.includes('delete') && (
                      <Tooltip arrow title='Deletar'>
                        <button type='button'>
                          <MdDeleteForever
                            size={24}
                            color='#ed1651'
                            onClick={() => {
                              actions.remove.setEntityInProcess(entity);
                              actions.remove.setShowDialog(true);
                            }}
                          />
                        </button>
                      </Tooltip>
                    )}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </TableComponent>
    </Container>
  );
}

Table.propTypes = {
  headers: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  actions: PropTypes.instanceOf(Object).isRequired,
  editing: PropTypes.bool.isRequired,
  processing: PropTypes.string,
};

Table.defaultProps = {
  processing: null,
};

export default memo(Table);
