import styled, { keyframes } from 'styled-components';

const wave = keyframes`
 50% {
      transform: scale(0.9);
    }
`;

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  text-align: justify;
  /* align-items: center; */
  justify-content: center;

  input[type='checkbox'] {
    display: none;
  }

  .cbx {
    margin: auto;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    display: flex;
  }
  .cbx span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
  }
  .cbx span {
    &:first-child {
      position: relative;
      width: 18px;
      min-width: 18px;
      height: 18px;
      border-radius: 3px;
      transform: scale(1);
      vertical-align: middle;
      border: 1px solid #9098a9;
      transition: all 0.2s ease;
    }
  }
  .cbx span {
    &:first-child svg {
      position: absolute;
      top: 3px;
      left: 2px;
      fill: none;
      stroke: #ffffff;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: 16px;
      stroke-dashoffset: 16px;
      transition: all 0.3s ease;
      transition-delay: 0.1s;
      transform: translate3d(0, 0, 0);
    }
  }
  .cbx span {
    &:first-child {
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        background: #411564;
        display: block;
        transform: scale(0);
        opacity: 1;
        border-radius: 50%;
      }
    }
  }
  .cbx span:last-child {
    padding-left: 8px;
  }
  .cbx:hover span:first-child {
    border-color: #411564;
  }

  .inp-cbx:checked + .cbx span:first-child {
    background: #411564;
    border-color: #411564;
    animation: ${wave} 0.4s ease;
  }
  .inp-cbx:checked + .cbx span:first-child svg {
    stroke-dashoffset: 0;
  }
  .inp-cbx:checked + .cbx span:first-child:before {
    transform: scale(3.5);
    opacity: 0;
    transition: all 0.6s ease;
  }
`;
