import React, { memo, useState, useEffect, useRef, useCallback } from 'react';
import { MdSave, MdCancel } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Scope } from '@unform/core';
import { Form } from '@unform/web';

import {
  ButtonNormal,
  Checkbox,
  Dialog,
  Input,
  Pagination,
  Panel,
  SearchPanel,
  Select,
  Table,
  TreeView,
  MainContainer,
} from '~/components';
import { LoadingTable } from '~/components/Shimmer';
import { getTotalItemsPerPage, mountOptionList } from '~/functions';
import api from '~/services/api';
import { updateBreadcrumb } from '~/store/modules/user/actions';

import { FormContainer, ButtonContainer } from './styles';
import { headers } from './tableHeaders';

function Forms() {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(getTotalItemsPerPage(198, 38));
  const [lastPage, setLastPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [entityList, setEntityList] = useState([]);
  const [pathList, setPathList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [entityInProcess, setEntityInProcess] = useState({});
  const [treeNodes, setTreeNodes] = useState([]);
  const [checked, setChecked] = useState([]);
  const [process, setProcess] = useState('add');

  const profile = useSelector((state) => state.user.profile);
  const formRef = useRef(null);

  const mountTreeList = useCallback(
    async (childrenEntity, setFunction, pathId) => {
      const { data: childrenData } = await api.get(`${childrenEntity}/${pathId}`);
      setFunction(childrenData);
    },
    [],//eslint-disable-line
  );

  useEffect(() => {
    setIsLoading(true);
    async function loadEntitiesData() {
      dispatch(
        updateBreadcrumb([
          { name: 'Início', path: '/' },
          { name: 'Configurações', path: '/sys-config' },
          { name: 'Formulário', path: '/forms' },
        ]),
      );
      const {
        data: { data, lastPage: last, total: totalItems },
      } = await api.get(`forms?page=${page}&itemsPerPage=${itemsPerPage}`);
      setEntityList(data || []);

      setTotal(parseInt(totalItems, 10));
      setLastPage(parseInt(last, 10));
      setIsLoading(false);
    }

    loadEntitiesData();
  }, [dispatch, itemsPerPage, page]);

  useEffect(() => {
    setItemsPerPage(showForm ? 10 : getTotalItemsPerPage(198, 38));
  }, [showForm]);

  useEffect(() => {
    async function loadSelectOption() {
      mountOptionList('paths', setPathList);
    }

    loadSelectOption();
  }, []);

  useEffect(() => {
    if (!showForm) return;
    if (process === 'edit') {
      setTreeNodes([]);
    } else {
      setTreeNodes([]);
      mountTreeList('skillbehaviors', setTreeNodes, null);
    }
  }, [mountTreeList, pathList, process, showForm]);

  const loadEntityData = async (entityData) => {
    const { data } = await api.get(`forms/${entityData.id}`);
    formRef.current.setData(data);

    const checkedItems = data?.behaviorForms?.map((behavior) => behavior.behavior_id);
    setChecked(checkedItems);
  };

  const mountTreeListOnSelectPath = (pathId) => {
    mountTreeList('skillbehaviors', setTreeNodes, pathId);
  };

  function clearForm() {
    formRef.current.setFieldValue('id', null);
    formRef.current.reset();
  }

  async function removeEntity() {
    if (entityInProcess.id) {
      const response = await api.delete(`forms/${entityInProcess.id}`);
      toast.success(`${response.statusText}: Formulário excluído com sucesso`);
      const userListWithoutDeleted = entityList.filter((entity) => entity.id !== entityInProcess.id);
      setEntityList(userListWithoutDeleted);
      setShowDialog(false);
    }
  }

  const onSubmit = async (data, { reset }) => {
    const formData = { ...data, behaviors: [...checked], company_id: profile.company_id };
    if (process === 'edit') {
      const { status, statusText } = await api.put(`forms/${data.id}`, formData);
      if (status === 200) {
        toast.success(`${statusText}: Dados atualizados com sucesso`);
        setShowForm(false);
      } else {
        toast.error(`${statusText}: Erro ao atualizar dados. Code: ${status}`);
      }
    } else {
      const { status, statusText, ...rest } = await api.post(`forms`, formData).catch((err) => err);
      if (status === 201) {
        toast.success(`${statusText}: Dados cadastrados com sucesso`);
        setEntityList((entities) => [...entities, rest.data]);
        setShowForm(false);
        reset();
      } else {
        toast.error(`${rest.response.status}: ${rest.response.statusText}. Erro ao cadastrar dados`);
      }
    }
  };

  const handleSearch = async ({ searchSentence }) => {
    setIsLoading(true);
    const {
      data: { data, total: totalReturned },
    } = await api.get(`forms?page=${page}&itemsPerPage=${itemsPerPage}&searchSentence=${searchSentence}&searchBy=name`);

    setEntityList(data);
    setTotal(parseInt(totalReturned, 10));
    setIsLoading(false);
  };

  return (
    <MainContainer>
      <SearchPanel handleSearch={handleSearch} />
      <FormContainer showForm={showForm}>
        <Form ref={formRef} onSubmit={onSubmit} className='form'>
          <Input name='id' type='hidden' />
          <Input name='company_id' type='hidden' />
          <section className='first-row'>
            <Input name='name' placeholder='Nome' label='Nome' />
            <Input name='observation' placeholder='Observação' label='Observação' />
            <Select
              name='path_id'
              placeholder='Trajetória / Complexidade'
              label='Trajetória / Complexidade'
              options={pathList}
              isMulti={false}
              closeMenuOnSelect
              onChange={(event) => event && mountTreeListOnSelectPath(event?.value)}
            />
          </section>
          <Checkbox name='active' label='Ativo' />
          <div className='sub-title-group'>Selecione os comportamentos que serão avaliados no formulário</div>
          <Scope path='skillBehaviorsTrash'>
            {treeNodes.length > 0 && <TreeView nodes={treeNodes} checked={checked} setChecked={setChecked} />}
          </Scope>
          <ButtonContainer>
            <ButtonNormal color='#fff' background='#00995d' style={{ width: '100px' }} type='submit'>
              <MdSave size={20} color='#ffffff' /> Salvar
            </ButtonNormal>
            <ButtonNormal
              color='#fff'
              background='#ed1651'
              style={{ width: '100px' }}
              onClick={() => {
                setShowForm(false);
                clearForm();
              }}>
              <MdCancel size={20} color='#ffffff' /> Cancelar
            </ButtonNormal>
          </ButtonContainer>
        </Form>
      </FormContainer>
      {isLoading ? (
        <LoadingTable />
      ) : (
        !showForm && (
          <Panel>
            <Table
              headers={headers}
              data={entityList}
              editing={showForm}
              actions={{
                showForm: () => setShowForm(true),
                setProcess,
                edit: loadEntityData,
                remove: { setEntityInProcess, setShowDialog },
                clear: clearForm,
                reset: () => null,
                add: true,
                search: true,
                buttons: ['edit', 'delete'],
                setEntityInProcess,
              }}
            />
          </Panel>
        )
      )}
      <Dialog
        open={showDialog}
        setOpen={setShowDialog}
        confirmAction={removeEntity}
        title='Confirma a exclusão da classificação: '
        entityData={entityInProcess}
        actionName='Excluir'
        actionText='A exclusão desse formulário acarretará na exclusão de todos os dados já cadastrados para ele. Tenha certeza que deseja excluí-lo.'
      />
      {!showForm && <Pagination page={page} setPage={setPage} lastPage={lastPage} total={total} />}
    </MainContainer>
  );
}

export default memo(Forms);
