import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const ClassContainer = styled.section`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const VideoContainer = styled.div`
  width: 100%;
  flex: 1 1 0%;
  position: relative;
`;

export const DescriptionContainer = styled.section``;
