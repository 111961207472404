import styled from 'styled-components';

export const Container = styled.div`
  .rct-node {
    label {
      margin-bottom: 10px;
      max-width: calc(100vw - 128px);
      width: 1090px;
      font-size: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    }
  }
`;
