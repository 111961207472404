import styled from 'styled-components';

export default styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 10px;
  background: #fff;
  border-radius: 2px;
  /* box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(0, 0, 0, 0.2);

  &.no-shadow {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.02), 0 2px 3px rgba(0, 0, 0, 0.5);
  } */
`;
