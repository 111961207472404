import styled from 'styled-components';

export const Container = styled.div`
  label {
    color: #333;
    margin-bottom: 5px;
    display: block;
  }

  .MuiFormControl-root {
    width: 100%;
    background: rgba(155, 155, 155, 0.2);
    border-radius: 3px;
  }

  .MuiInputBase-input {
    width: 100%;
    display: block !important;
    color: rgb(38, 43, 51) !important;
    pointer-events: unset !important;
    border-radius: 3px !important;
    padding: 12px !important;
    /* text-align: center; */
    outline: none !important;
    border-width: 0px !important;
    border-style: initial !important;
    border-color: initial !important;
    border-image: initial !important;
    transition: color 0.1s ease 0s !important;
    /* font-weight: bold !important; */
    border: none !important;
    font-size: 1.5rem !important;

    &:focus {
      background: rgba(200, 200, 200, 0.8);
    }
  }

  .MuiInput-underline {
    &:before {
      border: none !important;
    }
    &:after {
      border: none !important;
    }
  }
`;
