import React, { memo, Fragment } from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { Container } from './styles';

const Breadcrumb = ({ crumbs }) => {
  if (crumbs.length < 1) {
    return null;
  }

  const generateKey = (pre) => {
    return `${pre}_${new Date().getTime()}`;
  };

  return (
    <Container>
      {crumbs.map(({ name, path }, key) =>
        key + 1 === crumbs.length ? (
          <span key={generateKey(name)}>{name}</span>
        ) : (
          <Fragment key={generateKey(name)}>
            <Link to={path}>{name}</Link>
            <strong>|</strong>
          </Fragment>
        ),
      )}
    </Container>
  );
};

Breadcrumb.propTypes = {
  crumbs: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

export default memo(Breadcrumb);
