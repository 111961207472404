import styled from 'styled-components';

export const FormContainer = styled.div`
  height: ${(props) => (props.showForm ? '120px' : '0')};
  opacity: ${(props) => (props.showForm ? '1' : '0')};
  transition: height 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 2s ease;

  form {
    margin-top: 10px;
    display: ${(props) => (props.showForm ? 'flex' : 'none')};
    flex-direction: column;

    > div {
      margin-bottom: 10px;
    }

    .hidden-input {
      display: none;
    }
    .hidden-input:target {
      display: block;
    }

    .input-text-group {
      display: flex;
      flex-direction: row;

      p {
        margin-left: 10px;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-left: 10px;
  }
`;
