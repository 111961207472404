/* eslint-disable react/no-unescaped-entities */
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { DoughnutChart, MainContainer } from '~/components';
import * as routes from '~/routes/config';
import api from '~/services/api';
import { updateBreadcrumb } from '~/store/modules/user/actions';

import { Container } from './styles';

function Follow() {
  const dispatch = useDispatch();
  const cycle = useSelector((state) => state.cycle);
  const [usersCount, setUsersCount] = useState(0);
  const [autoEvaluation, setAutoEvaluation] = useState(0);
  const [leaderEvaluation, setLeaderEvaluation] = useState(0);
  const [feedbackFinished, setFeedbackFinished] = useState(0);
  const [employeeConfirmFeedback, setEmployeeConfirmFeedback] = useState(0);
  const [employeeReceivedPdi, setEmployeeReceivedPdi] = useState(0);

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { name: 'Início', path: '/' },
        { name: 'Configurações', path: '/sys-config' },
        { name: 'Ciclo de Avaliação', path: '/evaluation-cycle' },
        { name: 'Acompanhe os Processos deste Ciclo de Avaliação', path: '/follow' },
      ]),
    );
  }, [dispatch]);

  useEffect(() => {
    async function getData() {
      if (!cycle.id) return;
      const { data: employeesData } = await api.get(`dashboardsummaries/${cycle.id}`);

      const totalUsers = employeesData?.filter((user) => user.active);
      setUsersCount(totalUsers.length);

      const totalFinishedAutoEvaluation = employeesData?.filter(
        (employee) =>
          employee.__meta__.employeeEvaluationAnswers === 0 &&
          employee.__meta__.employeeEvaluationJustificatives === 0 &&
          employee.active,
      );
      setAutoEvaluation(totalFinishedAutoEvaluation.length);

      const totalFinishedLeaderEvaluation = employeesData?.filter(
        (employee) =>
          employee.__meta__.leaderEvaluationAnswers === 0 &&
          employee.__meta__.leaderEvaluationJustificatives === 0 &&
          employee.active,
      );
      setLeaderEvaluation(totalFinishedLeaderEvaluation.length);

      const totalFinishedFeedback = employeesData.filter(
        (employee) => employee.__meta__.leaderEvaluationComments === 0 && employee.active,
      );
      setFeedbackFinished(totalFinishedFeedback.length);

      const totalEmployeeConfirmFeedback = employeesData.filter(
        (employee) => employee.__meta__.employeeConfirmFeedback === 0 && employee.active,
      );
      setEmployeeConfirmFeedback(totalEmployeeConfirmFeedback.length);

      const totalEmployeeReceivedPdi = employeesData.filter(
        (employee) => employee.__meta__.employeeReceivedPdi !== 0 && employee.active,
      );
      setEmployeeReceivedPdi(totalEmployeeReceivedPdi.length);
    }

    getData();
  }, [cycle.id]);

  return (
    <MainContainer>
      <Container>
        <h2>Processos para acompanhamento</h2>
        <small>Acompanhamento de criação e preenchimento de processos</small>
        <div>
          <Link to={`${routes.UNFINISHED}/auto-evaluation`}>
            <section>
              <h6>Autoavaliação</h6>
              <DoughnutChart total={usersCount} value={autoEvaluation} width='142px' color='#0a5f55' strokeColor='#5c5c65' />
            </section>
          </Link>
          <Link to={`${routes.UNFINISHED}/leader-evaluation`}>
            <section>
              <h6>Avaliação dos Gestores</h6>
              <DoughnutChart total={usersCount} value={leaderEvaluation} width='142px' color='#f47920' strokeColor='#5c5c65' />
            </section>
          </Link>
          <Link to={`${routes.UNFINISHED}/feedback`}>
            <section>
              <h6>Feedback</h6>
              <DoughnutChart total={usersCount} value={feedbackFinished} width='142px' color='#ed1651' strokeColor='#5c5c65' />
            </section>
          </Link>
          <Link to={`${routes.UNFINISHED}/feedback-received`}>
            <section>
              <h6>Feedback Confirmado</h6>
              <DoughnutChart
                total={usersCount}
                value={employeeConfirmFeedback}
                width='142px'
                color='#a3238e'
                strokeColor='#5c5c65'
              />
            </section>
          </Link>
          <Link to={`${routes.UNFINISHED}/pdi-received`}>
            <section>
              <h6>PDI</h6>
              <DoughnutChart total={usersCount} value={employeeReceivedPdi} width='142px' color='#b1d34b' strokeColor='#5c5c65' />
            </section>
          </Link>
        </div>
        <h2>Relatórios</h2>
        <small>Relatórios para acompanhamento dos processos</small>
        <div>
          <Link to={routes.PDI_REPORT}>
            <section>
              <h6>Relatório Solicitação de Treinamento pelo PDI</h6>
              <p>
                Relatório contendo todos os planos de desenvolvimento bem seu tipo, explicitando assim os treinamentos solicitados
              </p>
            </section>
          </Link>
        </div>
        <h2>Demais Processos</h2>
        <small>Demais processos para acompanhamento do setor de RH</small>
        <div>
          <Link to={routes.DEPARTMENT_PDI}>
            <section>
              <h6>Lista de PDI's de todos os setores</h6>
              <p>Relação de PDI's criados e acompanhamento de status.</p>
            </section>
          </Link>
          <Link to={routes.DEPARTMENT_CONTINUOUS_FEEDBACK}>
            <section>
              <h6>Lista de Feedbacks Contínuos de todos os setores</h6>
              <p>Relação dos feedbacks contínuos criados</p>
            </section>
          </Link>
        </div>
      </Container>
    </MainContainer>
  );
}

export default memo(Follow);
