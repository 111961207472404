import React, { memo, useEffect, useState, Fragment } from 'react';
import ReactExport from 'react-export-excel';
import { RiFileExcel2Line } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import { MainContainer, ButtonNormal } from '~/components';
import { sortObjectByKey, groupBy } from '~/functions';
import api from '~/services/api';
import { updateBreadcrumb } from '~/store/modules/user/actions';

import {
  EmployeeListContainer,
  DepartmentContainer,
  DepartmentHeader,
  EmployeeInformation,
  DownloadButtonContainer,
} from './styles';

function PdiReport() {
  const dispatch = useDispatch();
  const cycle = useSelector((state) => state.cycle);
  const [developmentPlanList, setDevelopmentPlanList] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  const { ExcelFile, ExcelSheet } = ReactExport;
  const { ExcelColumn } = ExcelFile;

  const planStatus = ['Aguardando', 'Finalizado', 'Cancelado', 'Expirado'];

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { name: 'Início', path: '/' },
        { name: 'Configurações', path: '/sys-config' },
        { name: 'Ciclo de Avaliação', path: '/evaluation-cycle' },
        { name: 'Acompanhe os Processos deste Ciclo de Avaliação', path: '/follow' },
        { name: 'Relatório de Plano de Desenvolvimento Por Tipo', path: '/pdi-report' },
      ]),
    );
  }, [dispatch]);

  useEffect(() => {
    async function getData() {
      const { data } = await api.get(`pdireport/${cycle.id}`);
      const organizedData = data.map((employee) => ({
        id: employee.id,
        name: employee.name,
        department: employee.departments.name,
        evaluationCycleDevelopmentPlans: employee.evaluationCycleDevelopmentPlans,
        __meta__: employee.__meta__,
      }));

      setDevelopmentPlanList(sortObjectByKey(groupBy(organizedData, 'department')));

      const dataArray = [];
      organizedData.forEach((employee) =>
        employee.evaluationCycleDevelopmentPlans.map((plan) =>
          dataArray.push({
            id: employee.id,
            name: employee.name,
            department: employee.department,
            type: plan.developmentPlans?.action,
            action: plan.action,
            status: plan.status,
            initialDate: new Date(plan.initial_date).toLocaleDateString('pt-BR'),
            finalDate: new Date(plan.final_date).toLocaleDateString('pt-BR'),
          }),
        ),
      );

      setOriginalData(dataArray);
    }

    getData();
  }, [cycle.id]);

  return (
    <MainContainer>
      <DownloadButtonContainer>
        {originalData.length > 0 && (
          <ExcelFile
            element={
              <ButtonNormal type='button' color='#333' background='#ddd'>
                <RiFileExcel2Line size={24} color='#00995d' />
                Download Data
              </ButtonNormal>
            }>
            <ExcelSheet data={originalData} name='PDI'>
              <ExcelColumn label='Setor' value='department' />
              <ExcelColumn label='Colaborador' value='name' />
              <ExcelColumn label='Tipo Plano' value='type' />
              <ExcelColumn label='Plano' value='action' />
              <ExcelColumn label='Data Inicial' value='initialDate' />
              <ExcelColumn label='Data Final' value='finalDate' />
              <ExcelColumn label='Status' value={(col) => planStatus[col.status]} />
            </ExcelSheet>
          </ExcelFile>
        )}
      </DownloadButtonContainer>
      <div>
        {Object.keys(developmentPlanList).map((department) => (
          <DepartmentContainer key={developmentPlanList[department][0].id}>
            <DepartmentHeader>
              <h4>{department}</h4>
            </DepartmentHeader>
            <EmployeeListContainer>
              {developmentPlanList[department].map((employee) => (
                <Fragment key={`${employee.id}.${Math.random(10000)}`}>
                  {employee.evaluationCycleDevelopmentPlans.map((plan) => (
                    <EmployeeInformation
                      key={`${employee.id}.${Math.random(1000)}.${Math.random(1000)}`}
                      finished={(employee.__meta__.employeeReceivedPdi === 0).toString()}>
                      <div>{employee.name}</div>
                      <div>{plan.developmentPlans?.action}</div>
                      <div>{planStatus[plan.status]}</div>
                      <div>{plan.action}</div>
                    </EmployeeInformation>
                  ))}
                </Fragment>
              ))}
            </EmployeeListContainer>
          </DepartmentContainer>
        ))}
      </div>
    </MainContainer>
  );
}

export default memo(PdiReport);
