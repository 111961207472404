import React, { memo } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import './config/ReactotronConfig';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from '~/store';

import { Modal } from './components';
import Routes from './routes/index';
import history from './services/history';
import GlobalStyle from './styles/global';

function App() {
  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: 'dark',
        },
        overrides: {
          MuiTooltip: {
            tooltip: {
              fontSize: '1.2rem',
            },
          },
        },
      }),
    [],
  );

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Router history={history}>
              <Routes />
            </Router>
            <GlobalStyle />
            <ToastContainer autoClose={9000} position='top-center' />
            <Modal />
          </MuiPickersUtilsProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default memo(App);
