import { Types as AuthTypes } from '../auth/actions';
import { Types as UserTypes } from './actions';

const acceptedTypes = {
  [AuthTypes.SIGN_IN_SUCCESS](draft, payload) {
    draft.profile = payload.user;
    draft.id = payload.user.id;
  },
  [UserTypes.UPDATE_PROFILE_SUCESS](draft, payload) {
    draft.profile = payload.profile;
  },
  [UserTypes.CHECK_IF_USER_IS_ADMIN_SUCCESS](draft, payload) {
    draft.admin = payload.admin;
  },
  [UserTypes.CHECK_IF_USER_IS_ADMIN_FAILURE](draft, _payload) {
    draft.admin = false;
  },
  [UserTypes.UPDATE_BREADCRUMB_REQUEST](draft, payload) {
    draft.breadcrumb = payload.breadcrumb;
  },
  [UserTypes.UPDATE_PROCESS_REQUEST](draft, payload) {
    draft.process = payload.process;
  },
  [AuthTypes.SIGN_OUT_REQUEST](draft, _payload) {
    draft.profile = null;
  },
  [UserTypes.UPDATE_OPTIONS_REQUEST](draft, payload) {
    draft.options = payload.options;
  },
};

export default acceptedTypes;
