export const Types = {
  UPDATE_PROFILE_REQUEST: '@user/UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCESS: '@user/UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE: '@user/UPDATE_PROFILE_FAILURE',
  CHECK_IF_USER_IS_ADMIN_REQUEST: '@user/CHECK_IF_USER_IS_ADMIN_REQUEST',
  CHECK_IF_USER_IS_ADMIN_SUCCESS: '@user/CHECK_IF_USER_IS_ADMIN_SUCCESS',
  CHECK_IF_USER_IS_ADMIN_FAILURE: '@user/CHECK_IF_USER_IS_ADMIN_FAILURE',
  UPDATE_BREADCRUMB_REQUEST: '@user/UPDATE_BREADCRUMB_REQUEST',
  UPDATE_IS_LOADING_REQUEST: '@user/UPDATE_IS_LOADING_REQUEST',
  UPDATE_PROCESS_REQUEST: '@user/UPDATE_PROCESS_REQUEST',
  UPDATE_OPTIONS_REQUEST: '@user/UPDATE_OPTIONS_REQUEST',
};

export function updateProfileRequest(data) {
  return {
    type: Types.UPDATE_PROFILE_REQUEST,
    payload: { data },
  };
}

export function updateProfileSuccess(profile) {
  return {
    type: Types.UPDATE_PROFILE_SUCESS,
    payload: { profile },
  };
}

export function updateProfileFailure() {
  return {
    type: Types.UPDATE_PROFILE_FAILURE,
  };
}

export function checkIfUserIsAdminRequest(profile) {
  return {
    type: Types.CHECK_IF_USER_IS_ADMIN_REQUEST,
    payload: { profile },
  };
}

export function checkIfUserIsAdminSuccess(admin) {
  return {
    type: Types.CHECK_IF_USER_IS_ADMIN_SUCCESS,
    payload: { admin },
  };
}

export function checkIfUserIsAdminFailure() {
  return {
    type: Types.CHECK_IF_USER_IS_ADMIN_FAILURE,
  };
}

export function updateBreadcrumb(breadcrumb) {
  return {
    type: Types.UPDATE_BREADCRUMB_REQUEST,
    payload: { breadcrumb },
  };
}

export function updateProcessRequest(process) {
  return {
    type: Types.UPDATE_PROCESS_REQUEST,
    payload: { process },
  };
}

export function updateOptionsRequest(options) {
  return {
    type: Types.UPDATE_OPTIONS_REQUEST,
    payload: { options },
  };
}
