import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  border-radius: 5px;
  position: relative;
`;

const blinker = keyframes`
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
`;

export const Table = styled.table`
  padding: 0;
  margin: 0;
  border-collapse: collapse;
  table-layout: fixed;
  border-spacing: 0;

  .custom-button-effect {
    animation-name: ${(props) => (props.processing ? blinker : 'none')};
    animation-duration: 0.3s;
    animation-iteration-count: infinite;
  }

  th {
    color: #fff !important;
    background-color: #333 !important;
    font-weight: inherit !important;
    border: none !important;
  }

  th.actions-column {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
  }

  thead th {
    vertical-align: center !important;
    padding: 5px 10px !important;
    table-layout: fixed;
  }

  td {
    vertical-align: middle !important;
    padding: 5px 0 5px 10px !important;
    border-top: none !important;
    border-bottom: 1px solid #eee;
    text-overflow: ellipsis;
    overflow: hidden !important;
    white-space: nowrap;
    font-size: 1.3rem;
  }

  td:first-child {
    border-left: none;
  }

  tr td {
    border-bottom: none;
  }

  tr {
    background-color: rgb(242, 245, 250);
  }

  tr:nth-child(odd) {
    background-color: rgb(242, 245, 250);
  }

  tr:nth-child(even) {
    background: #fff;
  }

  tbody tr {
    &:hover {
      background: #c4cbcf;
      /* color: #fff; */
      cursor: pointer !important;
    }
  }

  .btn-group {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    button {
      border: none;
      background: transparent;
      padding: 0;
    }

    svg {
      &:hover {
        opacity: 0.8;
      }
    }
  }

  th.actions-column {
    button {
      border: none;
      background: transparent;
      padding: 0;
    }

    svg {
      background: #00995d;
      border-radius: 50%;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
`;
