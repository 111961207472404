import { toast } from 'react-toastify';

import { eventChannel } from 'redux-saga';
import { all, takeLatest, call, take, put, select } from 'redux-saga/effects';

import ws from '~/services/websocket';

import { Types as AuthTypes } from '../auth/actions';
// import { Types as UserTypes } from '../user/actions';
import { Types } from './actions';

export function* closeUserChannel() {
  try {
    const id = yield select((state) => state.user.id);

    const channel = ws.getSubscription(`user:${id}`);
    if (!channel) return;
    channel.close();
  } catch (err) {
    // do nothing console.log(err);
  }
}

export function subscribeUser({ id }) {
  return eventChannel((emitter) => {
    const channel = ws.getSubscription(`user:${id}`) || ws.subscribe(`user:${id}`);

    channel.on('logout', ({ forced } = { forced: false }) => {
      if (!forced) {
        toast.error('Login realizado em outro navegador');
      }
      return emitter({ type: AuthTypes.SIGN_OUT_REQUEST });
    });

    return () => {
      channel.close();
    };
  });
}

export function* watchUserSubscription({ payload: { id } }) {
  const channel = yield call(subscribeUser, {
    id,
  });

  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

export default all([
  takeLatest(Types.SUBSCRIBE_USER_REQUEST, watchUserSubscription),
  takeLatest(AuthTypes.SIGN_OUT_REQUEST, closeUserChannel),
]);
