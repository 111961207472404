import React, { useEffect, useState, useRef, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from '@unform/web';

import { MainContainer, Select } from '~/components';
import { mountOptionList, formatDate } from '~/functions';
import api from '~/services/api';
import { updateBreadcrumb } from '~/store/modules/user/actions';

import { Container, AreaContainer, FeedbackContainer } from './styles';

function DepartmentContinuousFeedback() {
  const dispatch = useDispatch();
  const process = useSelector((state) => state.user.process);
  const [areaList, setAreaList] = useState([]);
  const [childrenArea, setChildrenArea] = useState([]);
  const [feedbackList, setFeedbackList] = useState([]);
  const [allFeedbackList, setAllFeedbackList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [areaSelected, setAreaSelected] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { name: 'Início', path: '/' },
        { name: 'Configurações', path: '/sys-config' },
        { name: 'Ciclo de Avaliação', path: '/evaluation-cycle' },
        { name: 'Acompanhe os Processos deste Ciclo de Avaliação', path: '/follow' },
        { name: 'Lista de feedbacks contínuos', path: '/continuous-feedback' },
      ]),
    );

    mountOptionList('departments', setAreaList);
    mountOptionList('users', setUserList);
  }, [dispatch, process]);

  useEffect(() => {
    if (allFeedbackList.length) return;
    async function getData() {
      const { data } = await api.get('continuousfeedback');
      setFeedbackList(data);
      setAllFeedbackList(data);
    }

    getData();
  }, [allFeedbackList.length]);

  useEffect(() => {
    setChildrenArea(areaList);
  }, [areaList]);

  const handleFilterAreaSubmit = (data) => {
    if (data) {
      setChildrenArea(areaList.filter((area) => area.label === data.label));
      setAreaSelected(true);
    } else {
      setChildrenArea(areaList);
      setAreaSelected(false);
    }
  };

  function handleFilterUserSubmit(data) {
    if (data) {
      const feedbacksOfEspecificUser = allFeedbackList.filter((feedback) => feedback.employees.id === data.id);
      if (feedbacksOfEspecificUser.length > 0) {
        const userAreaList = areaList.filter((area) => area.id === feedbacksOfEspecificUser[0].employees.department_id);
        setFeedbackList(feedbacksOfEspecificUser);
        setChildrenArea(userAreaList);
      } else {
        setFeedbackList([]);
      }
    } else {
      setFeedbackList(allFeedbackList);
      if (!areaSelected) {
        setChildrenArea(areaList);
      }
    }
  }

  return (
    <MainContainer>
      <Container>
        <Form className='form' ref={formRef}>
          <Select
            name='area_id'
            placeholder='Escolha o setor'
            label='Escolha o setor'
            options={areaList}
            isMulti={false}
            closeMenuOnSelect
            onChange={handleFilterAreaSubmit}
          />
          <Select
            name='area_id'
            placeholder='Escolha um colaborador'
            label='Escolha um colaborador'
            options={userList}
            isMulti={false}
            closeMenuOnSelect
            onChange={handleFilterUserSubmit}
          />
        </Form>
        {childrenArea.length > 0 &&
          childrenArea.map((area) => (
            <AreaContainer key={area.id}>
              <h3>{area.label}</h3>
              <div>
                {feedbackList.map(
                  (feedback) =>
                    feedback.employees.departments?.name === area.label && (
                      <FeedbackContainer key={feedback.id} category={feedback.category}>
                        <div className='feedback-header'>
                          <h5>{feedback.employees.name}</h5>
                          <span className='tag'>
                            {feedback.category === 1 ? 'Feedback Positivo' : 'Feedback de Desenvolvimento'}
                          </span>
                        </div>
                        <article>{feedback.description}</article>
                        <div>
                          <p>
                            {feedback.visible_to_employee ? 'Visível para o colaborador' : 'Não está visível para o colaborador'}
                          </p>
                          <small>Colaborador confirmou em: {formatDate(feedback.employee_confirmed)}</small>
                        </div>
                      </FeedbackContainer>
                    ),
                )}
              </div>
            </AreaContainer>
          ))}
      </Container>
    </MainContainer>
  );
}

export default memo(DepartmentContinuousFeedback);
