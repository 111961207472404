import { Link } from 'react-router-dom';

import styled from 'styled-components';

export const DescriptionContainer = styled.blockquote`
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  color: #efefef;

  @media (max-width: 768px) {
    padding-top: 0px;
  }

  .text-description {
    background: #dedede;
    font-weight: 400;
    padding: 15px 25px;
    border-radius: 8px;
    width: 100%;

    @media (max-width: 768px) {
      padding: 10px 15px;
    }
  }

  h1 {
    font-weight: 900;
    font-size: 2.5rem;
    color: #555;
  }

  p,
  cite {
    font-size: 1.7rem;
    color: #666;

    i {
      font-size: 1.8rem;
      color: #000;
      border-bottom: 0.1rem dotted #888;
    }
  }

  h1,
  p {
    text-align: justify;
    margin-bottom: 10px;
  }

  .image-description {
    position: absolute;
    margin-top: -20px;
    right: 60px;

    @media (max-width: 768px) {
      right: 25px;
      margin-top: 10px;

      img {
        max-width: 60px;
      }
    }

    img {
      width: 125px;
      z-index: 1;
    }
  }

  @media (max-width: 768px) {
    h1,
    p {
      margin-bottom: 7px;
    }
  }

  p.instruction-container {
    margin-top: 15px;
  }
`;

export const UpContainer = styled.div`
  width: 100%;
  /* max-width: calc(100vw - 129px); */
  padding: 10px 0;
  overflow: hidden;
  align-items: stretch;

  @media (max-width: 768px) {
    padding: 10px 0;
    max-width: 100%;
    margin-bottom: 10px;
  }

  & > div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(33rem, 33rem));
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    min-height: 0; /* NEW */
    min-width: 0; /* NEW; needed for Firefox */

    @media (max-width: 1280px) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
      grid-template-rows: repeat(auto-fit, minmax(33rem, 33rem));
      grid-row-gap: 20px;
      min-height: 0;
      min-width: 0;
    }

    @media (max-width: 860px) {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: repeat(auto-fit, minmax(33rem, 33rem));
      grid-row-gap: 20px;
      min-height: 0; /* NEW */
      min-width: 0; /* NEW; needed for Firefox */
    }

    @media (max-height: 825px) {
      grid-template-rows: repeat(280px);
    }
  }
`;

export const CardContainer = styled(Link)`
  a {
    text-decoration: none;
  }

  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  opacity: 1;
  background: ${(props) => props.backgroundcolor};
  border-radius: 8px;
  transition: all 0.2s ease 0s;
  padding: 20px 25px;
  border-image: initial;
  border: 1px solid #eee;

  .card-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 10px;
    margin-bottom: 10px;
    background-image: linear-gradient(to right, #411564, #999);
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 80% 1px;
    transition: background-size 3s ease;

    h2 {
      color: #444;
      margin: 0;
      font-size: 1.9rem;
    }

    @media (max-width: 768px) {
      padding-bottom: 5px;
    }
  }

  @media (max-width: 768px) {
    padding: 15px 20px !important;
  }

  p {
    color: #444;
    font-size: 1.6rem;
    font-weight: 300;
  }

  span {
    color: #222;
    font-size: 10rem;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;

    /* @media (max-width: 768px) {
      display: none;
    } */

    img {
      object-fit: contain;
      width: 20rem;
      height: 100%;
    }
  }

  &:hover {
    /* border: 1px solid #b1d34b !important; */
    cursor: pointer;
    transform: translate3d(0, -1%, 0);
  }

  div.pdi-chart-container {
    position: relative;
    width: 325px;
    height: 213px;

    canvas {
      margin-top: -55px;
    }
  }
`;

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  opacity: 1;
  border-radius: 8px;
  transition: all 0.2s ease 0s;
  padding: 20px 25px;
  /* border: 2px solid rgb(0, 0, 0, 0) !important; */
  border-image: initial;
  /* box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12); */
  position: relative;

  &:hover {
    cursor: pointer;
    transform: translate3d(0, -2%, 0);
  }

  .card-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    h2 {
      color: #444;
      margin: 0;
      font-size: 1.9rem;
    }

    @media (max-width: 768px) {
      padding-bottom: 5px;
    }
  }

  .react-calendar {
    border: none;
    line-height: 1em;
  }

  .react-calendar__navigation {
    margin-bottom: 0;
  }

  .react-calendar__tile {
    padding: 0.25em;
  }

  .react-calendar__tile--hasActive {
    background-color: #0a5f55;
    color: #fff;
  }

  .react-calendar__tile--now {
    background-color: #b1d34b !important;
    color: #333;
  }

  .react-calendar__tile--active {
    background-color: #00995d;
  }
`;

export const ExpiredPeriodCardContainer = styled.div`
  a {
    text-decoration: none;
  }

  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  opacity: 1;
  background: ${(props) => props.backgroundcolor};
  border-radius: 8px;
  transition: all 0.2s ease 0s;
  padding: 20px 25px;
  border-image: initial;

  .card-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 10px;
    margin-bottom: 10px;
    background-image: linear-gradient(to right, #411564, #999);
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 80% 1px;
    transition: background-size 3s ease;

    h2 {
      color: #f47920;
      margin: 0;
      font-size: 1.9rem;
    }

    @media (max-width: 768px) {
      padding-bottom: 5px;
    }
  }

  i {
    color: #f47920;
  }

  @media (max-width: 768px) {
    padding: 15px 20px !important;
  }

  p {
    color: #444;
    font-size: 1.6rem;
    font-weight: 300;
  }

  span {
    color: #222;
    font-size: 10rem;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;

    /* @media (max-width: 768px) {
      display: none;
    } */

    img {
      object-fit: contain;
      width: 20rem;
      height: 100%;
    }
  }

  &:hover {
    /* border: 1px solid #b1d34b !important; */
    cursor: pointer;
    transform: translate3d(0, -1%, 0);
  }
`;

export const DataSummaryContainer = styled.section`
  /* background: #fff; */
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(100% / 4), 1fr));
  grid-template-rows: repeat(minmax(180px, 180px));
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  min-height: 0; /* NEW */
  min-width: 0; /* NEW; needed for Firefox */
  margin: 20px 0 10px 0;

  @media (max-width: 1170px) {
    grid-template-columns: repeat(auto-fit, minmax(calc(100% / 3), 1fr));
  }

  @media (max-width: 520px) {
    grid-template-columns: repeat(auto-fit, minmax(calc(100% / 2), 1fr));
  }

  section {
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 15px 20px 15px;
    max-width: 100%;
    justify-content: space-between;
    height: 100%;
    transition: all 0.6s;
    /* border: 1px solid #ddd; */
    border-radius: 10px;

    &:hover {
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      background: linear-gradient(145deg, #f0f0f0, #cacaca);
    }

    h6 {
      color: #444;
      font-size: 1.6rem;
      font-weight: 600;
      width: 100%;
      text-align: center;
    }

    span {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      width: 100%;

      @media (max-width: 768px) {
        flex-direction: column-reverse;
      }

      small {
        background: rgba(255, 255, 255, 0.4);
        color: #0a5f55;
        padding: 2px 10px 4px 10px;
        border-radius: 10px;

        @media (max-width: 768px) {
          margin-bottom: 10px;
        }
      }

      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        letter-spacing: 2px;

        p {
          color: #999;
          font-size: 1.5rem;
        }

        h2 {
          font-size: 2.6rem;
          color: #222;
          font-weight: 700;
          margin-bottom: 10px;
        }
      }
    }
  }

  section.department-container {
    background: linear-gradient(180deg, #a3238e, #411564);

    &:hover {
      box-shadow: none;
    }

    h6 {
      margin-bottom: 5px;
      color: #fff;
      /* border-bottom: 1px dotted #ffcb08; */
    }

    div.sub-department-container {
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      align-content: center;

      span {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        width: 100%;
        min-width: 66px;
        margin-bottom: 5px;

        p {
          color: #fff0c7;
          font-weight: 300;
        }

        small {
          background: rgba(255, 240, 199, 0.3);
          color: #411564;
          font-weight: 700;
          padding: 3px 0 4px 0;
          border-radius: 10px;
          width: 70px;
          align-items: center;
          text-align: center;
        }

        small.description {
          background: transparent;
          color: #ccc;
          font-size: 1.2rem;
          text-align: center;
          padding: 5px 0;
        }

        h2 {
          font-size: 1.9rem;
          color: #ffcb08;
          font-weight: 700;
          margin-bottom: 0px;
        }
      }
    }
  }
`;

function getTextPercentage(value) {
  return `${value}%`;
}

export const ProgressBar = styled.div`
  background: #eee;
  border-radius: 50px;
  height: 2.7rem;
  line-height: 0;
  font-size: 0.75rem;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  animation: width 2s ease-out forwards;
  position: relative;

  div {
    width: ${(props) => getTextPercentage(props.percentage)};
    background: linear-gradient(45deg, #00995d, #b1d34b) !important;
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: #222;
    font-weight: 500;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  align-content: center;

  form {
    width: 100%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 20px;

    a {
      margin-left: 0 !important;
    }
  }
`;
