import React, { memo, useState } from 'react';
import makeAnimated from 'react-select/animated';
import { Form } from '@unform/web';
import styled from 'styled-components';
import { Select, MainContainer } from '~/components';
import Input from '~/components/Input';
import { DescriptionContainer, Container } from './styles';
import 'react-perfect-scrollbar/dist/css/styles.css';

const ButtonAdicionar = (props) => <button className='buttonAdicionar' onClick={props.onClick}>{props.text}</button>
const ButtonRemover = (props) => <button className='buttonRemover' onClick={props.onClick}>{props.text}</button>
const ButtonCadastrar = (props) => <button className='buttonCadastrar' onClick={props.onClick}>{props.text}</button>
//const SelectTraining = (props) => <select>{props.options}</select>

export default function handleAddTraining() {
 
  const training = () => (['curso1', 'curso2', 'curso3', 'curso4'])


  const trainingName = ([
    {value : 'curso1', label: 'curso1'},
    {value : 'curso2', label: 'curso2'}
  ])

  const pivotraining = ([

  ]);

  const optionsDois = [
    { value: 'Curso1', label: 'Curso1' },
    { value2: 'Curso2', label: 'Curso2' },
    { value3: 'Curso3', label: 'Curso3' },
  ];
  
  return (
    <MainContainer>
      <section>
        <header>
          <Container>
            <DescriptionContainer>
              <section>
                <h1>Treinamento</h1>
                <span>Esta área serve para você registrar o treinamento feito pelo RH.</span>
              </section>
            </DescriptionContainer>
            <section>
              <div className='treinamentos'>
                <select className='Cursos'>
                  <option value=' '>Selecione seu curso!</option>
                  <option value=' '>Recursos Humanos</option>
                </select>
              </div>
            </section>
            <Form onSubmit='' onChange=''>
              <div className='setor'>

                <select className='Setores' options={training}>
                  <option value=' '>Tecnologia da Informação</option>
                  <option value=' '>Recursos Humanos</option>
                </select>
              </div>
            </Form>

            <div className='treinamentos1'>
              <div className='tabelas'>
                <select className='curso1' id='leftBox' multiple='multiple'>
                  <option value='treinamento1'>Teste</option>
                  <option value='treinamento1'>Teste</option>
                  <option value='treinamento1'>Teste</option>
                  <option value='treinamento1'>Teste</option>
                  <option value='treinamento1'>Teste</option>
                  <option value='treinamento1'>Teste</option>
                </select>

                <div className='button'>
                <ButtonAdicionar  text='Adicionar'  />
                <ButtonRemover  text='Remover' />
                </div>

                <select className='curso2' name='Curso' size='2' multiple='multiple' id='rightBox'>
                  <option value='' />
                </select>
                <br />
              </div>
            </div>
            <section>
              <div>
                <input type='date' className='data' />
              </div>
              <div>
                <input type='time' className='data' />
              </div>
              <ButtonCadastrar text='Cadastrar' />
            </section>
          </Container>
        </header>
      </section>
    </MainContainer>
  );
}
