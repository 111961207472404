import React, { memo, useRef, useEffect } from 'react';

import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import { Container } from './styles';

const Rating = ({ name, options }) => {
  const inputRefs = useRef([]);
  const { fieldName, registerField, defaultValue = [] } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs) => {
        return refs.filter((ref) => ref.checked).map((ref) => ref.value);
      },
      clearValue: (refs) => {
        refs.forEach((ref) => {
          ref.checked = false;
        });
      },
      setValue: (refs, values) => {
        refs.forEach((ref) => {
          if (values.includes(ref.id)) {
            ref.checked = true;
          }
        });
      },
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <Container>
      {options &&
        options.map((option, index) => (
          <label htmlFor={`${fieldName}.${option.id}`} className='container' key={`${fieldName}.${option.id}`}>
            {option.title}
            <input
              ref={(ref) => {
                inputRefs.current[index] = ref;
              }}
              value={option.value}
              defaultChecked={defaultValue.find((value) => Number(value) === Number(option.value))}
              type='radio'
              name={fieldName}
              id={`${fieldName}.${option.id}`}
            />
            <span className='checkmark' />
          </label>
        ))}
    </Container>
  );
};

Rating.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
};

export default memo(Rating);
