import React, { memo } from 'react';

import Panel from '~/components/Panel';
import Skeleton from '~/components/Skeleton';

import { Container } from './styles';

function LoadingTable() {
  return (
    <Container>
      <Panel className='no-shadow'>
        <Skeleton className='header-skeleton' />
        <span>
          <Skeleton className='row-table-skeleton' />
          <Skeleton className='row-table-skeleton' />
          <Skeleton className='row-table-skeleton' />
          <Skeleton className='row-table-skeleton' />
          <Skeleton className='row-table-skeleton' />
          <Skeleton className='row-table-skeleton' />
          <Skeleton className='row-table-skeleton' />
          <Skeleton className='row-table-skeleton' />
          <Skeleton className='row-table-skeleton' />
        </span>
      </Panel>
    </Container>
  );
}

export default memo(LoadingTable);
