import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import { Form } from '@unform/web';

import logo from '~/assets/logo_unimed.png';
import { Input } from '~/components';

function ForgotPassword() {
  async function handleSubmit(data) {
    console.tron.log(data);
  }
  return (
    <>
      <img src={logo} alt='Unimed' />
      <Form onSubmit={handleSubmit}>
        <Input name='username' placeholder='Seu usuário' />
        <button type='submit'>Recuperar Senha</button>
        <Link to='/'>Voltar</Link>
      </Form>
    </>
  );
}

export default memo(ForgotPassword);
