export const headers = [
  {
    title: 'Descrição',
    width: '70%',
    align: 'left',
    columnName: 'description',
    dataType: String,
  },
  {
    title: 'Nível',
    width: '20%',
    align: 'left',
    columnName: 'level',
    dataType: String,
  },
];
