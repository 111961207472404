import styled from 'styled-components';

export const EmployeeSummaryContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #333;
  border-radius: 20px;
  color: #fff;
  border-radius: 8px;

  > div {
    padding: 20px;
  }

  div.development-plans-container {
    background: #444;
    width: 100%;
    color: #fff0c7;
    border-radius: 8px;

    @media (max-width: 768px) {
      border-radius: 25px;
    }

    h3 {
      color: #fff;
      margin-bottom: 10px;
      width: 100%;
      text-align: center;

      @media (max-width: 768px) {
        margin-bottom: 5px;
        font-size: 1.6rem;
      }

      @media (max-width: 1280px) {
        margin-bottom: 5px;
      }
    }

    section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 100%;

      div {
        width: 100%;
        height: 100%;
        font-weight: 300;
        text-align: justify;
        font-size: 1.6rem;
        line-height: 2rem;

        @media (max-width: 768px) {
          font-size: 1.4rem;
        }

        @media (max-width: 1220px) {
          font-size: 1.5rem;
          line-height: normal;
        }
      }
    }
  }

  div.chart-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;

    div.pdi-chart-container {
      position: relative;
      width: 150px;

      canvas {
        margin-top: 10px;
      }
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const Article = styled.article`
  width: 100%;
  margin: 0 0 20px 0;
  position: relative;

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  div.inner {
    width: 44%;
    float: left;
    margin: 5px 0 0 0;
    border-radius: 6px;

    @media (max-width: 768px) {
      width: 80%;
    }
  }

  div.inner span.date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    padding: 5px 0;
    position: absolute;
    top: 0;
    left: 50%;
    margin: 0 0 0 -32px;
    border-radius: 100%;
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
    background: #c4cbcf;
    color: #000;
    border: 2px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 7px #ddd;

    @media (max-width: 768px) {
      left: 40px;
    }
  }

  div.inner span.date span {
    display: block;
    text-align: center;
  }

  div.inner span.date span.day {
    font-size: 10px;
    color: #444;
  }

  div.inner span.date span.month {
    font-size: 20px;
  }

  div.inner span.date span.year {
    font-size: 10px;
    color: #444;
  }

  div.inner section.header-container {
    padding: 15px;
    margin: 0;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    border-radius: 6px 6px 0 0;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: ${(props) => (props.headerColor === 1 ? '#00995d' : '#ed1651')};

    &:after {
      background: ${(props) => (props.headerColor === 1 ? '#00995d' : '#ed1651')};
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    svg {
      margin-left: 10px;
      cursor: pointer;
    }

    div {
      display: flex;
      flex-direction: row;
    }
  }

  div.inner section.header-container:after {
    content: '';
    position: absolute;
    top: 20px;
    right: -5px;
    width: 10px;
    height: 10px;
    transform: rotate(-45deg);
  }

  div.inner p {
    padding: 15px;
    margin: 0;
    font-size: 14px;
    background: #fff;
    color: #656565;
    display: flex;
    flex-direction: column;

    &:last-child {
      border-radius: 0 0 6px 6px;
    }

    &:not(:first-of-type) {
      border-top: 1px dashed #c4cbcf;
      background: #fff;
    }

    small {
      color: #888;
      margin: 3px 0;
      letter-spacing: 0.15rem;
      font-weight: 300;

      &:not(:first-of-type) {
        color: #f47920;
      }
    }
  }

  div.inner {
    @media (max-width: 768px) {
      float: right !important;
    }
  }

  div.inner section.header-container:after {
    @media (max-width: 768px) {
      left: -5px;
    }
  }

  &:nth-child(2n + 2) div.inner {
    float: right;
  }

  &:nth-child(2n + 2) div.inner section.header-container:after {
    left: -5px;
  }
`;

export const Section = styled.section`
  width: 100%;
  margin: 0 auto 20px auto;
  position: relative;
  top: 25px;
  display: ${(props) => (props.show ? 'block' : 'none')};
  padding-bottom: 20px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    margin: 0 0 0 -1px;
    width: 2px;
    height: 100%;
    background: rgba(104, 45, 0, 0.6);

    @media (max-width: 768px) {
      left: 45px;
      padding-bottom: 40px;
    }
  }
`;

export const WithoutContinuousFeedbackContainer = styled.div`
  width: 100%;
  margin: 20px auto 0 auto;
  text-align: center;
  padding: 30px;
  background: #fff;
  border-radius: 8px;
  font-size: 1.8rem;
`;
