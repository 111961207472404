import { useState, useEffect } from 'react';

function usePlayerState(playerRef) {
  const [playerState, setPlayerState] = useState({
    playing: false,
    percentage: 0,
    elapsedTime: '00:00:00',
    duration: '00:00:00',
  });

  const formatTime = (seconds) =>
    new Date(seconds * 1000).toLocaleTimeString('pt-BR', {
      timeZone: 'Etc/UTC',
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

  function toggleVideoPlayer() {
    setPlayerState((state) => ({ ...state, playing: !state.playing }));
  }

  function handleTimeUpdate() {
    const { currentTime, duration } = playerRef.current;
    const currentPercentage = (currentTime / duration) * 100;
    setPlayerState((state) => ({
      ...state,
      percentage: currentPercentage,
      elapsedTime: formatTime(currentTime),
      duration: formatTime(duration),
    }));
  }

  function handleChangeVideoPercentage(event) {
    const currentPercentageValue = event.target.value;
    const { duration } = playerRef.current;
    playerRef.current.currentTime = (duration / 100) * currentPercentageValue;
    setPlayerState((state) => ({ ...state, percentage: currentPercentageValue }));
  }

  function handleLoadData() {
    const { duration } = playerRef.current;
    setPlayerState((state) => ({
      ...state,
      duration: formatTime(duration),
    }));
  }

  useEffect(() => {
    playerState.playing ? playerRef.current.play() : playerRef.current.pause();
  }, [playerRef, playerState.playing]);

  return { playerState, toggleVideoPlayer, handleTimeUpdate, handleChangeVideoPercentage, handleLoadData };
}

export default usePlayerState;
