import React, { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import Breadcrumb from '~/components/Breadcrumb';
import Header from '~/components/Header';
import { signOut } from '~/store/modules/auth/actions';

import { Wrapper, ChildrenContainer } from './styles';

function DefaultLayout({ children }) {
  const breadcrumb = useSelector((state) => state.user.breadcrumb);

  const dispatch = useDispatch();

  useEffect(() => {
    const doSignOut = () => {
      dispatch(signOut());
    };

    window.addEventListener('unload', doSignOut);
  }, [dispatch]);

  return (
    <Wrapper>
      <Header />
      <Breadcrumb crumbs={breadcrumb ?? [{}]} />
      <ChildrenContainer>{children}</ChildrenContainer>
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default memo(DefaultLayout);
