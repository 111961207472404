import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
  /* height: 100%; */ /* Foi removido pois estava bugando o código */

  h2 {
    /* margin-bottom: 15px; */
  }

  div {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(100% / 6), 1fr));
    grid-template-rows: repeat(minmax(180px, 234px));
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    min-height: 0; /* NEW */
    min-width: 0; /* NEW; needed for Firefox */
    margin: 20px 0 10px 0;
    /* height: 100%; */

    @media (max-width: 1170px) {
      grid-template-columns: repeat(auto-fit, minmax(calc(100% / 3), 1fr));
    }

    @media (max-width: 520px) {
      grid-template-columns: repeat(auto-fit, minmax(calc(100% / 2), 1fr));
    }
  }

  a {
    p {
      text-decoration: none;
      text-align: justify;
      color: #333;
      font-weight: 300;
    }

    section {
      background: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px 15px 20px 15px;
      min-width: 200px;
      max-width: 100%;
      justify-content: space-between;
      height: 100%;
      transition: all 0.6s;
      /* border: 1px solid #ddd; */
      border-radius: 10px;

      &:hover {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        background: linear-gradient(145deg, #f0f0f0, #cacaca);
      }

      h6 {
        color: #444;
        font-size: 1.6rem;
        font-weight: 600;
        width: 100%;
        text-align: center;
      }

      span {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        width: 100%;

        @media (max-width: 768px) {
          flex-direction: column-reverse;
        }

        small {
          background: rgba(255, 255, 255, 0.4);
          color: #0a5f55;
          padding: 2px 10px 4px 10px;
          border-radius: 10px;

          @media (max-width: 768px) {
            margin-bottom: 10px;
          }
        }

        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          align-content: center;
          letter-spacing: 2px;

          p {
            color: #999;
            font-size: 1.5rem;
          }

          h2 {
            font-size: 2.6rem;
            color: #222;
            font-weight: 700;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
`;
