export const headers = [
  {
    title: 'Nome',
    width: '20%',
    align: 'left',
    columnName: 'name',
    dataType: String,
  },
  {
    title: 'Registro',
    width: '5%',
    align: 'left',
    columnName: 'registry',
    dataType: String,
  },
  {
    title: 'Setor',
    width: '20%',
    align: 'left',
    columnName: 'departments.name',
    dataType: String,
  },
  {
    title: 'Cargo',
    width: '22%',
    align: 'left',
    columnName: 'positions.description',
    dataType: String,
  },
  {
    title: 'Email',
    width: '18%',
    align: 'left',
    columnName: 'email',
    dataType: String,
  },
  {
    title: 'Status',
    width: '5%',
    align: 'left',
    columnName: 'active',
    dataType: Boolean,
  },
];
