import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  align-content: center;

  @media (max-width: 1280px) {
    max-width: 100%;
    padding: 20px 20px 40px 20px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;
    max-width: 600px;

    input {
      background: rgba(0, 0, 0, 0.1);
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #333;
      margin: 0 0 10px;

      &::placeholder {
        color: #888;
      }
    }

    button {
      margin: 5px 0 0;
      height: 44px;
      background: #411564;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.08, '#411564')};
      }
    }
    button1 {
      margin: 5px 0 0;
      height: 44px;
      background: #0094FF;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;
      text-align: center;
      padding: 2%;
      cursor: pointer;

      &:hover {
        background: ${darken(0.08, '#0094FF')};
      }
    }

    hr {
      border: 0;
      height: 1px;
      background: rgba(3, 3, 3, 0.2);
      margin: 10px 0 20px;
    }

    button.logout {
      background: #f47920;

      &:hover {
        background: ${darken(0.08, '#f47920')};
      }
    }
  }
`;
