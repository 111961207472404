import React, { memo } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

import { Container, EntityNameConfirmation, TitleStyled } from './styles';

function AlertDialog({ open, setOpen, confirmAction, title, actionText, actionName, entityData }) {
  return (
    <Container>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          <TitleStyled>{title}</TitleStyled>
          <EntityNameConfirmation>{entityData.name ?? entityData.description}</EntityNameConfirmation>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{actionText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color='secondary'>
            Cancelar
          </Button>
          <Button onClick={() => confirmAction()} color='primary' autoFocus>
            {actionName}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  entityData: PropTypes.instanceOf(Object).isRequired,
  actionText: PropTypes.string.isRequired,
  actionName: PropTypes.string.isRequired,
};

export default memo(AlertDialog);
