import produce from 'immer';

import { Types as CycleTypes } from './actions';

const INITIAL_STATE = {
  id: null,
  initialDate: null,
  finalDate: null,
  initialManagerFeedback: null,
  finalManagerFeedback: null,
  initialEmployeeEvaluation: null,
  finalEmployeeEvaluation: null,
  initialManagerEvaluation: null,
  finalManagerEvaluation: null,
  description: null,
  options: {},
};

export default function cycle(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case CycleTypes.UPDATE_EVALUATION_CYCLE_IN_USE: {
        draft.id = action.payload.id;
        draft.initialDate = action.payload.initialDate;
        draft.finalDate = action.payload.finalDate;
        draft.initialManagerFeedback = action.payload.initialManagerFeedback;
        draft.finalManagerFeedback = action.payload.finalManagerFeedback;
        draft.initialEmployeeEvaluation = action.payload.initialEmployeeEvaluation;
        draft.finalEmployeeEvaluation = action.payload.finalEmployeeEvaluation;
        draft.initialManagerEvaluation = action.payload.initialManagerEvaluation;
        draft.finalManagerEvaluation = action.payload.finalManagerEvaluation;
        draft.description = action.payload.description;
        break;
      }
      case CycleTypes.UPDATE_EVALUATION_CYCLE_OPTIONS: {
        draft.options = action.payload;
        break;
      }
      default:
        break;
    }
  });
}
