import styled from 'styled-components';

export const Container = styled.header`
  background: ${(props) => props.background};
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;

  .logo-header {
    width: 130px;
  }

  @media print {
    display: none;
  }

  @media (max-width: 1250px) {
    padding: 0 20px;
  }

  @media (max-width: 1210px) {
    padding: 0 15px;
  }

  @media (max-width: 1140px) {
    padding: 0 20px;
  }

  @media (max-width: 768px) {
    padding: 0 10px;
    .logo-header {
      width: 110px;
    }
  }
`;

export const Content = styled.div`
  height: 64px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  nav {
    display: flex;
    align-items: center;

    img {
      margin-right: 20px;
      padding-right: 20px;
      border-right: 1px solid #eee;

      @media (max-width: 768px) {
        margin-right: 12px;
        padding-right: 12px;
      }
    }

    a {
      font-weight: bold;
      color: #f47920;
    }
  }

  aside {
    display: flex;
    align-items: center;
  }

  span.header-gestao {
    b {
      color: #ffcb08;
    }

    font-size: 28px;
    color: #b1d34b;
    font-weight: 100;

    @media (max-width: 900px) {
      font-size: 2.2rem;
    }

    @media (max-width: 768px) {
      font-size: 1.9rem;
    }

    @media (max-width: 384px) {
      font-size: 1.7rem;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid #eee;
  align-items: center;

  @media (max-width: 768px) {
    margin-left: 12px;
    padding-left: 12px;
  }

  div {
    text-align: right;

    strong {
      display: block;
      color: #eee;
      font-weight: 900;
      font-size: 1.3rem;

      @media (max-width: 768px) {
        display: none;
      }
    }

    a {
      display: block;
      margin-top: 2px;
      font-size: 12px;
      color: #ffcb08;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  img {
    height: 32px;
    width: 32px;
    border-radius: 100%;
    display: block;

    @media (max-width: 768px) {
      display: none;
    }
  }
`;
