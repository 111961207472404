import React, { memo } from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';

const DoughnutChart = ({ total, value, width, color, stroke }) => {
  return (
    <Container
      width={width}
      color={color}
      stroke={stroke}
      dasharray={`${Number((value / total) * 100).toFixed(2)} ${100 - Number((value / total) * 100).toFixed(2)}`}>
      <svg width='100%' height='100%' viewBox='0 0 40 40' className='donut'>
        <circle className='donut-hole' cx='20' cy='20' r='15.91549430918954' fill='#fff' />
        <circle className='donut-ring' cx='20' cy='20' r='15.91549430918954' fill='transparent' strokeWidth='3.5' />
        <circle
          className='donut-segment donut-segment-2'
          cx='20'
          cy='20'
          r='15.91549430918954'
          fill='transparent'
          strokeWidth='3.5'
          strokeDasharray={`${Number((value / total) * 100).toFixed(2)} ${100 - Number((value / total) * 100).toFixed(2)}`}
          strokeDashoffset='25'
        />
        <g className='donut-text donut-text-1'>
          <text y='50%' transform='translate(0, 2)'>
            <tspan x='50%' textAnchor='middle' className='donut-percent'>
              {Number((value / total) * 100).toFixed(2)}%
            </tspan>
          </text>
          <text y='60%' transform='translate(0, 2)'>
            <tspan x='50%' textAnchor='middle' className='donut-data'>
              {value} de {total}
            </tspan>
          </text>
        </g>
      </svg>
    </Container>
  );
};

DoughnutChart.propTypes = {
  total: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  width: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  stroke: PropTypes.string,
};

DoughnutChart.defaultProps = {
  stroke: null,
};

export default memo(DoughnutChart);
