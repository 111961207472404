import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';

import { updateProfileSuccess, updateProfileFailure, checkIfUserIsAdminSuccess, checkIfUserIsAdminFailure } from './actions';

export function* updateProfile({ payload }) {
  try {
    const { name, email, avatar, ...rest } = payload.data;
    const profile = {
      name,
      email,
      avatar,
      ...(rest.oldPassword ? rest : {}),
    };

    const response = yield call(api.put, 'profile', profile);

    toast.success('Perfil atualizado com sucesso!');

    yield put(updateProfileSuccess(response.data));
  } catch (error) {
    toast.error(`Erro ao atualizar perfil, confira seus dados! ${error.message}`);
    yield put(updateProfileFailure());
  }
}

export function* checkIfUserIsAdmin({ payload }) {
  try {
    if (!payload.profile) return;
    const { roles } = payload.profile;

    if (roles) {
      yield put(checkIfUserIsAdminSuccess(roles.includes('administrator')));
    }
  } catch (error) {
    toast.error(`Não foi possível verificar o usuário! ${error.message}`);
    yield put(checkIfUserIsAdminFailure());
  }
}

export default all([
  takeLatest('@user/UPDATE_PROFILE_REQUEST', updateProfile),
  takeLatest('@user/CHECK_IF_USER_IS_ADMIN_REQUEST', checkIfUserIsAdmin),
]);
