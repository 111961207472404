import React, { memo } from 'react';

import PropTypes from 'prop-types';

import {
  Container,
  ClassMenuTopContainer,
  ClassMenuContainer,
  MenuHeaderContainer,
  ProgressCircle,
  TitleContainer,
  MenuItemsContainer,
} from './styles';

function MenuClassContainer({ course }) {
  return (
    <Container>
      <ClassMenuTopContainer>
        {course &&
          course.map((chapter, index) => (
            <ClassMenuContainer>
              <MenuHeaderContainer>
                <ProgressCircle>
                  <svg viewBox='0 0 38 38' className='circle'>
                    <circle r='18' cx='19' cy='19' />
                  </svg>
                  <svg
                    className='stroke'
                    stroke='currentColor'
                    fill='currentColor'
                    strokeWidth='0'
                    viewBox='0 0 512 512'
                    color='#00995d'
                    size='11'
                    height='11'
                    width='11'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z' />
                  </svg>
                  <span>{index + 1}</span>
                </ProgressCircle>
                <TitleContainer>
                  <span>{chapter.name}</span>
                  <div>
                    <span>{chapter.lessons.length} aulas</span>
                  </div>
                </TitleContainer>
              </MenuHeaderContainer>
              <MenuItemsContainer>
                <ul>
                  {chapter.lessons.map((lesson) => (
                    <li>
                      <button type='button' className={lesson.cssClass} aria-label=' ' />
                      <div>
                        <a href={lesson.url} className={lesson.cssClass}>
                          {lesson.name}
                        </a>
                      </div>
                    </li>
                  ))}
                </ul>
              </MenuItemsContainer>
            </ClassMenuContainer>
          ))}
      </ClassMenuTopContainer>
    </Container>
  );
}

MenuClassContainer.propTypes = {
  course: PropTypes.instanceOf(Array).isRequired,
};

export default memo(MenuClassContainer);
