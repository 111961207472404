import React, { memo, useEffect, useState, useCallback } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import AnimatedNumber from 'animated-number-react';
import { parseISO, formatDistance } from 'date-fns';
import pt from 'date-fns/locale/pt';

import * as routes from '~/routes/config';
import api from '~/services/api';
import { updateBreadcrumb } from '~/store/modules/user/actions';

import { LoadingCard } from '../../components/Shimmer';
import { Container, DescriptionContainer, EmployeesContainer, UpContainer, CardContainer, Square } from './styles';

function AreaEmployees() {
  const dispatch = useDispatch();
  const process = useSelector((state) => state.user.process);

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { name: 'Início', path: '/' },
        { name: `Lista de Colaboradores - ${process === 'evaluation' ? 'Avaliação' : 'Feedback'}`, path: '/area-employees' },
      ]),
    );
  }, [dispatch, process]);

  const [employeeList, setEmployeeList] = useState([]);
  const [employeeEvaluated, setEmployeeEvaluated] = useState(0);
  const [employeeEvaluatedHimself, setEmployeeEvaluatedHimself] = useState(0);
  const [childrenAreas, setChildrenAreas] = useState([]);
  const [employeeHasFeedback, setEmployeeHasFeedback] = useState(0);
  const [employeeReceivedFeedback, setEmployeeReceivedFeedback] = useState(0);

  useEffect(() => {
    async function getData() {
      const { data: employees } = await api.get(`departmentemployees`);
      const sortedEmployees = employees.sort((employeeA, employeeB) => employeeA.name.localeCompare(employeeB.name, 'pt-BR'));
      const areas = sortedEmployees.map((employee) => employee.departments.name);
      const uniqueAreas = new Set([...areas]);
      const sortedAreas = [...uniqueAreas].sort((areaA, areaB) => areaA.localeCompare(areaB, 'pt-BR'));
      setEmployeeList(sortedEmployees);
      setChildrenAreas(sortedAreas);

      setEmployeeEvaluated(
        employees.filter(
          (employee) =>
            !parseInt(employee.__meta__.leaderAnswers, 10) &&
            !parseInt(employee.__meta__.leaderJustificatives, 10) &&
            employee.evaluationCycleAnswers?.length,
        ).length,
      );

      setEmployeeEvaluatedHimself(
        employees.filter(
          (employee) =>
            !parseInt(employee.__meta__.employeeEvaluationAnswers, 10) &&
            !parseInt(employee.__meta__.employeeEvaluationJustificatives, 10) &&
            employee.evaluationCycleAnswers?.length,
        ).length,
      );

      setEmployeeHasFeedback(employees.filter((employee) => !parseInt(employee.__meta__.leaderFeedback, 10)).length);

      setEmployeeReceivedFeedback(
        employees.filter(
          (employee) =>
            employee.evaluationCycleComments.filter((comment) => comment.employee_receipt_confirmation_date !== null).length > 0,
        ).length,
      );
    }

    getData();
  }, []);

  const checkFinished = useCallback(
    (employee) => {
      switch (process) {
        case 'evaluation':
          return (
            !parseInt(employee.__meta__.leaderAnswers, 10) &&
            !parseInt(employee.__meta__.leaderJustificatives, 10) &&
            employee.evaluationCycleAnswers?.length > 0
          );
        case 'feedback':
          return parseInt(employee.__meta__.leaderFeedback, 10) === 0;
        default:
          return false;
      }
    },
    [process],
  );

  const checkAutoAvaliation = useCallback((employee) => {
    return (
      !parseInt(employee.__meta__.employeeEvaluationAnswers, 10) &&
      !parseInt(employee.__meta__.employeeEvaluationJustificatives, 10) &&
      employee.evaluationCycleAnswers?.length > 0
    );
  }, []);

  const checkConfirmed = useCallback((employee) => {
    return employee.evaluationCycleComments.filter((comments) => comments.employee_receipt_confirmation_date !== null).length > 0;
  }, []);

  return (
    <Container>
      <DescriptionContainer>
        <section>
          <h1>{process === 'evaluation' ? 'Avaliação dos liderados' : 'Feedback'}</h1>
          <span>Esses são os colaboradores aos quais você deve {process === 'evaluation' ? 'avaliar' : 'dar feedback'}.</span>
        </section>
        <section className='area-summary'>
          <span>
            Colaboradores:{' '}
            <b>
              <AnimatedNumber value={employeeList.length} formatValue={(value) => parseInt(value, 10)} />
            </b>
          </span>
          <span>
            <section>
              {process === 'evaluation' ? 'Já avaliados: ' : 'Feedbacks criados: '}
              <b>
                <AnimatedNumber
                  value={process === 'evaluation' ? employeeEvaluated : employeeHasFeedback}
                  formatValue={(value) => parseInt(value, 10)}
                />
              </b>{' '}
            </section>
            {process === 'evaluation' && (
              <section>
                Se autoavaliaram:{' '}
                <b>
                  <AnimatedNumber
                    value={process === 'evaluation' ? employeeEvaluatedHimself : 0}
                    formatValue={(value) => parseInt(value, 10)}
                  />
                </b>
              </section>
            )}
            {process === 'feedback' && (
              <section>
                Já receberam Feedback:{' '}
                <b>
                  <AnimatedNumber
                    value={process === 'feedback' ? employeeReceivedFeedback : 0}
                    formatValue={(value) => parseInt(value, 10)}
                  />
                </b>
              </section>
            )}
          </span>
        </section>
        <section className='legend'>
          <h4>Legenda</h4>
          {process === 'evaluation' ? (
            <>
              <div>
                <span>Avaliação do Gestor Realizada</span>
                <Square color='#ffcb08' />
              </div>
              <div>
                <span>Autoavaliação Realizada</span>
                <Square color='#411564' />
              </div>
              <div>
                <span>Gestor + Autoavaliação Realizada</span>
                <Square color='#00995d' />
              </div>
            </>
          ) : (
            <>
              <div>
                <span>Feedback do Gestor Realizado</span>
                <Square color='#00995d' />
              </div>
              <div>
                <span>Feedback do Gestor + Colaborador Confirmou</span>
                <Square color='#411564' />
              </div>
            </>
          )}
        </section>
      </DescriptionContainer>
      {childrenAreas.length > 0 ? (
        childrenAreas.map((area) => (
          <div key={area} className='area-description'>
            {childrenAreas.length > 1 ? <h3>{area}</h3> : <div />}
            <EmployeesContainer>
              <UpContainer>
                <div>
                  {employeeList.map((employee) => {
                    if (employee.departments.name === area) {
                      return (
                        <CardContainer
                          to={`${process === 'evaluation' ? routes.LEADER_EVALUATION : routes.MANAGER_FEEDBACK}/${employee.id}`}
                          key={employee.id}
                          finished={checkFinished(employee).toString()}
                          autoevaluated={checkAutoAvaliation(employee).toString()}
                          confirmed={checkConfirmed(employee).toString()}
                          process={process}
                          withoutform={`${!employee.evaluationCycleAnswers?.length > 0}`}>
                          <div className='employee-description'>
                            <div className='card-title'>
                              <h4>{employee.name}</h4>
                              <MdKeyboardArrowRight size={24} color='#333' />
                            </div>
                            <span>
                              <b>HIERARQUIA</b> - {employee.hierarchies.description.toUpperCase()}
                            </span>
                            <span>
                              <b>CARGO</b> - {employee.positions.description.toUpperCase()}
                            </span>
                            <span>
                              <b>TRAJETÓRIA</b> - {employee.positions.paths.description.toUpperCase()}
                            </span>
                            <span>
                              <b>COLABORADOR(A)</b>{' '}
                              {formatDistance(parseISO(employee.admitted_at), new Date(), {
                                addSuffix: true,
                                locale: pt,
                              }).toUpperCase()}
                            </span>
                          </div>
                        </CardContainer>
                      );
                    }
                    return null;
                  })}
                </div>
              </UpContainer>
            </EmployeesContainer>
          </div>
        ))
      ) : (
        <EmployeesContainer>
          <UpContainer>
            <div>
              <LoadingCard />
              <LoadingCard />
              <LoadingCard />
            </div>
          </UpContainer>
        </EmployeesContainer>
      )}
    </Container>
  );
}

export default memo(AreaEmployees);
