export const headers = [
  {
    title: 'Name',
    width: '15%',
    align: 'left',
    columnName: 'name',
    dataType: String,
  },
  {
    title: 'Descrição',
    width: '70%',
    align: 'left',
    columnName: 'description',
    dataType: String,
  },
  {
    title: 'Nota',
    width: '5%',
    align: 'left',
    columnName: 'score',
    dataType: String,
  },
];
