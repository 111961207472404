import React from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';

function MainContainer({ children }) {
  return <Container>{children}</Container>;
}

MainContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default MainContainer;
