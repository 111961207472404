import React, { memo, useEffect, useState, useRef, useCallback } from 'react';
import { GiStopwatch, GiCheckeredFlag, GiCancel, GiCalendar } from 'react-icons/gi';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from '@unform/web';
import { debounce } from 'lodash';

import { CustomRadio, DoughnutChart, Select } from '~/components';
import { LoadingCard } from '~/components/Shimmer';
import { mountOptionList } from '~/functions';
import { useToggle } from '~/hooks';
import api from '~/services/api';
import { updateEvaluationCycleInUse } from '~/store/modules/cycle/actions';
import { updateBreadcrumb } from '~/store/modules/user/actions';

import { Container, DescriptionContainer, EmployeesContainer, UpContainer, CardContainer, ResumeContainer } from './styles';

function DepartmentPdi() {
  const dispatch = useDispatch();
  const process = useSelector((state) => state.user.process);
  const [employeeList, setEmployeeList] = useState([]);
  const [allEmployeeList, setAllEmployeeList] = useState([]);
  const [childrenAreas, setChildrenAreas] = useState([]);
  const [allAreas, setAllAreas] = useState([]);
  const [cycleList, setCycleList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [options, setOptions] = useState([]);
  const [employeeWithPlan, setEmployeeWithPlan] = useState(0);
  const [created, setCreated] = useState(0);
  const [finished, setFinished] = useState(0);
  const [canceled, setCanceled] = useState(0);
  const [overdue, setOverdue] = useState(0);
  const [refreshData, toggleRefreshData] = useToggle();
  const cycle = useSelector((state) => state.cycle);
  const formRef = useRef(null);

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { name: 'Início', path: '/' },
        { name: 'Configurações', path: '/sys-config' },
        { name: 'Ciclo de Avaliação', path: '/evaluation-cycle' },
        { name: 'Acompanhe os Processos deste Ciclo de Avaliação', path: '/follow' },
        { name: 'Lista de PDI de todos os Setores', path: '/department-pdi' },
      ]),
    );
    mountOptionList('evaluationcycles', setCycleList);

    mountOptionList('departments', setAreaList);
  }, [dispatch, process]);

  useEffect(() => {
    if (cycle.id && cycleList.length > 0 && formRef.current) {
      formRef.current.setData({ cycle_id: cycle.id });
    }
  }, [cycle.id, cycleList.length]);

  useEffect(() => {
    async function getData() {
      if (!cycle.id) return;
      setChildrenAreas([]);
      const { data: employees } = await api.get(`pdireport/${cycle.id}`);
      const sortedEmployees = employees.sort((employeeA, employeeB) => employeeA.name.localeCompare(employeeB.name, 'pt-BR'));
      const areas = sortedEmployees.map((employee) => employee.departments.name);
      const uniqueAreas = new Set([...areas]);
      const sortedAreas = [...uniqueAreas].sort((areaA, areaB) => areaA.localeCompare(areaB, 'pt-BR'));
      setEmployeeList(sortedEmployees);
      setAllEmployeeList(sortedEmployees);

      setChildrenAreas(sortedAreas);
      setAllAreas(sortedAreas);

      setOptions([
        { id: 0, value: 0, title: 'Aguardando', svg: <GiStopwatch size={20} /> },
        { id: 1, value: 1, title: 'Finalizado', svg: <GiCheckeredFlag size={20} /> },
        { id: 2, value: 2, title: 'Cancelado', svg: <GiCancel size={20} /> },
        { id: 3, value: 3, title: 'Expirado', svg: <GiCalendar size={20} /> },
      ]);
    }

    getData();
  }, [cycle.id, refreshData]);

  useEffect(() => {
    setEmployeeWithPlan(employeeList.filter((employee) => employee.evaluationCycleDevelopmentPlans.length > 0).length);
    setCreated(employeeList.map((employee) => employee.evaluationCycleDevelopmentPlans.length).reduce((a, b) => a + b, 0));
    setFinished(
      employeeList
        .map((employee) => employee.evaluationCycleDevelopmentPlans.filter((plan) => Number(plan.status) === 1).length)
        .reduce((a, b) => a + b, 0),
    );
    setCanceled(
      employeeList
        .map((employee) => employee.evaluationCycleDevelopmentPlans.filter((plan) => Number(plan.status) === 2).length)
        .reduce((a, b) => a + b, 0),
    );
    setOverdue(
      employeeList
        .map(
          (employee) =>
            employee.evaluationCycleDevelopmentPlans.filter(
              (plan) => Number(plan.status) !== 1 && Number(plan.status) !== 2 && new Date(plan.final_date) <= new Date(),
            ).length,
        )
        .reduce((a, b) => a + b, 0),
    );
  }, [employeeList]);

  const submitHandle = async (developmentPlanId, value) => {
    // try {
    //   const { data } = await api.put(`evaluationcycledevelopmentplans/${developmentPlanId}`, {
    //     data: [{ id: developmentPlanId, status: Number(value) }],
    //   });
    //   if (data.status === 'ok') {
    //     toggleRefreshData();
    //     toast.success(`Status atualizado com sucesso!`);
    //   } else {
    //     toast.error(`Erro ao salvar dados! ${data.message}`);
    //   }
    // } catch (err) {
    //   toast.error(`Erro ao salvar dados! ${err.message}`);
    // }
  };

  const checkIfPlanIsOverdue = (status, finalDate) => {
    return new Date(finalDate) < new Date() && Number(status) !== 1 && Number(status) !== 2 ? '3' : Number(status).toString();
  };

  const handleChangeSubmit = useCallback( // eslint-disable-line
    debounce(
      () => {
        formRef.current.submitForm();
      },
      10,
      { trailing: true },
    ),
    [],
  );

  const submitForm = ({ cycle_id }) => {
    dispatch(updateEvaluationCycleInUse({ id: cycle_id }));
  };

  const handleFilterAreaSubmit = (data) => {
    if (data) {
      setChildrenAreas(allAreas.filter((area) => area === data.label));
      setEmployeeList(allEmployeeList.filter((employee) => employee.departments.name === data.label));
    } else {
      setChildrenAreas(allAreas);
      setEmployeeList(allEmployeeList);
    }
  };

  return (
    <Container>
      <>
        <DescriptionContainer>
          <section>
            <h1>PDI - Planos Separados por Setores</h1>
            <span>Aqui temos a lista de todos os PDI's criados para este ciclo de avaliação</span>
          </section>
        </DescriptionContainer>
        <Form onSubmit={submitForm} onChange={handleChangeSubmit} className='form' ref={formRef}>
          <Select
            name='cycle_id'
            placeholder='Escolha o ciclo'
            label='Escolha o Ciclo'
            options={cycleList}
            isMulti={false}
            closeMenuOnSelect
            onChange={handleChangeSubmit}
          />
          <Select
            name='area_id'
            placeholder='Escolha o setor'
            label='Escolha o setor'
            options={areaList}
            isMulti={false}
            closeMenuOnSelect
            onChange={handleFilterAreaSubmit}
          />
        </Form>
      </>
      {childrenAreas.length > 0 && (
        <>
          <ResumeContainer>
            <section>
              <h6>Criados</h6>
              <DoughnutChart
                total={employeeList.length}
                value={employeeWithPlan}
                width='142px'
                color='#00995d'
                strokeColor='#5c5c65'
              />
            </section>
            <section>
              <h6>Finalizados</h6>
              <DoughnutChart total={created} value={finished} width='142px' color='#f47920' strokeColor='#5c5c65' />
            </section>
            <section>
              <h6>Cancelados</h6>
              <DoughnutChart total={created} value={canceled} width='142px' color='#ed1651' strokeColor='#5c5c65' />
            </section>
            <section>
              <h6>Expirados</h6>
              <DoughnutChart total={created} value={overdue} width='142px' color='#a3238e' strokeColor='#5c5c65' />
            </section>
          </ResumeContainer>
        </>
      )}
      {childrenAreas.length > 0 ? (
        childrenAreas.map((area) => (
          <div key={area} className='area-description'>
            {childrenAreas.length > 1 ? <h4>{area}</h4> : <div />}
            <EmployeesContainer>
              <UpContainer>
                <div>
                  {employeeList.map((employee) => {
                    if (employee.departments.name === area) {
                      return (
                        <CardContainer key={employee.id} process={process}>
                          <div className='employee-description'>
                            <div className='card-title'>
                              <h4>{employee.name}</h4>
                              <div>
                                {employee.evaluationCycleDevelopmentPlans.filter((plan) => Number(plan.status) === 1).length}/
                                {employee.evaluationCycleDevelopmentPlans.length}
                              </div>
                            </div>
                            <div className='employee-data'>
                              {employee.evaluationCycleDevelopmentPlans.length > 0 ? (
                                employee.evaluationCycleDevelopmentPlans.map((plan) => (
                                  <Form
                                    onSubmit={() => null}
                                    key={plan.id}
                                    initialData={{
                                      [plan.id]: [checkIfPlanIsOverdue(plan.status, plan.final_date)],
                                    }}>
                                    <div>
                                      <div className='plan-description'>
                                        <h4>
                                          Ação: {plan.developmentPlans?.action}
                                          {plan.developmentPlans?.action === 'Outros' && ` ( ${plan.others} )`}
                                        </h4>
                                        <small>
                                          Início: {new Date(plan.initial_date).toLocaleDateString('pt-BR')} | Fim:
                                          {new Date(plan.final_date).toLocaleDateString('pt-BR')}
                                        </small>
                                      </div>
                                      <small>{plan.action}</small>
                                    </div>
                                    <CustomRadio
                                      name={plan.id}
                                      options={options}
                                      onChange={(e) => submitHandle(plan.id, e.target.value)}
                                    />
                                  </Form>
                                ))
                              ) : (
                                <span className='without-pdi'>Não possui PDI cadastrado</span>
                              )}
                            </div>
                          </div>
                        </CardContainer>
                      );
                    }
                    return null;
                  })}
                </div>
              </UpContainer>
            </EmployeesContainer>
          </div>
        ))
      ) : (
        <>
          <EmployeesContainer>
            <UpContainer>
              <div>
                <LoadingCard />
                <LoadingCard />
                <LoadingCard />
              </div>
            </UpContainer>
          </EmployeesContainer>
        </>
      )}
    </Container>
  );
}

export default memo(DepartmentPdi);
