import styled from 'styled-components';

export const Container = styled.aside`
  position: relative;
  width: 350px;
  margin-left: 30px;
  background: rgb(25, 25, 28);
  border-radius: 5px;
  overflow: hidden;
  display: initial;
`;

export const ClassMenuTopContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
`;

export const ClassMenuContainer = styled.div`
  flex: 1 1 0%;
  overflow-y: auto;
  padding-top: 0px;
`;

export const MenuHeaderContainer = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  background: rgb(41, 41, 46);
`;

export const ProgressCircle = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  background: rgb(0, 0, 0);
  border-radius: 50%;
  flex-shrink: 0;

  svg.circle {
    position: absolute;
    top: 0px;
    left: 0px;
  }

  svg.stroke {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -150%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease 0s;
    color: #00995d;
  }

  span {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0px;
    left: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    z-index: 2;
    font-size: 14px;
    font-weight: bold;
    color: rgb(225, 225, 230);
  }
`;

export const TitleContainer = styled.div`
  margin: 0 20px;
  color: #fff;

  span {
    &:first-of-type {
      display: block;
      font-size: 16px;
      font-weight: bold;
      color: rgb(225, 225, 230);
    }
  }
`;

export const MenuItemsContainer = styled.div`
  height: auto;
  overflow: initial;

  ul {
    margin: 30px;

    li {
      font-size: 14px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      position: relative;
      z-index: 5;
      margin-top: 20px;

      &:not(:first-of-type) {
        &:before {
          top: auto;
          bottom: 50%;
        }
      }

      &:last-of-type {
        &:before {
          top: auto;
          bottom: 50%;
        }

        &:after {
          height: 0;
        }
      }

      button {
        position: relative;
        cursor: pointer;
        width: 10px;
        height: 10px;
        border: 0px;
        border-radius: 50%;
        margin-right: 30px;
        flex-shrink: 0;
        z-index: 2;
        transition: box-shadow 0.2s ease 0s;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 20px;
          height: 20px;
          transform: translate(-50%, -50%);
        }

        &:after {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          z-index: 2;
          border-radius: 50%;
        }
      }

      button.completed {
        background: #00995d;

        &:hover {
          &:before {
            background: rgb(32, 32, 36);
            border: 2px solid rgb(60, 60, 60);
            border-radius: 50%;
          }

          &:after {
            background: #00995d;
          }
        }
      }

      button.active {
        background: rgb(225, 225, 230);

        &:before {
          background: rgb(32, 32, 36);
          border: 2px solid #00995d;
          border-radius: 50%;
        }

        &:after {
          background: rgb(225, 225, 230);
        }
      }

      button.inactive {
        background: rgb(60, 60, 66);

        &:hover {
          &:before {
            background: rgb(32, 32, 36);
            border: 2px solid rgb(60, 60, 60);
            border-radius: 50%;
          }

          &:after {
            background: rgb(60, 60, 66);
          }
        }
      }

      div {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        width: 100%;

        a {
          color: rgb(168, 168, 179);
          font-weight: 300;

          &:hover {
            color: #fff;
          }
        }

        a.active {
          color: rgb(225, 225, 230);
          font-weight: bold; /*quando estiver ativo fica como bold*/
        }
      }

      &:before,
      &:after {
        content: '';
        left: 4px;
        width: 2px;
        top: 50%;
        height: calc(100% + 5px);
        background: rgb(60, 60, 66);
        position: absolute;
      }
    }
  }
`;
