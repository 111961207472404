import styled from 'styled-components';

export const FormContainer = styled.div`
  height: ${(props) => (props.showForm ? '120px' : '0')};
  opacity: ${(props) => (props.showForm ? '1' : '0')};
  transition: height 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 2s ease;

  form {
    margin-top: 10px;
    display: ${(props) => (props.showForm ? 'flex' : 'none')};
    flex-direction: column;

    div.text-to-left {
      align-items: flex-start;
      justify-content: flex-start;
      align-content: flex-start;
      display: flex;
    }

    > div {
      margin-bottom: 10px;
    }

    .hidden-input {
      display: none;
    }
    .hidden-input:target {
      display: block;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-left: 10px;
  }
`;

export const ChangeContainerMode = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  > div:last-child {
    margin-bottom: 30px;
  }
`;

export const BehaviorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 8px 15px 5px 15px;
  border-radius: 8px;

  section.skill-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    section {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      div.header-path {
        margin-right: 15px;

        small {
          color: #666;
          font-weight: 300;
        }
      }

      button {
        max-width: 200px;
      }
    }

    h3 {
      color: #333;
      margin-right: 15px;
    }

    svg {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  div.skill-container {
    display: flex;
    flex-direction: column;
    border-top: 1px dotted #333;
    padding-top: 10px;
    transition: all 0.5s ease;

    section {
      margin-bottom: 10px;
    }

    section:first-child {
      display: flex;
      flex-direction: row;
      align-items: start !important;
      height: 100%;
      align-content: center;

      div:first-child {
        display: flex;
        flex-direction: column;
        align-items: start !important;
        height: 100%;
        align-content: center;
        margin-right: 15px;

        h5 {
          color: #411564;
          font-size: 1.5rem;
        }

        small {
          color: #666;
          font-weight: 300;
        }
      }
    }

    div.skill-description-container {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: start;
      border-bottom: 1px dotted #ddd;
      padding: 5px 0 4px 0;

      form {
        width: 100%;
      }

      div {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      svg {
        margin-right: 15px;
        cursor: pointer;
        min-width: 24px;

        &:hover {
          opacity: 0.8;
        }
      }

      p {
        text-align: justify;
        color: #333;
        font-weight: 300;
        text-transform: lowercase;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
`;
