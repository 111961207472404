import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;

  p {
    margin-bottom: 10px;
  }

  section.pdi-container {
    margin: 10px 0;

    header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 5px 5px 5px 10px;
      background: rgba(196, 203, 207, 0.2);
      border-radius: 8px;

      h4 {
        width: 100%;
      }

      button {
        width: 25%;
      }
    }
  }

  .delete-confirm-button {
    padding: 10px 25px;
    border-radius: 5px;
    box-shadow: none !important;
    background: #00995d;
    color: #fff;

    &:hover {
      opacity: 0.8;
    }
  }

  .delete-cancel-button {
    color: #ed1651;
    border-bottom: 1px dotted #ed1651;
    margin-right: 25px !important;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const Details = styled.details`
  margin-top: 10px;
  border-radius: 8px;
  background: #eee;
  padding: 10px;

  summary {
    cursor: pointer;
    display: flex;
    align-items: center;

    &:before {
      content: '⇲ ';
      margin-right: 5px;
    }

    &::marker {
      display: none;
      content: '';
    }

    button {
      border: none;
      margin-left: 10px;
      color: #ed1651;
      border-bottom: 1px dotted #888;
      font-size: 1.4rem;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &[open] {
    summary {
      margin-bottom: 0.6rem;
      border-bottom: 1px dashed #333;
      padding-bottom: 7px;

      &:before {
        content: '⇱ ';
      }
    }
  }

  .date-picker-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    gap: 10px;

    > div {
      width: 100%;
    }
  }
`;
