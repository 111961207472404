import React from 'react';

import { MainContainer, MenuClassContainer, VideoPlayer } from '~/components';

import { Container, ClassContainer, DescriptionContainer, VideoContainer } from './styles';

function VideoTrainings() {
  const course = [
    {
      name: 'Cooperativismo',
      id: '123456-123456-123456-123456',
      lessons: [
        { name: 'Aula 1 - Teste de video', cssClass: 'completed', url: 'http://avaliacaodesempenho.unimedvarginha.coop.br' },
        { name: 'Aula 2 - Teste de video', cssClass: 'active', url: 'http://avaliacaodesempenho.unimedvarginha.coop.br' },
        { name: 'Aula 3 - Teste de video', cssClass: 'inactive', url: 'http://avaliacaodesempenho.unimedvarginha.coop.br' },
        { name: 'Aula 4 - Teste de video', cssClass: 'inactive', url: 'http://avaliacaodesempenho.unimedvarginha.coop.br' },
      ],
    },
    {
      name: 'Código de Conduta',
      id: '123456-123456-123456-123456',
      lessons: [
        { name: 'Aula 1 - Teste de video', cssClass: 'completed', url: 'http://avaliacaodesempenho.unimedvarginha.coop.br' },
        { name: 'Aula 2 - Teste de video', cssClass: 'active', url: 'http://avaliacaodesempenho.unimedvarginha.coop.br' },
        { name: 'Aula 3 - Teste de video', cssClass: 'inactive', url: 'http://avaliacaodesempenho.unimedvarginha.coop.br' },
      ],
    },
  ];

  return (
    <MainContainer>
      <Container>
        <ClassContainer>
          <VideoContainer>
            <VideoPlayer />
          </VideoContainer>
          <MenuClassContainer course={course} />
        </ClassContainer>
        <DescriptionContainer>Description</DescriptionContainer>
      </Container>
    </MainContainer>
  );
}

export default VideoTrainings;
