import React, { memo, useEffect, useRef } from 'react';
import CheckboxTree from 'react-checkbox-tree';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  fas,
  faCheckSquare,
  faSquare,
  faChevronRight,
  faChevronDown,
  faPlusSquare,
  faMinusSquare,
  faFolder,
  faFolderOpen,
  faFile,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { Container } from './styles';

function CheckboxTrees({ nodes, name, expandedItems, checkedItems, setChecked, setExpanded, ...rest }) {
  const checkRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: checkRef.current,
    });
  }, [fieldName, registerField]);

  library.add(
    fas,
    faCheckSquare,
    faSquare,
    faChevronRight,
    faChevronDown,
    faPlusSquare,
    faMinusSquare,
    faFolder,
    faFolderOpen,
    faFile,
  );

  useEffect(() => {
    setExpanded(expandedItems);
    setChecked(checkedItems);
  }, [checkedItems, expandedItems, setChecked, setExpanded]);

  return (
    <Container>
      <CheckboxTree
        ref={checkRef}
        defaultValue={defaultValue}
        nodes={nodes}
        checked={checkedItems}
        expanded={expandedItems}
        onCheck={(check) => setChecked(check)}
        onExpand={(expand) => setExpanded(expand)}
        showExpandAll
        checkModel='leaf'
        {...rest}
        icons={{
          check: <FontAwesomeIcon className='rct-icon rct-icon-check' icon='check-square' />,
          uncheck: <FontAwesomeIcon className='rct-icon rct-icon-uncheck' icon={['fas', 'square']} />,
          halfCheck: <FontAwesomeIcon className='rct-icon rct-icon-half-check' icon='check-square' />,
          expandClose: <FontAwesomeIcon className='rct-icon rct-icon-expand-close' icon='chevron-right' />,
          expandOpen: <FontAwesomeIcon className='rct-icon rct-icon-expand-open' icon='chevron-down' />,
          expandAll: <FontAwesomeIcon className='rct-icon rct-icon-expand-all' icon='plus-square' />,
          collapseAll: <FontAwesomeIcon className='rct-icon rct-icon-collapse-all' icon='minus-square' />,
          parentClose: <FontAwesomeIcon className='rct-icon rct-icon-parent-close' icon='folder' />,
          parentOpen: <FontAwesomeIcon className='rct-icon rct-icon-parent-open' icon='folder-open' />,
          leaf: <FontAwesomeIcon className='rct-icon rct-icon-leaf-close' icon='file' />,
        }}
      />
      {error && <span className='input-error'>{error}</span>}
    </Container>
  );
}

CheckboxTrees.propTypes = {
  nodes: PropTypes.instanceOf(Object).isRequired,
  name: PropTypes.string.isRequired,
  expandedItems: PropTypes.instanceOf(Array).isRequired,
  checkedItems: PropTypes.instanceOf(Array).isRequired,
  setChecked: PropTypes.func.isRequired,
  setExpanded: PropTypes.func.isRequired,
};

export default memo(CheckboxTrees);
