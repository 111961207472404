import React, { memo, useEffect, useState, useRef, useCallback } from 'react';
import { Radar } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';

import { groupBy } from 'lodash';

import logo from '~/assets/logo_unimed.png';
import { CalibrationDialog } from '~/components';
import { LoadingCard } from '~/components/Shimmer';
import api from '~/services/api';
import { updateBreadcrumb } from '~/store/modules/user/actions';

import { Container, DescriptionContainer, ReportContainer, ChartContainer, DetailsContainer, UpContainer } from './styles';

function SummaryEmployees() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const cycle = useSelector((state) => state.cycle);

  const [storage, setStorage] = useState({});
  const [employeeData, setEmployeeData] = useState(null);
  const [employeeAnswers, setEmployeeAnswers] = useState(null);
  const [employeeAnswersRoot, setEmployeeAnswersRoot] = useState([]);
  const [employeeResultSet, setEmployeeResultSet] = useState([]);
  const [leaderResultSet, setLeaderResultSet] = useState([]);
  const [total, setTotal] = useState({});
  const [classification, setClassification] = useState([]);
  const [entityData, setEntityData] = useState({});
  const [showConciliationForm, setShowConciliationForm] = useState(false);
  const printRef = useRef();
  const precisaCalibrar = [3, -3]; // Se este for o resultado do produto estiver aqui calibrar
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const dataReturned = JSON.parse(localStorage.getItem(`@crescer-unimed/${user.id}`));
    setStorage(dataReturned);

    if (cycle.options.singleView) {
      dispatch(
        updateBreadcrumb([
          { name: 'Início', path: '/' },
          { name: 'Avaliação do Colaborador', path: `/summary-evaluation-employee` },
        ]),
      );
    } else {
      dispatch(
        updateBreadcrumb([
          { name: 'Início', path: '/' },
          { name: 'Resumo das Avaliações', path: `/summary-evaluations` },
          { name: 'Lista de Colaboradores', path: `/summary-evaluation-area` },
          { name: 'Avaliação do Colaborador', path: `/summary-evaluation-employee` },
        ]),
      );
    }
  }, [dispatch, user.id]);

  useEffect(() => {
    async function getData() {
      const { data } = await api.get(`summaryemployees/${cycle.id}/${cycle.options.employeeId}`);
      setEmployeeData(data);

      const {
        data: { data: classificationData },
      } = await api.get('classifications');
      setClassification(classificationData);

      if (data) {
        const createEmployeeAnswers = data.evaluationCycleAnswers?.map((answer) => {
          return {
            behavior: answer.behaviors.description,
            evaluationAnswerId: answer.id,
            skill: answer.behaviors.skills.name,
            employee: answer.user_answer,
            leader: answer.leader_answer,
            conciliation: answer.reconciliation_score,
          };
        });

        const groupBySkill = groupBy(createEmployeeAnswers, 'skill');
        const labels = [];
        const groupToArray = Object.entries(groupBySkill).map((item) => {
          labels.push(item[0]);
          return { skill: item[0], behaviors: item[1] };
        });

        setEmployeeAnswersRoot(createEmployeeAnswers);
        setEmployeeAnswers(groupToArray);

        const sumResult = [];
        createEmployeeAnswers.reduce((res, value) => {
          if (!res[value.skill]) {
            res[value.skill] = { skill: value.skill, employee: 0, leader: 0, conciliation: 0, qty: 0 };
            sumResult.push(res[value.skill]);
          }
          res[value.skill].employee += Number(value.employee);
          res[value.skill].leader += Number(value.leader);
          res[value.skill].conciliation += Number(value.conciliation);
          res[value.skill].qty += 1;

          return res;
        }, {});

        const employeeResult = {};
        sumResult.forEach((item) => {
          employeeResult[item.skill] = item.employee / item.qty;
        });

        const leaderResult = {};
        sumResult.forEach((item) => {
          leaderResult[item.skill] = item.leader / item.qty;
        });

        setEmployeeResultSet(employeeResult);
        setLeaderResultSet(leaderResult);
        const employeeArrayResult = Object.entries(employeeResult).map((item) => item[1]);
        const leaderArrayResult = Object.entries(leaderResult).map((item) => item[1]);

        setTotal({
          colaborador: (
            Math.floor((employeeArrayResult.reduce((a, b) => a + b, 0) * 100) / 100) / employeeArrayResult.length
          ).toFixed(2),
          lider: (Math.floor((leaderArrayResult.reduce((a, b) => a + b, 0) * 100) / 100) / leaderArrayResult.length).toFixed(2),
        });

        setChartData({
          labels,
          datasets: [
            {
              label: 'Colaborador',
              data: employeeArrayResult,
              borderColor: '#0a5f55',
              backgroundColor: 'rgba(0, 153, 93, 0.2)',
              fill: true,
            },
            {
              label: 'Líder',
              data: leaderArrayResult,
              borderColor: '#f47920',
              backgroundColor: 'rgba(255,203,8,0.2)',
              fill: true,
            },
          ],
        });

        setChartOptions({
          scales: {
            r: {
              suggestedMin: 0,
              suggestedMax: 3,
            },
          },
        });
      }
    }

    getData();
  }, [cycle.id, cycle.options.employeeId, storage]);

  const handleConciliationFormOpen = (evaluationCyclAnswerId) => {
    setEntityData(
      employeeAnswersRoot
        .filter((answer) => answer.evaluationAnswerId === evaluationCyclAnswerId)
        .map((answer) => ({
          id: answer.evaluationAnswerId,
          behavior: answer.behavior,
          leader: answer.leader,
        }))[0],
    );
    setShowConciliationForm(true);
  };

  const handleConciliation = useCallback(
    async (id, value) => {
      if (value) {
        try {
          const evaluationCycleAnswer = [
            { id, leader_answer: value, reconciliation_score: entityData.leader, conciliator_id: user.id },
          ];
          const response = await api.put(`evaluationcycleanswers/${id}`, { data: evaluationCycleAnswer });
          setShowConciliationForm(false);
          toast.success(`${response.statusText}: Calibração realizada com sucesso`);
        } catch (err) {
          toast.error(`${err.response.data.message}: Erro ao salvar calibração.`);
        }
      }
    },
    [entityData.leader, user.id],
  );

  return (
    <>
      <Container className='printElement' ref={printRef}>
        {employeeData && classification && total ? (
          <>
            <DescriptionContainer>
              <div className='top-header'>
                <img className='logo-header' src={logo} alt='Crescer Unimed' />
                <section>
                  <h3>{employeeData.name}</h3>
                  <span className='employee-position'>{employeeData.positions?.description}</span>
                </section>
              </div>
              <div className='area-summary' />
              <button type='button' onClick={handlePrint}>
                Imprimir
              </button>
            </DescriptionContainer>
            <ReportContainer>
              <h1>Avaliação de Desempenho</h1>
              <h4>Correlação das avaliações</h4>
              <ChartContainer>
                <Radar data={chartData} options={chartOptions} />
              </ChartContainer>
              <DetailsContainer>
                <h1>Comportamentos</h1>
                {employeeAnswers &&
                  employeeAnswers.map((answer) => (
                    <div className='row' key={answer.skill}>
                      <span>
                        <h3>{answer.skill}</h3>
                        {answer.behaviors.map((behavior) => (
                          <section key={behavior.behavior} className='each-behavior'>
                            <p>
                              {behavior.behavior.slice(0, 1).toUpperCase() +
                                behavior.behavior.slice(1, behavior.behavior.length).toLowerCase()}
                            </p>
                            <section className='score-container'>
                              <section className='scores'>
                                <h4>Colaborador</h4>
                                <p>{Number(behavior.employee).toFixed(2)}</p>
                              </section>
                              <section className='scores'>
                                <h4>Líder</h4>
                                <p>{Number(behavior.leader).toFixed(2)}</p>
                              </section>
                              {precisaCalibrar.includes(
                                Number(behavior.leader).toFixed(2) - Number(behavior.employee).toFixed(2),
                              ) ? (
                                <button type='button' onClick={() => handleConciliationFormOpen(behavior.evaluationAnswerId)}>
                                  CALIBRAR
                                </button>
                              ) : (
                                <div />
                              )}
                            </section>
                          </section>
                        ))}
                        <section className='total-container'>
                          <section>Autoavaliação: {Math.floor((employeeResultSet[answer.skill] * 100) / 100).toFixed(2)}</section>
                          <section>Líder: {Math.floor((leaderResultSet[answer.skill] * 100) / 100).toFixed(2)}</section>
                        </section>
                      </span>
                    </div>
                  ))}
              </DetailsContainer>
              <DetailsContainer>
                <div className='row'>
                  <span>
                    <h3 className='evaluation-total'>Total das Avaliações</h3>
                    <section className='total-container'>
                      <section>Autoavaliação: {total.colaborador}</section>
                      <section>Líder: {total.lider}</section>
                    </section>
                    <section>
                      <h3 className='evaluation-main-total'>Total Geral</h3>
                      <section className='total-container main'>
                        <section>
                          {/* Pontuação Total: {((Number(total.Colaborador) + Number(total['Líder'])) / 2).toFixed(2)} */}
                          Pontuação Total: {((Number(total.colaborador) + Number(total.lider)) / 2).toFixed(2)}
                        </section>
                        <section>
                          Classificação Geral:{' '}
                          <b>
                            {
                              classification.find(
                                (item) =>
                                  ((Number(total.colaborador) + Number(total.lider)) / 2).toFixed(2) >= item.initial_value &&
                                  ((Number(total.colaborador) + Number(total.lider)) / 2).toFixed(2) <= item.final_value,
                              )?.description
                            }
                          </b>
                        </section>
                      </section>
                    </section>
                  </span>
                </div>
              </DetailsContainer>
              <DetailsContainer>
                <h1>Justificativas por competência</h1>
                {employeeData.evaluationCycleJustificatives.map((justificative) => (
                  <div className='row' key={justificative.id}>
                    <span>
                      <h3>{justificative.skills.name}</h3>
                      <p className='justificative-description'>{justificative.skills.description}</p>
                      <p className='employee-justificative'>
                        <b>Colaborador: </b>
                        {justificative.user_justificative}
                      </p>
                      <p>
                        <b>Líder: </b>
                        {justificative.leader_justificative}
                      </p>
                    </span>
                  </div>
                ))}
              </DetailsContainer>
              <DetailsContainer>
                <h1>Feedbacks</h1>
                {employeeData.evaluationCycleComments.map((comment) => (
                  <div className='row' key={comment.id}>
                    <span>
                      <h3>{comment.comments.description}</h3>
                      <p className='feedback-container'>{comment.leader_comment}</p>
                    </span>
                  </div>
                ))}
              </DetailsContainer>
            </ReportContainer>
          </>
        ) : (
          <ReportContainer>
            <UpContainer>
              <div>
                <LoadingCard />
              </div>
            </UpContainer>
          </ReportContainer>
        )}
      </Container>
      <CalibrationDialog
        open={showConciliationForm}
        setOpen={setShowConciliationForm}
        confirmAction={handleConciliation}
        title='Insira a pontuação de conciliação: '
        entityData={entityData}
        actionName='Calibrar'
      />
    </>
  );
}

export default memo(SummaryEmployees);
