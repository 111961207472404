import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import { MainContainer } from '~/components';
import * as routes from '~/routes/config';

function EmployeeTrainings() {
  return (
    <MainContainer>
      <Link to={routes.VIDEO_TRAINING}>Video</Link>
    </MainContainer>
  );
}

export default memo(EmployeeTrainings);
