import React, { useEffect, memo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { formatDistance, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { PropTypes } from 'prop-types';

import { MainContainer } from '~/components';
import { isObjectEmpty, formatDate } from '~/functions';
import api from '~/services/api';
import { updateBreadcrumb } from '~/store/modules/user/actions';

import { Section, Article, EmployeeSummaryContainer, WithoutContinuousFeedbackContainer } from './styles';

function Timeline({
  match: {
    params: { employee_id },
  },
}) {
  const dispatch = useDispatch();
  const [employeeData, setEmployeeData] = useState({});
  const [employeeContinuousFeedbacks, setEmployeeContinuousFeedback] = useState([]);

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { name: 'Início', path: '/' },
        { name: `Linha do Tempo de Seus Feedbacks`, path: '/timeline' },
      ]),
    );
  }, [dispatch]);

  useEffect(() => {
    async function getData() {
      const { data } = await api.get(`continuousfeedbackemployeelist/${employee_id}`);
      const { continuousFeedbacks, ...employeeInfo } = data;
      setEmployeeData(employeeInfo);
      setEmployeeContinuousFeedback(continuousFeedbacks);
    }

    if (employee_id) {
      getData();
    }
  }, [employee_id]);

  return (
    <MainContainer>
      {!isObjectEmpty(employeeData) && (
        <EmployeeSummaryContainer>
          <div className='development-plans-container'>
            <h3>Um breve resumo de sua história no Grupo Empresarial Unimed Varginha.</h3>
            <section>
              <div>
                <p>
                  Você faz parte do nosso quadro de colaboradores{' '}
                  {formatDistance(parseISO(employeeData.admitted_at), new Date(), {
                    addSuffix: true,
                    locale: pt,
                  })}
                  , atualmente está trabalhando no setor de {employeeData.departments?.name.toLowerCase()}, ocupando o cargo de{' '}
                  {employeeData.positions?.description.toLowerCase()}.
                </p>
              </div>
            </section>
          </div>
        </EmployeeSummaryContainer>
      )}
      <Section id='timeline' show={employeeContinuousFeedbacks.length !== 0}>
        {employeeContinuousFeedbacks.map((continuousFeedback) => (
          <Article headerColor={continuousFeedback.category} key={continuousFeedback.id}>
            <div className='inner'>
              <span className='date'>
                <span className='day'>
                  {new Date(continuousFeedback.created_at).getDate()}
                  {/* <sup>th</sup> */}
                </span>
                <span className='month'>
                  {new Date(continuousFeedback.created_at).toLocaleString('pt-br', { month: 'short' }).replace('.', '')}
                </span>
                <span className='year'>{new Date(continuousFeedback.created_at).getFullYear()}</span>
              </span>
              <section className='header-container'>
                <h5>{continuousFeedback.category === 1 ? 'Feedback Positivo' : 'Feedback de Desenvolvimento'}</h5>
              </section>
              <p>
                <small>Descrição</small>
                {continuousFeedback.description}
              </p>
              {continuousFeedback.continuousFeedbackDevelopmentPlans &&
                continuousFeedback.continuousFeedbackDevelopmentPlans.map((actionPlan) => (
                  <p key={actionPlan.id}>
                    <small>Plano de Ação</small>
                    {actionPlan.action}
                    <small>
                      {formatDate(actionPlan.initial_date)} a {formatDate(actionPlan.final_date)}
                    </small>
                  </p>
                ))}
            </div>
          </Article>
        ))}
      </Section>
      {employeeContinuousFeedbacks.length === 0 && (
        <WithoutContinuousFeedbackContainer>
          {employeeContinuousFeedbacks && <div>Você ainda não recebeu feedbacks de seu líder.</div>}
        </WithoutContinuousFeedbackContainer>
      )}
    </MainContainer>
  );
}

Timeline.propTypes = {
  match: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
};

Timeline.defaultProps = {
  match: {},
};

export default memo(Timeline);
