import React, { useRef, useEffect, memo, useState } from 'react';

import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import { StyledInput, Container } from './styles';

function Input({ name, viewAs, submitFunction, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue = '', registerField, error } = useField(name);
  const [editValue, setEditValue] = useState(rest.defaultValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const [isEditing, setIsEditing] = useState(false);
  const edit = () => setIsEditing(true);
  const done = () => {
    const splitItems = fieldName.split('.');
    submitFunction({ [splitItems[0]]: inputRef.current.value, id: splitItems[1], ...rest.customData });
    setEditValue(inputRef.current.value);
    setIsEditing(false);
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  return (
    <Container>
      {isEditing ? (
        <div>
          <StyledInput id={fieldName} ref={inputRef} defaultValue={defaultValue} {...rest} onBlur={done} />
          {error && <span className='input-error'>{error}</span>}
        </div>
      ) : (
        React.createElement(viewAs || 'p', { onClick: edit }, editValue)
      )}
    </Container>
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  viewAs: PropTypes.string.isRequired,
  submitFunction: PropTypes.func.isRequired,
};

export default memo(Input);
