import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from '@unform/web';

import { Input } from '~/components';
import { signOut } from '~/store/modules/auth/actions';
import { updateProfileRequest, updateBreadcrumb } from '~/store/modules/user/actions';

import { Container } from './styles';
import { backgrounds } from 'polished';

function Profile() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { name: 'Início', path: '/' },
        { name: 'Perfil', path: '/profile' },
      ]),
    );
  }, [dispatch]);

  async function handleSubmit(data) {
    dispatch(updateProfileRequest(data));
    dispatch(signOut());
  }

  function handleSignOut() {
    dispatch(signOut());
  }

  return (
    <Container>
      <Form initialData={profile} onSubmit={handleSubmit}>
        <label htmlFor='name'>Nome</label> {//eslint-disable-line
        }
        <Input name='name' placeholder='Nome' disabled />
        <label htmlFor='email'>E-mail</label> {// eslint-disable-line
        }
        <Input name='email' placeholder='Seu endereço de e-mail' disabled />
        <hr />
        <Input type='password' name='oldPassword' placeholder='Sua senha secreta' />
        <Input type='password' name='password' placeholder='Nova senha secreta' />
        <Input type='password' name='password_confirmation' placeholder='Confirme a nova Senha' />
        <button type='submit'>Atualizar Perfil</button>
        <button type='button' className='logout' onClick={handleSignOut}>
          Sair da Plataforma
        </button>
        <button1 type='button' className='perfil'  >
          Ver Perfil
        </button1>
      </Form>
    </Container>
  );
}

export default memo(Profile);
