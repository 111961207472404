import React, { memo } from 'react';

import { Container } from './styles';

function AdminMenu() {
  return (
    <Container>
      <div className='navbar'>
        <i className='fas fa-home icon-home' />
        <i className='fas fa-cog icon-settings' />

        <div className='circle'>
          <i className='fas fa-plus plus-icon' />
          <i className='fab fa-youtube social' />
          <i className='fab fa-twitter social' />
          <i className='fab fa-github  social' />
        </div>
        <div className='circleBackground' />
      </div>
    </Container>
  );
}

export default memo(AdminMenu);
