import React, { memo, useEffect, useState, useCallback, useRef } from 'react';
import { MdSyncProblem } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Form } from '@unform/web';
import { debounce } from 'lodash';

import { OrgChart, Select, ButtonCard } from '~/components';
import { mountOptionList } from '~/functions';
import * as routes from '~/routes/config';
import api from '~/services/api';
import { updateEvaluationCycleInUse, updateEvaluationCycleOptions } from '~/store/modules/cycle/actions';
import { updateBreadcrumb } from '~/store/modules/user/actions';

import { Container, DeparmentContainer, FormContainer } from './styles';

function AreaEmployees() {
  const dispatch = useDispatch();

  const history = useHistory();
  const [cycleList, setCycleList] = useState([]);
  const [treeNodes, setTreeNodes] = useState([]);
  const [showList, setShowList] = useState([]);
  const formRef = useRef(null);

  const admin = useSelector((state) => state.user.admin);
  const cycle = useSelector((state) => state.cycle);

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { name: 'Início', path: '/' },
        { name: 'Resumo das Avaliações', path: '/summary-evaluations' },
      ]),
    );
  }, [dispatch]);

  useEffect(() => {
    if (cycleList.length) {
      formRef.current.setData({ cycle_id: cycle.id });
    }
  }, [cycle.id, cycleList.length]);

  const mountTreeList = useCallback(async (childrenEntity, setFunction) => {
    const { data: childrenData } = await api.get(`${childrenEntity}`);
    setShowList((list) => [...list, childrenData[0]?.value]);
    setFunction(childrenData);
  }, []);

  useEffect(() => {
    mountTreeList('departmenthierarchies', setTreeNodes);
    mountOptionList('evaluationcycles', setCycleList);
  }, [mountTreeList]);

  const openReport = (departmentId) => {
    dispatch(updateEvaluationCycleOptions({ departmentId }));
    history.push(`${routes.SUMMARY_EVALUATION_AREA}`);
  };

  const handleChangeSubmit = useCallback( // eslint-disable-line
    debounce(
      () => {
        formRef.current.submitForm();
      },
      10,
      { trailing: true },
    ),
    [],
  );

  const submitForm = ({ cycle_id }) => {
    dispatch(updateEvaluationCycleInUse({ id: cycle_id }));
  };

  const handleConciliation = () => {
    history.push(routes.CONCILIATION);
  };

  return (
    <Container>
      {admin ? (
        <DeparmentContainer>
          <FormContainer>
            <ButtonCard onClick={handleConciliation} show>
              <div>
                <MdSyncProblem size={48} color='#333' />
                <h1>Calibração</h1>
              </div>
              <small>Verifique quais serão os colaboradores que necessitam de calibração</small>
            </ButtonCard>
            <Form onSubmit={submitForm} onChange={handleChangeSubmit} className='form' ref={formRef}>
              <Select
                name='cycle_id'
                placeholder='Ciclo'
                label='Escolha o Ciclo'
                options={cycleList}
                isMulti={false}
                closeMenuOnSelect
                onChange={handleChangeSubmit}
              />
            </Form>
          </FormContainer>
          {cycle.id && <OrgChart data={treeNodes} showList={showList} handleList={setShowList} openReport={openReport} />}
        </DeparmentContainer>
      ) : (
        <h1>Sem permissão para esta sessão</h1>
      )}
    </Container>
  );
}

export default memo(AreaEmployees);
