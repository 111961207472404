export const Types = {
  SUBSCRIBE_USER_REQUEST: '@websocket/SUBSCRIBE_USER_REQUEST',
};

export function subscribeUserRequest(data) {
  return {
    type: Types.SUBSCRIBE_USER_REQUEST,
    payload: data,
  };
}
