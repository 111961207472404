import styled from 'styled-components';

export const ButtonContainer = styled.button`
  background: #ddd;
  color: #222;
  border: none;
  margin-bottom: 20px;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background 0.3s ease-out;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &:hover {
    background: #ccc;
  }
`;
