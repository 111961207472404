import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  label {
    color: #333;
    margin-bottom: 5px;
    display: block;
  }

  .react-select-unimed {
    border: none !important;
    width: 100%;
    border-radius: 4px;
  }

  .react-select-unimed__indicators {
    span {
      background-color: rgba(150, 150, 150, 0.9);
    }
    svg {
      color: rgba(150, 150, 150, 0.9) !important;
    }
  }

  .css-1rhbuit-multiValue {
    background: none !important;
  }

  div[class$='-control'] {
    background: rgba(155, 155, 155, 0.2);
    border: none !important;
    font-size: 1.5rem !important;
    min-height: 44px !important;
    /* margin: 0 0 10px; */

    &::placeholder {
      color: #888;
    }
  }
`;
