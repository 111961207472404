import React, { memo } from 'react';
import { Switch } from 'react-router-dom';

import {
  AreaEmployees,
  AreaPDI,
  Behaviors,
  Classification,
  Conciliation,
  ContinuousFeedback,
  Comments,
  Dashboard,
  EmployeeDashboard,
  Timeline,
  LeaderDashboard,
  Departments,
  DepartmentContinuousFeedback,
  DepartmentPdi,
  DevelopmentPlans,
  EvaluationCycles,
  EmployeeTimeline,
  Feedback,
  ForgotPassword,
  Forms,
  Results,
  Hierarchies,
  MainMenu,
  Paths,
  PdiReport,
  Positions,
  Profile,
  Questions,
  RatingScale,
  SignIn,
  Skills,
  SummaryEvaluations,
  Trainings,
  TrainingRequest,
  EmployeeTraining,
  VideoTraining,
  Unfinished,
  Users,
  Follow,
} from '~/pages';
import EmployeeDevelopmentPlan from '~/pages/Dashboard/Employee/DevelopmentPlan';
import { EmployeeEvaluation, LeaderEvaluation } from '~/pages/Evaluations';
import SummaryAreaEvaluation from '~/pages/SummaryEvaluations/SummaryAreaEmployees';
import SummaryEmployee from '~/pages/SummaryEvaluations/SummaryEmployees';

import * as RouteItems from './config';
import Route from './Route';

function Routes() {
  return (
    <Switch>
      <Route exact path='/' component={SignIn} />
      <Route path='/forgotpassword' component={ForgotPassword} />

      <Route path={RouteItems.AREA_EMPLOYEES} component={AreaEmployees} isPrivate />
      <Route path={RouteItems.AREA_PDI} component={AreaPDI} isPrivate />
      <Route path={RouteItems.BEHAVIOR} component={Behaviors} isPrivate isAdmin />
      <Route path={RouteItems.CLASSIFICATION} component={Classification} isPrivate isAdmin />
      <Route path={RouteItems.COMMENT} component={Comments} isPrivate isAdmin />
      <Route path={RouteItems.CONCILIATION} component={Conciliation} isPrivate isAdmin />
      <Route path={RouteItems.CONTINUOUS_FEEDBACK} component={ContinuousFeedback} isPrivate />
      <Route path={RouteItems.DASHBOARD} component={Dashboard} isPrivate isAdmin />
      <Route path={RouteItems.DEPARTMENT} component={Departments} isPrivate isAdmin />
      <Route path={RouteItems.DEPARTMENT_PDI} component={DepartmentPdi} isPrivate isAdmin />
      <Route path={RouteItems.DEPARTMENT_CONTINUOUS_FEEDBACK} component={DepartmentContinuousFeedback} isPrivate isAdmin />
      <Route path={RouteItems.DEVELOPMENT_PLAN} component={DevelopmentPlans} isPrivate isAdmin />
      <Route path={RouteItems.EMPLOYEE_DASHBOARD} component={EmployeeDashboard} isPrivate />
      <Route path={RouteItems.EMPLOYEE_DEVELOPMENT_PLAN} component={EmployeeDevelopmentPlan} isPrivate />
      <Route path={RouteItems.EMPLOYEE_EVALUATION} component={EmployeeEvaluation} isPrivate />
      <Route path={`${RouteItems.EMPLOYEE_TIMELINE}/:employee_id`} component={EmployeeTimeline} isPrivate />
      <Route path={RouteItems.EMPLOYEE_TRAINING} component={EmployeeTraining} isPrivate />
      <Route path={RouteItems.EVALUATION_CYCLE} component={EvaluationCycles} isPrivate isAdmin />
      <Route path={RouteItems.FORM} component={Forms} isPrivate isAdmin />
      <Route path={RouteItems.FOLLOW} component={Follow} isPrivate isAdmin />
      <Route path={RouteItems.HIERARCHY} component={Hierarchies} isPrivate isAdmin />
      <Route path={RouteItems.LEADER_DASHBOARD} component={LeaderDashboard} isPrivate />
      <Route path={`${RouteItems.LEADER_EVALUATION}/:employee_id`} component={LeaderEvaluation} isPrivate />
      <Route path={`${RouteItems.MANAGER_FEEDBACK}/:employee_id`} component={Feedback} isPrivate />
      <Route path={RouteItems.PATH} component={Paths} isPrivate isAdmin />
      <Route path={RouteItems.PDI_REPORT} component={PdiReport} isPrivate isAdmin />
      <Route path={RouteItems.POSITION} component={Positions} isPrivate isAdmin />
      <Route path={RouteItems.PROFILE} component={Profile} isPrivate />
      <Route path={RouteItems.QUESTION} component={Questions} isPrivate isAdmin />
      <Route path={RouteItems.RATING_SCALE} component={RatingScale} isPrivate isAdmin />
      <Route path={RouteItems.RESULTS} component={Results} isPrivate />
      <Route path={RouteItems.SKILL} component={Skills} isPrivate isAdmin />
      <Route path={RouteItems.SUMMARY_EVALUATION} component={SummaryEvaluations} isPrivate isAdmin />
      <Route path={RouteItems.SUMMARY_EVALUATION_AREA} component={SummaryAreaEvaluation} isPrivate isAdmin />
      <Route path={RouteItems.SUMMARY_EVALUATION_EMPLOYEE} component={SummaryEmployee} isPrivate />
      <Route path={RouteItems.SYSTEM_CONFIG} component={MainMenu} isPrivate />
      <Route path={`${RouteItems.TIMELINE}/:employee_id`} component={Timeline} isPrivate />
      <Route path={RouteItems.TRAINING} component={Trainings} isPrivate />
      <Route path={RouteItems.TRAINING_REQUEST} component={TrainingRequest} isPrivate />
      <Route path={`${RouteItems.UNFINISHED}/:option`} component={Unfinished} isPrivate isAdmin />
      <Route path={RouteItems.USER} component={Users} isPrivate isAdmin />
      <Route path={RouteItems.VIDEO_TRAINING} component={VideoTraining} isPrivate />
    </Switch>
  );
}

export default memo(Routes);
