export const headers = [
  {
    title: 'Nome',
    width: '25%',
    align: 'left',
    columnName: 'name',
    dataType: String,
  },
  {
    title: 'Observação',
    width: '30%',
    align: 'left',
    columnName: 'observation',
    dataType: String,
  },
  {
    title: 'Trajetória',
    width: '30%',
    align: 'left',
    columnName: 'paths.description',
    dataType: String,
  },
  {
    title: 'Ativo',
    width: '5%',
    align: 'left',
    columnName: 'active',
    dataType: Boolean,
  },
];
