import React, { useEffect, memo, useState, useRef } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { GoRepo } from 'react-icons/go';
import { MdDeleteForever, MdKeyboardArrowUp, MdKeyboardArrowDown, MdPlaylistAdd, MdFingerprint } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { Scope } from '@unform/core';
import { Form } from '@unform/web';
import PropTypes from 'prop-types';

import { ButtonNormal, DatePicker, Input, Select, TextBox, ButtonLink } from '~/components';
import LoadingTest from '~/components/Shimmer/LoadingTest';
import { getRandomInt, toCamelCase, mountOptionList } from '~/functions';
import * as routes from '~/routes/config';
import api from '~/services/api';
import { updateEvaluationCycleOptions, updateEvaluationCycleInUse } from '~/store/modules/cycle/actions';
import { updateBreadcrumb } from '~/store/modules/user/actions';

import {
  Container,
  SkillContainer,
  AnswerContainer,
  FormContainer,
  OptionsContainer,
  MobileInstructionContainer,
  DescriptionContainer,
  FieldsContainer,
} from './styles';

function Feedback({
  match: {
    params: { employee_id },
  },
}) {
  const profile = useSelector((state) => state.user.profile);
  const cycle = useSelector((state) => state.cycle);

  const [employee, setEmployee] = useState({});
  const [commentList, setCommentList] = useState([]);
  const [developmentPlanList, setDevelopmentPlanList] = useState([]);
  const [planList, setPlanList] = useState([]);
  const [commentSaved, setCommentSaved] = useState([]);
  const [itemToShow, setItemToShow] = useState(null);
  const [evaluationCycleId, setEvaluationCycleId] = useState(null);
  const [selectedPlans, setSelectedPlans] = useState({});
  const [selectedPlansName, setSelectedPlansName] = useState({});
  const [listToShow, setListToShow] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [deleteItem, setDeleteItem] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showEmployeePasswordConfirm, setShowEmployeePasswordConfirm] = useState(false);
  const [employeePassword, setEmployeePassword] = useState('');
  const user = useSelector((state) => state.user);
  const formRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { name: 'Início', path: '/' },
        { name: 'Lista de Colaboradores', path: '/area-employees' },
        { name: 'Feedback', path: '/manager-feedback' },
      ]),
    );
  }, [dispatch]);

  useEffect(() => {
    async function getAnswers() {
      const { data: employeeData } = await api.get(`users/${employee_id}`);

      await mountOptionList('developmentplans', setPlanList);

      setEmployee(employeeData);
      setEvaluationCycleId(cycle.id);

      const { data: commentsData } = await api.get(
        `evaluationcyclecomments?evaluation_cycle_id=${cycle.id}&employee_id=${employee_id}`,
      );

      setCommentList(commentsData);
      commentsData.forEach((ans) => {
        setCommentSaved((com) => ({ ...com, [ans.id]: [ans.leader_comment] }));
      });

      const { data: developmentPlansData } = await api.get(
        `evaluationcycledevelopmentplans?evaluation_cycle_id=${cycle.id}&employee_id=${employee_id}`,
      );

      setDevelopmentPlanList(developmentPlansData);
      developmentPlansData.forEach((plan) => {
        const fakeIndex = plan.id ?? plan.fake_id;
        setSelectedPlans((sel) => ({ ...sel, [fakeIndex]: plan.developmentPlans?.description }));
        setSelectedPlansName((sel) => ({ ...sel, [fakeIndex]: plan.developmentPlans?.action }));
      });

      setLoaded(true);

      dispatch(updateEvaluationCycleInUse({ id: cycle.id }));
      dispatch(
        updateEvaluationCycleOptions({ departmentId: cycle.options.departmentId, employeeId: employee_id, singleView: true })
      );
    }

    getAnswers();
  }, [employee_id, user.id]);

  useEffect(() => {
    if (!loaded) return;
    const data = { comments: commentSaved, development_plans: developmentPlanList };
    formRef.current.setData(data); // Needed to set Selection option
    setInitialData(data);
    setItemToShow('comentarios');
  }, [loaded]); // eslint-disable-line

  async function handleSubmit(data) {
    const comments = Object.keys(data.comments).map((key) => ({
      id: commentList.find((item) => item.id === key).id,
      leader_comment: data.comments[key],
      leader_finished: !!data.comments[key].trim(),
      leader_id: profile.id,
    }));

    try {
      await api.put(`evaluationcyclecomments/${comments[0].id}`, {
        comments,
        developmentPlans: data.development_plans,
      });

      toast.success(`Dados salvos com sucesso`);
    } catch (err) {
      toast.error(`Algo deu errado! ${err.response.data.message}`);
    }
  }

  const addDevelopmentPlan = () => {
    const newPlan = {
      id: null,
      fake_id: `_${getRandomInt(100000)}.${getRandomInt(100000)}`,
      leader_id: profile.id,
      evaluation_cycle_id: evaluationCycleId,
      employee_id,
    };

    setInitialData((init) => ({ ...init, development_plans: [...init.development_plans, { ...newPlan }] }));
    setDevelopmentPlanList((dev) => [...dev, newPlan]);
    setListToShow((list) => [...list, newPlan.fake_id]);
  };

  const removeDevelopmentPlan = async () => {
    if (!deleteItem) return;
    const developmentPlanToDelete = developmentPlanList
      .filter((dev) => dev.id === deleteItem || dev.fake_id === deleteItem)
      .shift();

    const deleteReturn = await api.delete(
      //evaluationcycledevelopmentplans/${developmentPlanToDelete.id ?? developmentPlanToDelete.fake_id},
    );

    if (deleteReturn.status === 204) {
      const newDevelopmentPlanList = developmentPlanList.filter((dev) => dev?.id !== deleteItem && dev.fake_id !== deleteItem);
      setDevelopmentPlanList(newDevelopmentPlanList);
      setShowConfirm(false);
      setShowSuccess(true);
    } else {
      toast.error(`Algo deu errado!! ${deleteReturn.statusText}`);
    }
    setDeleteItem(null);
  };

  const verifyEmployeePasswordHandle = async () => {
    try {
      const { data } = await api.post('confirmemployeefeedback', { employee_id, password: employeePassword });
      if (data.status) {
        setShowSuccess(true);
        setShowEmployeePasswordConfirm(false);
        setEmployeePassword('');
      } else {
        toast.error(`Algo não está certo!. Verifique sua senha e tente novamente!`);
        setEmployeePassword('');
      }
    } catch (err) {
      // do something
    }
  };

  return (
    <Container>
      <DescriptionContainer>
        {employee.name && (
          <>
            <section>
              <h3>{employee.name}</h3>
              <span>{employee?.positions?.description}</span>
            </section>
            <section className='right-button-container'>
              <ButtonNormal
                type='button'
                className='confirm-button'
                onClick={() => setShowEmployeePasswordConfirm(true)}
                background='#ddd'
                color='#333'>
                <MdFingerprint size={24} color='#411564' />
                Confirmar Feedback
              </ButtonNormal>
              <ButtonLink color='#333' background='#ddd' to={routes.SUMMARY_EVALUATION_EMPLOYEE} target='_blank'>
                <GoRepo size={24} color='#333' className='svg-report' />
                Acessar Relatório
              </ButtonLink>
            </section>
          </>
        )}
      </DescriptionContainer>
      <MobileInstructionContainer>
        <div className='mobile-info'>Clique em cada um dos botões abaixo e responda a todas as questões</div>
      </MobileInstructionContainer>
      {loaded ? (
        <FormContainer>
          <OptionsContainer>
            <div className='options-skill-title'>Selecione um item</div>
            <button
              type='button'
              className={itemToShow === 'comentarios' ? 'active' : ''}
              onClick={() => setItemToShow('comentarios')}>
              Feedback
            </button>
            <button
              type='button'
              className={itemToShow === 'plano-desenvolvimento' ? 'active' : ''}
              onClick={() => setItemToShow('plano-desenvolvimento')}>
              PDI
            </button>
          </OptionsContainer>
          <Form onSubmit={handleSubmit} initialData={initialData} ref={formRef}>
            <SkillContainer show={itemToShow === 'comentarios'}>
              <div className='skill_name'>
                <h2>Retorno aos seus colaboradores</h2>
              </div>
              {commentList &&
                commentList.map((comment) => (
                  <AnswerContainer key={comment.id}>
                    <h4>{toCamelCase(comment.comments.description)}</h4>
                    <Scope path='comments'>
                      <TextBox
                        name={`${comment.id}`}
                        rows={2}
                        max-length={2000}
                        placeholder='Insira seu comentário aqui'
                        required
                      />
                    </Scope>
                  </AnswerContainer>
                ))}
              <ButtonNormal text='Salvar resposta' type='submit' />
            </SkillContainer>
            <SkillContainer show={itemToShow === 'plano-desenvolvimento'}>
              <div className='skill_name'>
                <h2>Plano de Desenvolvimento Individual</h2>
                <ButtonNormal type='button' onClick={addDevelopmentPlan} background='#ddd' color='#333'>
                  <MdPlaylistAdd size={24} color='#00995d' />
                  Adicionar Plano
                </ButtonNormal>
              </div>
              {developmentPlanList &&
                developmentPlanList.map((devPlan, index) => (
                  <Scope path={`development_plans[${index}]`} key={devPlan.id ? devPlan.id : devPlan.fake_id}>
                    <AnswerContainer>
                      <FieldsContainer show={listToShow.includes(devPlan.id ?? devPlan.fake_id)}>
                        <h4 className='development-plan-header'>
                          <span>
                            {selectedPlansName[devPlan.id ?? devPlan.fake_id] ??
                              'Descreva aqui o planejamento para seu colaborador'}
                            {selectedPlansName[devPlan.id ?? devPlan.fake_id] === 'Outros' && ` - ${devPlan.others}`}
                          </span>
                          <div>
                            <button type='button'>
                              {listToShow.includes(devPlan.id ?? devPlan.fake_id) ? (
                                <MdKeyboardArrowUp
                                  size={24}
                                  onClick={() => {
                                    const fakeIndex = devPlan.id ?? devPlan.fake_id;
                                    setListToShow((list) => [...list.filter((l) => l !== fakeIndex)]);
                                  }}
                                />
                              ) : (
                                <MdKeyboardArrowDown
                                  size={24}
                                  onClick={() => {
                                    const fakeIndex = devPlan.id ?? devPlan.fake_id;
                                    setListToShow((list) => [...list, fakeIndex]);
                                  }}
                                />
                              )}
                            </button>
                            <button type='button'>
                              <MdDeleteForever
                                size={24}
                                color='#ed1651'
                                onClick={() => {
                                  setDeleteItem(devPlan.id ?? devPlan.fake_id);
                                  setShowConfirm(true);
                                }}
                              />
                            </button>
                          </div>
                        </h4>
                        <Input name='id' id='id' hidden />
                        <Input name='fake_id' id='fake_id' hidden />
                        <Input name='leader_id' id='leader_id' hidden />
                        <Input name='evaluation_cycle_id' id='evaluation_cycle_id' hidden />
                        <Input name='employee_id' id='employee_id' hidden />
                        <div />
                        <section className='select-container'>
                          <div>
                            <Select
                              name='development_plan_id'
                              placeholder='Selecione uma opção?'
                              label='Tipo de Plano'
                              options={planList}
                              isMulti={false}
                              onChange={(e) => {
                                if (e === null) return;
                                const fakeIndex = devPlan.id ?? devPlan.fake_id;
                                setSelectedPlansName((sel) => ({ ...sel, [fakeIndex]: e?.label }));
                              }}
                              closeMenuOnSelect
                              fieldPointer='development_plan_id'
                              required
                            />
                            {selectedPlansName[devPlan.id ?? devPlan.fake_id] === 'Outros' && (
                              <Input name='others' id='others' label='Digite uma descrição para o Tipo de Plano' />
                            )}
                          </div>
                        </section>
                        <TextBox
                          name='action'
                          rows={1}
                          max-length={2000}
                          placeholder='Insira o plano de desenvolvimento aqui'
                          className='action-description'
                          label='Plano de Desenvolvimento'
                          required
                        />
                        <TextBox
                        name='expected'
                        rows={1}
                        max-length={2000}
                        placeholder='Resultado esperado'
                        className='expected'
                        label='Resultado esperado'
                        required
                      />
                        <DatePicker
                          id='initial_date'
                          name='initial_date'
                          dateFormat='dd/MM/yyyy'
                          type='keyboardDate'
                          onBlur={() => null}
                          label='Início (Obrigatório)'
                          required
                        />
                        <DatePicker
                          id='final_date'
                          name='final_date'
                          dateFormat='dd/MM/yyyy'
                          type='keyboardDate'
                          onBlur={() => null}
                          label='Previsão de Término (Obrigatório)'
                          required
                        />
                      </FieldsContainer>
                    </AnswerContainer>
                  </Scope>
                ))}
              <ButtonNormal text='Salvar plano' type='submit' />
            </SkillContainer>
          </Form>
        </FormContainer>
      ) : (
        <LoadingTest />
      )}

      <SweetAlert
        confirmBtnText='Excluir'
        cancelBtnText='Cancelar'
        title='Excluir item?'
        onConfirm={removeDevelopmentPlan}
        onCancel={() => setShowConfirm(false)}
        showCancel
        dependencies={[deleteItem]}
        show={showConfirm}
        confirmBtnCssClass='delete-confirm-button'
        cancelBtnCssClass='delete-cancel-button'
      />

      <SweetAlert
        confirmBtnText='Confirmar Feedback'
        cancelBtnText='Cancelar'
        title='Peça ao colaborador para inserir a senha:'
        onConfirm={verifyEmployeePasswordHandle}
        onCancel={() => setShowEmployeePasswordConfirm(false)}
        showCancel
        show={showEmployeePasswordConfirm}
        confirmBtnCssClass='delete-confirm-button'
        cancelBtnCssClass='delete-cancel-button'>
        <Form>
          <Input
            name='employeePassword'
            type='password'
            defaultValue={employeePassword}
            onChange={(e) => setEmployeePassword(e.target.value)}
            required
          />
        </Form>
      </SweetAlert>

      <SweetAlert
        title='Operação realizada com sucesso'
        success
        confirmBtnCssClass='delete-confirm-button'
        onConfirm={() => setShowSuccess(false)}
        show={showSuccess}
      />
    </Container>
  );
}

Feedback.defaultProps = {
  match: {},
};

export default memo(Feedback);
