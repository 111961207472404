/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useRef, useEffect, memo } from 'react';
import makeAnimated from 'react-select/animated';
import Select from 'react-select/async';

import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import { Container } from './styles';

// Estou utilizando o fieldPointer ao inves do name, pois em alguns casos o nome do campo pode ser diferente quando se trata de uma outra tabela
function ReactSelect({ name, label, limit = null, ...rest }) {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const filterOptions = (inputValue) => {
    return rest.options.filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterOptions(inputValue));
    }, 500);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'state.value',
      getValue: (selectRef) => {
        if (rest.isMulti) {
          if (!selectRef.select.state.value) {
            return [];
          }
          return selectRef.select.state.value.map((option) => option.value);
        }
        if (!selectRef.select.state.value) {
          return '';
        }
        return selectRef.select.state.value.value;
      },
      setValue: (selectRef, value) => {
        selectRef.select.select.setValue({
          value,
          label: selectRef.select.props.options.filter((opt) => opt.value === value)[0]?.label,
        });
      },
      clearValue: (selectRef) => {
        selectRef.select.select.clearValue();
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <Container>
      {label && <label htmlFor={name}>{label}</label>}
      <Select
        ref={ref}
        cacheOptions
        components={rest.isMulti && makeAnimated()}
        styles={
          (rest.isMulti && {
            multiValueLabel: (base) => ({
              ...base,
              backgroundColor: '#411564',
              color: '#ffcb08',
              padding: '5px 8px',
              borderRadius: 'none',
              marginBottom: '6px',
            }),
            // control: (base) => ({
            //   ...base,
            //   padding: values && values.length > 0 ? '6px 0 0 0' : '6px 0',
            // }),
            clearIndicator: (base) => ({ ...base, cursor: 'pointer' }),
            dropdownIndicator: (base) => ({ ...base, cursor: 'pointer' }),
            multiValueRemove: (base) => ({
              ...base,
              cursor: 'pointer',
              background: '#b1d34b',
              borderRadius: 'none',
              padding: '5px 8px',
              marginBottom: '6px',
              marginRight: '5px',
            }),
          },
          { menuPortal: (base) => ({ ...base, zIndex: 9999 }) })
        }
        classNamePrefix='react-select-unimed'
        isClearable
        isSearchable
        aria-label={fieldName}
        name={fieldName}
        loadOptions={loadOptions}
        loadingMessage={() => 'Carregando...'}
        defaultOptions={rest.options}
        defaultValue={defaultValue}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        noOptionsMessage={(selectRef) => {
          return selectRef.inputValue && selectRef.inputValue.length >= limit && limit !== null
            ? 'Número máximo de setores já selecionado, exclua um, caso deseje inserir outro setor'
            : 'Nada encontrado...';
        }}
        on
        menuPortalTarget={document.body}
        menuPosition='fixed'
        {...rest}
      />
      {error && <span>{error}</span>}
    </Container>
  );
}

ReactSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  limit: PropTypes.number,
};

ReactSelect.defaultProps = {
  label: '',
  limit: null,
};

export default memo(ReactSelect);
