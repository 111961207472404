import styled from 'styled-components';

export const Container = styled.div``;

export const PlayerContainer = styled.div`
  position: relative;
  height: 0px;
  padding-bottom: 56.25%;

  div.bitmovinplayer-container {
    padding: 0;
    margin: 0;
    position: absolute;
    overflow: hidden;
    min-height: 150px;
    min-width: 260px;
    box-sizing: content-box;
    background-color: transparent;
    width: 100%;
    height: 100%;

    video {
      object-fit: contain;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  div.bmpui-ui-uicontainer {
    height: 100%;
    left: 0;
    margin: 0;
    position: absolute;
    top: 0;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    color: #fff;
    font-family: sans-serif;
    font-size: 1em;
    text-align: left;
    user-select: none;

    div.bmpui-container-wrapper {
      pointer-events: auto;

      div.bmpui-ui-controlbar {
        opacity: 1;
        transition: opacity 0.3s, visibility 0s;
        bottom: 0;
        position: absolute;
        width: 100%;
        background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.7));
        box-sizing: border-box;
        line-height: 1em;
        padding: 1em 1em 0.5em;
        pointer-events: auto;

        div.bmpui-ui-settings-panel {
          width: 167px;
          height: 98px;
          opacity: 1;
          visibility: visible;
          transition: opacity 0.3s, visibility 0s, height 0.35s cubic-bezier(0.4, 0, 0.2, 1),
            width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
          background-color: hsla(0, 0%, 7%, 0.85);
          bottom: 5em;
          overflow: hidden;
          padding: 0;
          position: absolute;
          right: 2em;
          pointer-events: auto;
        }

        div.bmpui-controlbar-top {
          div.bmpui-container-wrapper {
            display: flex;
            width: 100%;
            margin: 0.5em 0;
            justify-content: space-between;

            span.bmpui-ui-playbacktimelabel {
              min-width: 62px;
              text-transform: uppercase;
              margin: 0 0.5em;

              &.text-right {
                text-align: right;
              }
            }

            div.bmpui-ui-seekbar {
              cursor: pointer;
              font-size: 1em;
              height: 1em;
              position: relative;
              width: 100%;

              input[type='range'] {
                width: 100%;
                -webkit-appearance: none;
                border-radius: 11px;
                cursor: pointer;
                background: transparent;

                &::-webkit-slider-runnable-track {
                  background: rgba(0, 0, 0, 0.6);
                  border-radius: 10px;
                  height: 6px;
                  border: none;
                }

                &::-webkit-slider-thumb {
                  -webkit-appearance: none;
                  background: #ffcb08;
                  height: 16px;
                  width: 16px;
                  margin-top: -6px;
                  border-radius: 50%;
                }
              }
            }
          }
        }
      }

      div.bmpui-ui-playbacktoggle-overlay {
        button.bmpui-ui-hugeplaybacktogglebutton {
          height: 100%;
          left: 0;
          margin: 0;
          position: absolute;
          top: 0;
          width: 100%;
          outline: 0;
          overflow: hidden;
          cursor: default;
          background-color: transparent;
          background-origin: content-box;
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: 1.5em;
          border: 0;
          box-sizing: content-box;
          cursor: pointer;
          font-size: 1em;
          min-width: 1.5em;
          padding: 0.25em;
          border: none;

          span.bmpui-label {
            color: #fff;
            display: none;
          }

          div.bmpui-image {
            background-image: '../../assets/playbacktoggle.svg' !important;
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: 7em;
            height: 100%;
            width: 100%;
            animation: none;
            transition: none;
            visibility: visible;
          }
        }
      }
    }
  }

  button.pip {
    position: absolute;
    right: 25px;
    top: 25px;
    border: 0px;
    background: rgba(65, 21, 100, 0.8);
    box-shadow: rgb(0 0 0 / 25%) 0px 0px 5px;
    border-radius: 3px;
    padding: 5px 6px 1px 6px;
    transition: all 0.2s ease 0s;
  }
`;

export const ExtraCommandsContainer = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background: rgb(32, 32, 36);
  border-radius: 0px 0px 5px 5px;
  padding: 16px;

  div {
    display: flex;
    -webkit-box-align: center;
    align-items: center;

    button {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      background: rgb(41, 41, 46);
      border-radius: 5px;
      border: 0px;
      transition: background 0.2s ease 0s;

      &:not(first-of-type) {
        margin-left: 8px;
      }
    }

    label.auto-play {
      display: flex;
      align-items: center;

      span.auto-play-text {
        font-size: 14px;
        color: rgb(225, 225, 230);
        margin: 0px 12px 0px 0px;
        opacity: 1;
        transition: opacity 0.2s ease 0s;
        font-weight: 300;
      }
    }
  }
`;
