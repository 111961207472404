export const headers = [
  {
    title: 'Descrição',
    width: '40%',
    align: 'left',
    columnName: 'name',
    dataType: String,
  },
  {
    title: 'Nível',
    width: '25%',
    align: 'left',
    columnName: 'level',
    dataType: String,
  },
  {
    title: 'Código de Área',
    width: '15%',
    align: 'center',
    columnName: 'area_code',
    dataType: String,
  },
  {
    title: 'Status',
    width: '10%',
    align: 'center',
    columnName: 'active',
    dataType: Boolean,
  },
];
