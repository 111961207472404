import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: block;
  position: relative;
`;

export const RootNotLeafContainer = styled.span`
  border: 1px solid #999;
  background: #999;
  color: #333;
  border-radius: 4px;
  padding: 2px 15px;
  margin-left: -10px;
  margin-bottom: 5px;
`;

export const Tree = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 2.5px 25px;
  margin: 0;
  line-height: 20px;
  color: #333;
  font-weight: 500;
  border-left: 0.5px dashed rgb(5, 5, 5);

  label {
    position: relative;
    left: -10px;
  }

  &:first-child {
    border: none !important;
    &:before {
      border: none;
      border-left: none !important;
    }
  }

  &:before {
    position: relative;
    top: 10px;
    width: 10px;
    color: white;
    border-bottom: 0.5px dashed rgb(5, 5, 5);
    border-left: 0.5px dashed rgb(5, 5, 5);
    content: '';
    display: inline-block;
    left: -25px;
  }

  &:last-child {
    border: none;
    &:before {
      height: 14px;
      margin-top: -14px;
      border-left: 0.5px dashed rgb(5, 5, 5);
    }
  }
`;
