/* eslint-disable no-return-assign */
import React, { memo, useEffect, useState, useRef, useCallback } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Calendar from 'react-calendar';
import { Doughnut } from 'react-chartjs-2';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from '@unform/web';

import 'react-calendar/dist/Calendar.css';
import { mountOptionList, getUTCDate, formatDate, returnTernaryResponse } from 'functions';
import { debounce } from 'lodash';

import AutoAvaliacao from '~/assets/autoavaliacao.png';
import EmBreve from '~/assets/breve.jpg';
import Completed from '~/assets/completed.jpg';
import FeedbackContinuo from '~/assets/continuous_feedback.jpg';
import MyFeedback from '~/assets/feedback.jpg';
import Gestor from '~/assets/leader-evaluation.jpg';
import Feedback from '~/assets/talk.png';
import { Select, MainContainer } from '~/components';
import { LoadingCard } from '~/components/Shimmer';
import * as routes from '~/routes/config';
import api from '~/services/api';
import { updateEvaluationCycleInUse } from '~/store/modules/cycle/actions';
import { updateBreadcrumb, updateProcessRequest, updateOptionsRequest } from '~/store/modules/user/actions';

import {
  UpContainer,
  CardContainer,
  DescriptionContainer,
  CalendarContainer,
  ExpiredPeriodCardContainer,
  FormContainer,
} from './styles';

function Dashboard() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const cycle = useSelector((state) => state.cycle);

  const { roles } = profile ?? [];

  const [evaluationCycleData, setEvaluationCycleData] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [employeeChartData, setEmployeeChartData] = useState({});
  const [resultChartData, setResultChartData] = useState([]);
  const [employeeFinishedAutoavaliation, setEmployeeFinishedAutoavaliation] = useState(false);
  const [employeeHasDevelopmentPlan, setEmployeeHasDevelopmentPlan] = useState(false);
  const [cycleList, setCycleList] = useState([]);
  const formRef = useRef(null);

  const chartOptions = {
    plugins: {
      legend: {
        position: 'right',
      },
    },
  };

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { name: 'Início', path: '/' },
        { name: '', path: '/' },
      ]),
    );

    mountOptionList('evaluationcycles', setCycleList);
  }, [dispatch]);

  useEffect(() => {
    if (cycle.id && cycleList.length > 0 && formRef.current) {
      formRef.current.setData({ cycle_id: cycle.id });
    }
  }, [cycle.id, cycleList.length]);

  useEffect(() => {
    async function getDates() {
      setLoading(true);
      if (!cycle.id) return;
      const { data: evaluationCycle } = await api.get(`evaluationcycles/${cycle.id}`);

      if (!evaluationCycle) {
        setShow(true);
        return;
      }
      setEvaluationCycleData(evaluationCycle);

      const { data: situation } = await api.get(`mysituation/${profile.id}/${cycle.id}`);
      setEmployeeFinishedAutoavaliation(
        Boolean(situation.evaluationCycleAnswers?.filter((answer) => answer.user_finished === 1).length),
      );

      const { data: employees } = await api.get(`consolidatedevaluationcycledevelopmentplans/fakeId/${cycle.id}`);
      const waiting = employees
        .map((employee) => employee.evaluationCycleDevelopmentPlans.filter((plan) => Number(plan.status) === 0).length)
        .reduce((a, b) => a + b, 0);
      const ended = employees
        .map((employee) => employee.evaluationCycleDevelopmentPlans.filter((plan) => Number(plan.status) === 1).length)
        .reduce((a, b) => a + b, 0);
      const canceled = employees
        .map((employee) => employee.evaluationCycleDevelopmentPlans.filter((plan) => Number(plan.status) === 2).length)
        .reduce((a, b) => a + b, 0);
      const expired = employees
        .map(
          (employee) =>
            employee.evaluationCycleDevelopmentPlans.filter(
              (plan) => Number(plan.status) !== 1 && Number(plan.status) !== 2 && new Date(plan.final_date) <= new Date(),
            ).length,
        )
        .reduce((a, b) => a + b, 0);

      setChartData({
        labels: ['Aguardando', 'Finalizado', 'Cancelado', 'Expirado'],
        datasets: [
          {
            label: 'Dados Principais',
            data: [waiting, ended, canceled, expired],
            // backgroundColor: ['rgba(65,21,100,0.5)', 'rgba(0,153,93,0.5)', 'rgba(237,22,81,0.5)', 'rgba(244,121,32,0.5)'],
            backgroundColor: ['#411564', '#00995d', '#ed1651', '#f47920'],
            borderWidth: 2,
            offset: 10,
            hoverOffset: 4,
          },
        ],
      });

      const { data: employeesAnswers } = await api.post('results', { type: 'leader', cycle: cycle.id });

      const withoutIncompletes = employeesAnswers.filter(
        (employee) =>
          employee.evaluationCycleAnswers.filter((answer) => answer.user_answer === null).length === 0 ||
          employee.evaluationCycleAnswers.filter((answer) => answer.leader_answer === null).length === 0,
      );

      const calculatedList = withoutIncompletes.map((employee) => ({
        id: employee.id,
        name: employee.name,
        department_id: employee.department_id,
        department_name: employee.departments.name,
        totalEmployee:
          employee.evaluationCycleAnswers.reduce((prev, cur) => {
            return prev + (cur.user_answer ?? 0);
          }, 0) / employee.evaluationCycleAnswers.length,
        totalLeader:
          employee.evaluationCycleAnswers.reduce((prev, cur) => {
            return prev + (cur.leader_answer ?? 0);
          }, 0) / employee.evaluationCycleAnswers.length,
        mainTotal:
          (employee.evaluationCycleAnswers.reduce((prev, cur) => {
            return prev + (cur.user_answer ?? 0);
          }, 0) /
            employee.evaluationCycleAnswers.length +
            employee.evaluationCycleAnswers.reduce((prev, cur) => {
              return prev + (cur.leader_answer ?? 0);
            }, 0) /
              employee.evaluationCycleAnswers.length) /
          2,
      }));

      let doesNotAttend = 0;
      let partiallyAttend = 0;
      let attend = 0;
      let overcomes = 0;

      const {
        data: { data: classifications },
      } = await api.get('classifications');

      const classificationsValue = classifications.reduce((ac, { description: x, ...rest }) => ((ac[x] = rest), ac), {});

      calculatedList.forEach((employee) => {
        const mainTotal = Number(employee.mainTotal).toFixed(2);

        if (
          mainTotal >= classificationsValue['Não Atende']?.initial_value &&
          mainTotal <= classificationsValue['Não Atende']?.final_value
        ) {
          doesNotAttend += 1;
        }
        if (
          mainTotal >= classificationsValue['Atende Parcialmente']?.initial_value &&
          mainTotal <= classificationsValue['Atende Parcialmente']?.final_value
        ) {
          partiallyAttend += 1;
        }
        if (mainTotal >= classificationsValue.Atende?.initial_value && mainTotal <= classificationsValue.Atende?.final_value) {
          attend += 1;
        }
        if (mainTotal >= classificationsValue.Supera?.initial_value && mainTotal <= classificationsValue.Supera?.final_value) {
          overcomes += 1;
        }
      });

      setResultChartData({
        labels: ['Não Atende', 'Atende Parcialmente', 'Atende', 'Supera'],
        datasets: [
          {
            label: 'Dados Principais',
            data: [Number(doesNotAttend), Number(partiallyAttend), Number(attend), Number(overcomes)],
            // backgroundColor: ['rgba(237,22,81,0.5)', 'rgba(255,203,8,0.5)', 'rgba(0,153,93,0.5)', 'rgba(10,95,85,0.5)'],
            backgroundColor: ['#ed1651', '#ffcb08', '#00995d', '#0a5f55'],
            borderWidth: 2,
            offset: 10,
            hoverOffset: 4,
          },
        ],
      });

      const { data: employeeDevPlan } = await api.get(`consolidatedevaluationcycledevelopmentplans/${cycle.id}`);
      setEmployeeHasDevelopmentPlan(employeeDevPlan.evaluationCycleDevelopmentPlans?.length > 0);
      const waitingEmployee = employeeDevPlan.evaluationCycleDevelopmentPlans.filter((plan) => Number(plan.status) === 0).length;
      const endedEmployee = employeeDevPlan.evaluationCycleDevelopmentPlans.filter((plan) => Number(plan.status) === 1).length;
      const canceledEmployee = employeeDevPlan.evaluationCycleDevelopmentPlans.filter((plan) => Number(plan.status) === 2).length;
      const expiredEmployee = employeeDevPlan.evaluationCycleDevelopmentPlans.filter(
        (plan) => Number(plan.status) !== 1 && Number(plan.status) !== 2 && new Date(plan.final_date) <= new Date(),
      ).length;

      setEmployeeChartData({
        labels: ['Aguardando', 'Finalizado', 'Cancelado', 'Expirado'],
        datasets: [
          {
            label: 'Dados Principais',
            data: [waitingEmployee, endedEmployee, canceledEmployee, expiredEmployee],
            // backgroundColor: ['rgba(65,21,100,0.5)', 'rgba(0,153,93,0.5)', 'rgba(237,22,81,0.5)', 'rgba(244,121,32,0.5)'],
            backgroundColor: ['#411564', '#00995d', '#ed1651', '#f47920'],
            borderWidth: 2,
            offset: 10,
            hoverOffset: 4,
          },
        ],
      });

      setLoading(false);
    }

    getDates();
  }, [cycle.id, profile.id, roles]);

  const setProcess = (process) => {
    dispatch(updateProcessRequest(process));
  };

  const returnAllResultsHandler = () => {
    dispatch(updateOptionsRequest({ resultType: 'leader' }));
  };

  const submitForm = ({ cycle_id }) => {
    dispatch(updateEvaluationCycleInUse({ id: cycle_id }));
  };

  const handleChangeSubmit = useCallback( // eslint-disable-line
    debounce(
      () => {
        if (formRef.current) {
          formRef.current.submitForm();
        }
      },
      10,
      { trailing: true },
    ),
    [],
  );

  return (
    <MainContainer>
      <FormContainer>
        <Form onSubmit={submitForm} onChange={handleChangeSubmit} className='form' ref={formRef}>
          <Select
            name='cycle_id'
            placeholder='Ciclo'
            label='Escolha o Ciclo de Avaliação'
            options={cycleList}
            isMulti={false}
            closeMenuOnSelect
            onChange={handleChangeSubmit}
          />
        </Form>
      </FormContainer>
      {!loading ? (
        <>
          {getUTCDate(evaluationCycleData.initial_evaluation_period) < getUTCDate(new Date()) && (
            <>
              {getUTCDate(evaluationCycleData?.final_employee_evaluation) > getUTCDate(new Date()) && (
                <section>
                  <DescriptionContainer>
                    <div className='text-description'>
                      <h1>Prezado(a) colaborador(a),</h1>
                      <p>
                        É com satisfação que damos início ao nosso 2º ciclo do processo de Avaliação de Desempenho por
                        Competências, referente ao período de 2022-2023. 
                      </p>
                      <p>
                        Alinhar expectativas individuais e organizacionais é parte fundamental do desenvolvimento de todos e
                        evolução da Unimed. Se conhecer e compreender o outro, obter e oferecer direcionamento, saber para onde ir
                        e o que ajustar gera confiança, promove o comprometimento e a motivação.
                      </p>
                      <p>Neste contexto, convidamos você a participar deste importante processo de gestão e desenvolvimento.</p>
                      <p className='deadline'>
                        O prazo para conclusão do processo de auto avaliação é até{' '}
                        <i>{formatDate(evaluationCycleData?.final_employee_evaluation)}</i>.
                      </p>
                      <p>Boa avaliação a todos!</p>
                      <cite>Gestão de Pessoas</cite>
                      <p className='instruction-container'> Para responder as avaliações, clique abaixo:</p>
                    </div>
                  </DescriptionContainer>
                </section>
              )}
              <section>
                <UpContainer>
                  <div>
                    {employeeHasDevelopmentPlan && (
                      <CardContainer to={routes.EMPLOYEE_DEVELOPMENT_PLAN} backgroundcolor='#fff'>
                        <div className='card-title'>
                          <h2>Seu Plano Desenvolvimento</h2>
                          <MdKeyboardArrowRight size={24} color='#411564' />
                        </div>
                        <div className='pdi-chart-container'>
                          <Doughnut data={employeeChartData} width={300} height={300} options={chartOptions} />
                        </div>
                      </CardContainer>
                    )}
                    {roles?.includes('evaluated') && (
                      <>
                        {getUTCDate(evaluationCycleData?.initial_employee_evaluation) > getUTCDate(new Date()) && (
                          <ExpiredPeriodCardContainer backgroundcolor='#fff'>
                            <div className='card-title'>
                              <h2>Autoavaliação - Em breve</h2>
                            </div>
                            <p>
                              Período de autoavaliação se inicia em{' '}
                              <i> {formatDate(evaluationCycleData?.initial_employee_evaluation)}</i>. Aguarde!
                            </p>
                            <span>
                              <img src={EmBreve} alt='' />
                            </span>
                          </ExpiredPeriodCardContainer>
                        )}
                        {getUTCDate(evaluationCycleData?.initial_employee_evaluation) <= getUTCDate(new Date()) &&
                          getUTCDate(evaluationCycleData?.final_employee_evaluation) >= getUTCDate(new Date()) &&
                          !employeeFinishedAutoavaliation && (
                            <CardContainer to={routes.EMPLOYEE_EVALUATION} backgroundcolor='#fff'>
                              <div className='card-title'>
                                <h2>Autoavaliação</h2>
                                <MdKeyboardArrowRight size={24} color='#411564' />
                              </div>
                              <p>Clique aqui e comece a preencher a sua avaliação de desempenho</p>
                              <span>
                                <img src={AutoAvaliacao} alt='' />
                              </span>
                            </CardContainer>
                          )}
                        {(employeeFinishedAutoavaliation ||
                          getUTCDate(evaluationCycleData?.final_employee_evaluation) < getUTCDate(new Date())) && (
                          <ExpiredPeriodCardContainer backgroundcolor='#fff'>
                            <div className='card-title'>
                              <h2>Autoavaliação - Finalizada</h2>
                            </div>
                            <p>
                              {returnTernaryResponse(
                                employeeFinishedAutoavaliation,
                                'Você finalizou sua autoavaliação. ',
                                'Autoavaliação finalizado. ',
                              )}
                              Aguarde as instruções sobre as próximas etapas! Logo será possível acessar seu PDI.
                            </p>
                            <span>
                              <img src={Completed} alt='' />
                            </span>
                          </ExpiredPeriodCardContainer>
                        )}
                      </>
                    )}
                    {roles?.includes('evaluator') && (
                      <>
                        {getUTCDate(evaluationCycleData?.initial_manager_evaluation) >= getUTCDate(new Date()) && (
                          <ExpiredPeriodCardContainer backgroundcolor='#fff'>
                            <div className='card-title'>
                              <h2>Avaliação do Gestor</h2>
                            </div>
                            <p>
                              Você poderá preencher a avaliação dos seus liderados a partir de{' '}
                              <i> {formatDate(evaluationCycleData?.initial_manager_evaluation)}</i>. Aguarde!
                            </p>
                            <span>
                              <img src={EmBreve} alt='' />
                            </span>
                          </ExpiredPeriodCardContainer>
                        )}
                        {getUTCDate(evaluationCycleData?.initial_manager_evaluation) <= getUTCDate(new Date()) &&
                          getUTCDate(evaluationCycleData?.final_manager_evaluation) >= getUTCDate(new Date()) && (
                            <CardContainer
                              to={routes.AREA_EMPLOYEES}
                              backgroundcolor='#fff'
                              onClick={() => setProcess('evaluation')}>
                              <div className='card-title'>
                                <h2>Avaliação do Gestor</h2>
                                <MdKeyboardArrowRight size={24} color='#411564' />
                              </div>
                              <p>Clique aqui e comece a preencher a avaliação de desempenho dos seus liderados</p>
                              <span>
                                <img src={Gestor} alt='' />
                              </span>
                            </CardContainer>
                          )}
                        {getUTCDate(evaluationCycleData?.final_manager_evaluation) < getUTCDate(new Date()) && (
                          <ExpiredPeriodCardContainer backgroundcolor='#fff'>
                            <div className='card-title'>
                              <h2>Avaliação do Gestor - Finalizado</h2>
                            </div>
                            <p>
                              Período de avaliação finalizado. O período para você preencher e dar o Feedback para seus
                              colaboradores já deve estar disponível!
                            </p>
                            <span>
                              <img src={Completed} alt='' />
                            </span>
                          </ExpiredPeriodCardContainer>
                        )}
                        {getUTCDate(evaluationCycleData?.initial_manager_feedback) >= getUTCDate(new Date()) && (
                          <ExpiredPeriodCardContainer backgroundcolor='#fff'>
                            <div className='card-title'>
                              <h2>Feedback do Gestor - Em breve</h2>
                            </div>
                            <p>
                              O Feedback do gestor se inicia em{' '}
                              <i> {formatDate(evaluationCycleData?.initial_manager_feedback)}</i>. Aguarde!
                            </p>
                            <span>
                              <img src={EmBreve} alt='' />
                            </span>
                          </ExpiredPeriodCardContainer>
                        )}
                        {getUTCDate(evaluationCycleData?.initial_manager_feedback) <= getUTCDate(new Date()) &&
                          getUTCDate(evaluationCycleData?.final_manager_feedback) >= getUTCDate(new Date()) && (
                            <CardContainer
                              to={routes.AREA_EMPLOYEES}
                              backgroundcolor='#fff'
                              onClick={() => setProcess('feedback')}>
                              <div className='card-title'>
                                <h2>Feedback do Gestor</h2>
                                <MdKeyboardArrowRight size={24} color='#411564' />
                              </div>
                              <p>
                                Clique aqui e comece a preencher o Feedback e cadastrar o PDI para seus liderados. Aqui também
                                você pode confirmar que deu o Feedback para seus colaboradores.
                              </p>
                              <span>
                                <img src={Feedback} alt='' />
                              </span>
                            </CardContainer>
                          )}
                        {getUTCDate(evaluationCycleData?.final_manager_feedback) < getUTCDate(new Date()) && (
                          <ExpiredPeriodCardContainer backgroundcolor='#fff'>
                            <div className='card-title'>
                              <h2>Feedback do Gestor - Finalizado</h2>
                            </div>
                            <p>Período de feedback finalizado. Aguarde sobre as próximas etapas!</p>
                            <span>
                              <img src={Completed} alt='' />
                            </span>
                          </ExpiredPeriodCardContainer>
                        )}
                        <CardContainer to={routes.AREA_PDI} backgroundcolor='#fff'>
                          <div className='card-title'>
                            <h2>PDI</h2>
                            <MdKeyboardArrowRight size={24} color='#411564' />
                          </div>
                          <div className='pdi-chart-container'>
                            <Doughnut data={chartData} width={300} height={300} options={chartOptions} />
                          </div>
                        </CardContainer>
                        <CardContainer to={routes.RESULTS} onClick={returnAllResultsHandler} backgroundcolor='#fff'>
                          <div className='card-title'>
                            <h2>Resultados</h2>
                            <MdKeyboardArrowRight size={24} color='#411564' />
                          </div>
                          <div className='pdi-chart-container'>
                            <Doughnut data={resultChartData} width={300} height={300} options={chartOptions} />
                          </div>
                        </CardContainer>
                      </>
                    )}
                  </div>
                </UpContainer>
              </section>
            </>
          )}

          <section>
            <UpContainer>
              <div>
                <CardContainer to={routes.CONTINUOUS_FEEDBACK} backgroundcolor='#fff' onClick={() => setProcess('feedback')}>
                  <div className='card-title'>
                    <h2>Feedback Contínuo</h2>
                    <MdKeyboardArrowRight size={24} color='#411564' />
                  </div>
                  <p>
                    Essa área é pra você registrar os acontecimentos do dia a dia, e ter um histórico de Feedbacks "Positivos" ou
                    de "Desenvolvimentos", bem como de ações sugeridas
                  </p>
                  <span>
                    <img src={FeedbackContinuo} alt='' style={{ width: '180px !important' }} />
                  </span>
                </CardContainer>
                <CardContainer
                  to={`${routes.EMPLOYEE_TIMELINE}/${profile.id}`}
                  backgroundcolor='#fff'
                  onClick={() => setProcess('feedback')}>
                  <div className='card-title'>
                    <h2>Feedbacks Recebidos</h2>
                    <MdKeyboardArrowRight size={24} color='#411564' />
                  </div>
                  <p>Acompanhe aqui os Feedbacks que você recebeu de seu líder.</p>
                  <span>
                    <img src={MyFeedback} alt='' width={210} />
                  </span>
                </CardContainer>
                <CalendarContainer backgroundcolor='#fff'>
                  <div className='card-title'>
                    <h2>Período Autoavaliação</h2>
                  </div>
                  <>
                    <Calendar
                      value={[
                        getUTCDate(evaluationCycleData?.initial_employee_evaluation),
                        getUTCDate(evaluationCycleData?.final_employee_evaluation),
                      ]}
                      onChange={() => null}
                      locale='pt-BR'
                    />
                  </>
                </CalendarContainer>
                {roles?.includes('evaluator') && (
                  <>
                    <CalendarContainer backgroundcolor='#fff'>
                      <div className='card-title'>
                        <h2>Período Avaliação do Gestor</h2>
                      </div>
                      <>
                        <Calendar
                          value={[
                            getUTCDate(evaluationCycleData?.initial_manager_evaluation),
                            getUTCDate(evaluationCycleData?.final_manager_evaluation),
                          ]}
                          onChange={() => null}
                          locale='pt-BR'
                        />
                      </>
                    </CalendarContainer>
                    <CalendarContainer backgroundcolor='#fff'>
                      <div className='card-title'>
                        <h2>Período Feedback do Gestor</h2>
                      </div>
                      <>
                        <Calendar
                          value={[
                            getUTCDate(evaluationCycleData?.initial_manager_feedback),
                            getUTCDate(evaluationCycleData?.final_manager_feedback),
                          ]}
                          onChange={() => null}
                          locale='pt-BR'
                        />
                      </>
                    </CalendarContainer>
                  </>
                )}
              </div>
            </UpContainer>
          </section>
        </>
      ) : (
        <>
          <UpContainer>
            <LoadingCard />
          </UpContainer>
          <UpContainer>
            <div>
              <LoadingCard />
              <LoadingCard />
              <LoadingCard />
            </div>
          </UpContainer>
        </>
      )}
      <SweetAlert
        title='Não foi possível carregar o conteúdo'
        type='error'
        showCloseButton
        show={show}
        focusConfirmBtn
        showConfirm
        allowEscape
        onConfirm={() => setShow(false)}
      />
    </MainContainer>
  );
}

export default memo(Dashboard);
