import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  label {
    color: #333;
    margin-bottom: 5px;
  }
`;

export const Input = styled.textarea`
  background: rgba(155, 155, 155, 0.2);
  border: 0;
  border-radius: 4px;
  max-height: 120px;
  padding: 15px;
  color: #333;
  margin: 0 0 10px;
  font: 15px 'Roboto', sans-serif;
  text-align: justify;
  display: block;

  &::placeholder {
    color: #555;
  }

  &:focus {
    background: rgba(200, 200, 200, 0.8);
  }

  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-track {
    background: rgba(155, 155, 155, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }
`;
