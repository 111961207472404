export const Types = {
  TOGGLE_MODAL_REQUEST: '@modal/TOGGLE_MODAL_REQUEST',
  DESTROY_MODAL_REQUEST: '@modal/DESTROY_MODAL_REQUEST',
};

export function toggleModalRequest(data) {
  return {
    type: Types.TOGGLE_MODAL_REQUEST,
    payload: data,
  };
}
