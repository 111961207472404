import React, { memo } from 'react';

import Skeleton from '~/components/Skeleton';

import { Container, FormContainer, OptionsContainer, SkillContainer } from './styles';

function LoadingTest() {
  return (
    <Container>
      <FormContainer>
        <OptionsContainer>
          <div className='options-skill-title'>Carregando...</div>
          <Skeleton className='button-skeleton' />
          <Skeleton className='button-skeleton' />
          <Skeleton className='button-skeleton' />
        </OptionsContainer>
        <SkillContainer>
          <Skeleton className='skill-skeleton' />
        </SkillContainer>
      </FormContainer>
    </Container>
  );
}

export default memo(LoadingTest);
