import styled from 'styled-components';

export const Wrapper = styled.div`
  display: block;
  position: relative;
  min-height: 100vh;
  height: 100%;
  background: var(--color-background);
  overflow: auto;
  padding-top: 74px;
`;

export const ChildrenContainer = styled.div`
  display: block;
  position: relative;
  overflow: auto;
  height: calc(100% - 30px);
  background: var(--color-background);
`;

export const BlankBreadcrumbContainer = styled.div`
  height: 30px;
`;
