import styled from 'styled-components';

export const Container = styled.div`
  button {
    border: none;
    background: ${(props) => (props.toggled ? '#00995d' : '#999')};
    padding: 13px 15px;
    color: ${(props) => (props.toggled ? '#fff' : '#555')};
    box-shadow: ${(props) => (props.toggled ? 'inset 0 0 10px #000000' : 'none')};
    border-radius: 4px;
    width: 100%;
  }
`;
