import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { sortObjectByKey, groupBy } from '~/functions';
import api from '~/services/api';
import { updateBreadcrumb } from '~/store/modules/user/actions';

import {
  Container,
  ListHeader,
  DepartmentContainer,
  DepartmentHeader,
  EmployeeListContainer,
  EmployeeInformation,
} from './styles';

export default function Unfinished() {
  const dispatch = useDispatch();
  const cycle = useSelector((state) => state.cycle);

  const { option } = useParams();
  const [departmentList, setDepartmentList] = useState([]);
  const [unfinishedList, setUnfinishedList] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { name: 'Início', path: '/' },
        { name: 'Configurações', path: '/sys-config' },
        { name: 'Ciclo de Avaliação', path: '/evaluation-cycle' },
        { name: 'Acompanhe os Processos deste Ciclo de Avaliação', path: '/follow' },
        { name: 'Lista de Completos/Incompletos', path: '/unfinished' },
      ]),
    );
  }, [dispatch]);

  useEffect(() => {
    async function getData() {
      const { data: departments } = await api.get('departments');
      setDepartmentList(departments);

      const { data: employeesData } = await api.get(`dashboardsummaries/${cycle.id}`);

      const totalUnfinished = employeesData.map((employee) => ({
        id: employee.id,
        name: employee.name,
        department: employee.departments?.name,
        __meta__: employee.__meta__,
      }));

      switch (option) {
        case 'auto-evaluation':
          setSelectedOption('Auto Avaliação');
          break;
        case 'leader-evaluation':
          setSelectedOption('Avaliação do Gestor');
          break;
        case 'feedback':
          setSelectedOption('Feedback do Gestor');
          break;
        case 'feedback-received':
          setSelectedOption('Colaboradores que confirmaram o recebimento do Feedback');
          break;
        case 'pdi-received':
          setSelectedOption('Colaboradores que receberam planos de desenvolvimento individual');
          break;
        default:
          break;
      }

      setUnfinishedList(sortObjectByKey(groupBy(totalUnfinished, 'department')));
    }

    getData();
  }, [cycle.id, option]);

  return (
    <Container>
      {unfinishedList && (
        <>
          <ListHeader>
            <h2>{selectedOption}</h2>
            <div>Setores {departmentList.length}</div>
          </ListHeader>
          <div>
            {Object.keys(unfinishedList).map((department) => (
              <DepartmentContainer key={unfinishedList[department][0].id}>
                <DepartmentHeader>
                  <h4>{department}</h4>
                </DepartmentHeader>
                <EmployeeListContainer>
                  {unfinishedList[department].map((employee) => {
                    switch (option) {
                      case 'auto-evaluation':
                        return (
                          <EmployeeInformation
                            key={employee.id}
                            finished={(
                              employee.__meta__.employeeEvaluationAnswers !== 0 &&
                              employee.__meta__.employeeEvaluationJustificatives !== 0
                            ).toString()}>
                            <div>{employee.name}</div>
                            <div>Comportamentos restantes: {employee.__meta__.employeeEvaluationAnswers}</div>
                            <div>Justificativas restantes: {employee.__meta__.employeeEvaluationJustificatives}</div>
                          </EmployeeInformation>
                        );
                      case 'leader-evaluation':
                        return (
                          <EmployeeInformation
                            key={employee.id}
                            finished={(
                              employee.__meta__.leaderEvaluationAnswers !== 0 &&
                              employee.__meta__.leaderEvaluationJustificatives !== 0
                            ).toString()}>
                            <div>{employee.name}</div>
                            <div>Comportamentos restantes: {employee.__meta__.leaderEvaluationAnswers}</div>
                            <div>Justificativas restantes: {employee.__meta__.leaderEvaluationJustificatives}</div>
                          </EmployeeInformation>
                        );

                      case 'feedback':
                        return (
                          <EmployeeInformation
                            key={employee.id}
                            finished={(employee.__meta__.leaderEvaluationComments !== 0).toString()}>
                            <div>{employee.name}</div>
                            <div>Feedback restantes: {employee.__meta__.leaderEvaluationComments}</div>
                          </EmployeeInformation>
                        );

                      case 'feedback-received':
                        return (
                          <EmployeeInformation
                            key={employee.id}
                            finished={(employee.__meta__.employeeConfirmFeedback !== 0).toString()}>
                            <div>{employee.name}</div>
                            <div className='status-column'>
                              {employee.__meta__.employeeConfirmFeedback !== 0
                                ? 'Ainda não recebeu o Feedback do Gestor'
                                : 'Já confirmou o Feedback'}
                            </div>
                          </EmployeeInformation>
                        );
                      case 'pdi-received':
                        return (
                          <EmployeeInformation
                            key={employee.id}
                            finished={(employee.__meta__.employeeReceivedPdi === 0).toString()}>
                            <div>{employee.name}</div>
                            <div className='status-column'>
                              {employee.__meta__.employeeReceivedPdi === 0
                                ? 'Ainda não recebeu nenhum plano de desenvolvimento'
                                : 'Já recebeu algum plano de desenvolvimento'}
                            </div>
                          </EmployeeInformation>
                        );

                      default:
                        return null;
                    }
                  })}
                </EmployeeListContainer>
              </DepartmentContainer>
            ))}
          </div>
          <div />
        </>
      )}
    </Container>
  );
}
