import { Link as RootLinkComponent } from 'react-router-dom';

import { darken } from 'polished';
import styled from 'styled-components';

export const Link = styled(RootLinkComponent)`
  color: ${(props) => props.color};
  background-color: ${(props) => props.background};
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  padding: 0 7px;
  font-size: 1.4rem;

  &:hover {
    background: ${(props) => `${darken(0.08, props.background)}`};
  }
`;
