import api from '~/services/api';

const mountOptionList = async (entity, setFunction) => {
  const { data } = await api.get(`${entity}`);
  const realData = data.data ?? data;

  const sortedItems = realData?.sort((itemA, itemB) => {
    if (itemA.name) {
      return itemA.name.localeCompare(itemB.name, 'pt-BR');
    }
    if (itemA.action) {
      return itemA.action.localeCompare(itemB.action, 'pt-BR');
    }
    if (itemA.description) {
      return itemA.description.localeCompare(itemB.description, 'pt-BR');
    }

    return {};
  });

  if (sortedItems) {
    setFunction([]);
    if (entity === 'ratingscales') {
      const formattedItems = sortedItems.map((item) => ({
        id: item.id,
        title: item.name ?? item.description,
        value: Number(item.score),
      }));

      setFunction(formattedItems);
    } else {
      const formattedItems = sortedItems.map((item) => ({
        value: item.id,
        label: item.name ?? item.action ?? item.description,
        description: item.description,
        id: item.id,
      }));

      setFunction(formattedItems);
    }
  }
};

export default mountOptionList;
