import React, { useEffect, memo, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { Scope } from '@unform/core';
import { Form } from '@unform/web';

import { ButtonNormal, Rating, TextBox, MainContainer } from '~/components';
import { LoadingTest } from '~/components/Shimmer';
import { dynamicSort, toCamelCase, mountOptionList } from '~/functions';
import api from '~/services/api';
import { updateBreadcrumb } from '~/store/modules/user/actions';

import {
  SkillContainer,
  AnswerContainer,
  RatingContainer,
  FormContainer,
  OptionsContainer,
  MobileInstructionContainer,
  DescriptionContainer,
} from './styles';

/*

TODO:
- Inserir botão para finalizar avaliação * talvez não será preciso por agora consiguimos acompanhar o preenchimento
- Inserir no Description container quantos itens faltam para preencher

*/

function EmployeeEvaluation() {
  const profile = useSelector((state) => state.user.profile);
  const cycle = useSelector((state) => state.cycle);

  const [employee, setEmployee] = useState({});
  const [answerList, setAnswerList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [optionList, setOptionList] = useState([]);
  const [behaviorList, setBehaviorList] = useState([]);
  const [justificative, setJustificative] = useState([]);
  const [justificativeList, setJustificativeList] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [itemToShow, setItemToShow] = useState(null);
  const [initialData, setInitialData] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { name: 'Início', path: '/' },
        { name: 'Sua Avaliação de Desempenho', path: '/evaluation-answer' },
      ]),
    );
  }, [dispatch]);

  useEffect(() => {
    async function getAnswers() {
      const { data: employeeData } = await api.get(`users/${profile.id}`);
      setEmployee(employeeData);

      const { data } = await api.get(
        `evaluationcycleanswers?evaluation_cycle_id=${cycle.id}&employeeId=${profile.id}&leaderId=${undefined}`,
      );
      const { data: justificativesData } = await api.get(
        `evaluationcyclejustificatives?evaluation_cycle_id=${cycle.id}&employeeId=${profile.id}&leaderId=${undefined}`,
      );
      const answers = data.map((ans) => {
        return {
          behavior_id: ans.behavior_id,
          description: ans.behaviors.description,
          evaluation_answer_id: ans.id,
          skill_id: ans.behaviors.skill_id,
          skill_name: ans.behaviors.skills.name,
          skill_description: ans.behaviors.skills.description,
          user_answer: ans.user_answer,
        };
      });
      setAnswerList(answers);
      answers.forEach((ans) => {
        setBehaviorList((val) => ({
          ...val,
          [ans.evaluation_answer_id]: [`${!ans.user_answer ? null : Number(ans.user_answer)}`],
        }));
      });

      const justificatives = justificativesData.map((ans) => {
        return {
          id: ans.id,
          skill_id: ans.skill_id,
          evaluation_answer_id: ans.id,
          skill_name: ans.skills.name,
          skill_description: ans.skills.description,
          user_justificative: ans.user_justificative,
        };
      });

      setJustificativeList(justificatives);

      justificatives.forEach((ans) => {
        setJustificative((jus) => ({ ...jus, [ans.skill_id]: ans.user_justificative }));
      });

      mountOptionList('ratingscales', setOptionList);

      setLoaded(true);
    }

    getAnswers();
  }, [mountOptionList, profile.id]); // eslint-disable-line

  useEffect(() => {
    const body = document.querySelector('#root');
    if (loaded)
      body.scrollIntoView(
        {
          behavior: 'smooth',
        },
        1000,
      );
  }, [loaded]);

  const removeDuplicates = useCallback(
    (list) => {
      const arrayList = list
        .filter((ele, ind) => ind === list.findIndex((elem) => elem.skill_id === ele.skill_id))
        .sort(dynamicSort('skill_name'));

      if (itemToShow === null && arrayList[0]) {
        setItemToShow(arrayList[0].skill_id);
      }

      return arrayList;
    },
    [itemToShow],
  );

  useEffect(() => {
    let skillComposer = [];
    answerList.forEach((ans) => {
      setInitialData({ values: behaviorList, justificatives: justificative });

      skillComposer = [
        ...skillComposer,
        { skill_id: ans.skill_id, skill_name: ans.skill_name, skill_description: ans.skill_description },
      ];
    });

    setSkillList(removeDuplicates(skillComposer));
  }, [loaded]); //eslint-disable-line

  async function handleSubmit(data) {
    setIsSaving(true);
    const answers = Object.keys(data.values).map((key) => ({
      id: key,
      user_answer: !data.values[key].length ? null : Number(data.values[key]),
      user_finished: !!data.values[key].length,
      employee_response_date: new Date(),
    }));
    const justificatives = Object.keys(data.justificatives).map((key) => ({
      id: justificativeList.find((item) => item.skill_id === key).id,
      user_justificative: data.justificatives[key],
      user_finished: !!data.justificatives[key],
    }));
    const {
      data: { status: statusAnswer },
    } = await api.put(`evaluationcycleanswers/${answers[0].id}`, { data: answers });
    const {
      data: { status: statusJustificative },
    } = await api.put(`evaluationcyclejustificatives/${answers[0].id}`, { data: justificatives });

    if (statusAnswer && statusJustificative) {
      toast.success(`Dados salvos com sucesso`);
    } else {
      toast.error('Algo deu errado, tente salvar novamente! Se persistir o erro, comunique com seu coordenador');
    }

    const indexItemShowing = skillList.findIndex((element) => {
      if (element.skill_id === itemToShow) {
        return true;
      }
      return false;
    });

    if (indexItemShowing + 1 < skillList.length) {
      setItemToShow(skillList[indexItemShowing + 1].skill_id);
    } else {
      setItemToShow(skillList[0].skill_id);
    }
    setIsSaving(false);
  }

  return (
    <MainContainer>
      <DescriptionContainer>
        <h3>{employee.name}</h3>
        <span>{employee?.positions?.description}</span>
      </DescriptionContainer>
      <MobileInstructionContainer>
        <div className='mobile-info'>Clique em cada uma das competências abaixo e responda a todas as questões</div>
      </MobileInstructionContainer>
      {loaded > 0 ? (
        <FormContainer>
          <OptionsContainer>
            <div className='options-skill-title'>Selecione as competências</div>
            {skillList &&
              removeDuplicates(skillList).map((skill) => (
                <button
                  key={skill.skill_id}
                  type='button'
                  className={itemToShow === skill.skill_id ? 'active' : ''}
                  onClick={() => setItemToShow(skill.skill_id)}>
                  {toCamelCase(skill.skill_name.toLowerCase())}
                </button>
              ))}
          </OptionsContainer>
          <Form onSubmit={handleSubmit} initialData={initialData}>
            {skillList &&
              removeDuplicates(skillList).map((skill) => (
                <SkillContainer key={skill.skill_id} show={itemToShow === skill.skill_id}>
                  <div className='skill_name'>
                    <h2>{skill.skill_name}</h2>
                    <span className='description'>{skill.skill_description}</span>
                  </div>
                  {answerList &&
                    answerList.map((answer) => {
                      if (answer.skill_id === skill.skill_id) {
                        return (
                          <AnswerContainer key={answer.evaluation_answer_id}>
                            <h4>{toCamelCase(answer.description.toLowerCase())}</h4>
                            <RatingContainer>
                              <Scope path='values'>
                                <Rating name={answer.evaluation_answer_id} options={optionList} />
                              </Scope>
                            </RatingContainer>
                          </AnswerContainer>
                        );
                      }
                      return null;
                    })}
                  <AnswerContainer>
                    <Scope path='justificatives'>
                      <h4>Justificativa</h4>
                      <TextBox
                        name={`${skill.skill_id}`}
                        rows={2}
                        max-length={2000}
                        placeholder='Insira sua justificativa aqui'
                        required={itemToShow === skill.skill_id}
                      />
                    </Scope>
                  </AnswerContainer>
                  <ButtonNormal
                    text={isSaving ? 'Salvando dados.... Aguarde...' : 'Salvar respostas'}
                    type='submit'
                    disabled={isSaving}
                  />
                </SkillContainer>
              ))}
          </Form>
        </FormContainer>
      ) : (
        <LoadingTest />
      )}
    </MainContainer>
  );
}

export default memo(EmployeeEvaluation);
