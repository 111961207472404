import React, { useEffect, memo, useState, useCallback } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Doughnut } from 'react-chartjs-2';
import { MdEdit, MdVisibility, MdVisibilityOff, MdDelete, MdFingerprint } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { Tooltip } from '@material-ui/core';
import { Form as Unform } from '@unform/web';
import { formatDistance, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { PropTypes } from 'prop-types';

import { MainContainer, Input } from '~/components';
import { isObjectEmpty, formatDate } from '~/functions';
import { useToggle } from '~/hooks';
import api from '~/services/api';
import { toggleModalRequest } from '~/store/modules/modal/actions';
import { updateBreadcrumb } from '~/store/modules/user/actions';

import Form from '../Form';
import { Section, Article, EmployeeSummaryContainer, AddButton, WithoutContinuousFeedbackContainer } from './styles';

function Timeline({
  match: {
    params: { employee_id },
  },
}) {
  const dispatch = useDispatch();
  const [employeeData, setEmployeeData] = useState({});
  const [positiveFeedback, setPositiveFeedback] = useState(0);
  const [developmentFeedback, setDevelopmentFeedback] = useState(0);
  const [initialData, setInitialData] = useState({});
  const [showDeleteConfirm, toggleDeletePopConfirm] = useToggle();
  const [showSuccessModal, toggleSuccessModal] = useToggle();
  const [entityInProcess, setEntityInProcess] = useState({});
  const [refreshData, toggleRefreshData] = useToggle();
  const [showEmployeePasswordConfirm, toggleEmployeePasswordConfirm] = useToggle();
  const [chartData, setChartData] = useState([]);
  const [employeePassword, setEmployeePassword] = useState('');
  const [confirmShowToEmployee, toggleShowToEmployeeConfirmation] = useToggle();

  const chartOptions = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          color: '#fff',
        },
      },
    },
  };

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { name: 'Início', path: '/' },
        { name: `Lista de Colaboradores - Feedback Contínuo`, path: '/continuous-feedback' },
        { name: `Linha do Tempo de Seus Feedbacks`, path: '/timeline' },
      ]),
    );
  }, [dispatch]);

  useEffect(() => {
    async function getData() {
      const { data } = await api.get(`continuousfeedbackareaemployee/${employee_id}`);
      setEmployeeData(data);
      const positives = data?.continuousFeedbacks.filter((continuousFeedback) => continuousFeedback.category === 1);
      setPositiveFeedback(positives);
      const developments = data?.continuousFeedbacks.filter((continuousFeedback) => continuousFeedback.category === 0);
      setDevelopmentFeedback(developments);

      setChartData({
        labels: ['Positivo', 'Desenvolvimento'],
        datasets: [
          {
            label: 'Dados Principais',
            data: [positives.length, developments.length],
            backgroundColor: ['#00995d', '#ed1651'],
            borderWidth: 0,
            offset: 10,
            hoverOffset: 4,
          },
        ],
      });
    }

    if (employee_id) {
      getData();
    }
  }, [employee_id, refreshData]);

  const showModal = useCallback(() => {
    dispatch(
      toggleModalRequest({
        title: 'Cadastro de Feedback Contínuo',
        entity_description: employeeData.name,
        content: () => <Form employeeId={employee_id} initialData={initialData} refreshData={toggleRefreshData} />,
        visible: true,
      }),
    );
  }, [dispatch, employeeData.name, employee_id, initialData, toggleRefreshData]);

  useEffect(() => {
    if (!isObjectEmpty(initialData)) {
      showModal();
    }
  }, [initialData, showModal]);

  const removeContinuousFeedback = async () => {
    try {
      await api.delete(`continuousfeedback/${entityInProcess.id}`);
      setEmployeeData((data) => ({
        ...data,
        continuousFeedbacks: data.continuousFeedbacks.filter((feed) => feed.id !== entityInProcess.id),
      }));

      toggleDeletePopConfirm();
      toggleSuccessModal();
      toggleRefreshData();
    } catch (err) {
      toast.error(`Não foi possível deletar este item! Verifique com o adminsitrador`);
    }
  };

  const verifyEmployeePasswordHandle = async () => {
    try {
      const { data } = await api.post('confirmemployeecontinuousfeedback', {
        employee_id,
        password: employeePassword,
        continuous_feedback_id: entityInProcess.id,
      });

      if (data.status) {
        toggleSuccessModal();
        toggleEmployeePasswordConfirm();
        setEmployeePassword('');
        toggleRefreshData();
      } else {
        toast.error(`Algo não está certo!. Verifique sua senha e tente novamente!`);
        setEmployeePassword('');
      }
    } catch (err) {
      // do something
    }
  };

  const toggleVisibletoEmployeeHandle = async () => {
    try {
      await api.put(`continuousfeedback/${entityInProcess.id}`, { ...entityInProcess });
      toggleShowToEmployeeConfirmation();
      toggleRefreshData();
      toggleSuccessModal();
    } catch (err) {
      toast.error(`Algo não deu certo! Tente novamente, se persistir o erro entre contato com o desenvolvedor.`);
    }
  };

  return (
    <MainContainer>
      {!isObjectEmpty(employeeData) && (
        <>
          <EmployeeSummaryContainer>
            <div className='summary-numbers-container'>
              <h4>Feedback</h4>
              <div>
                <small>Positivo</small>
                <p>{positiveFeedback.length}</p>
              </div>
              <div>
                <small>Desenvolvimento</small>
                <p>{developmentFeedback.length}</p>
              </div>
            </div>
            <div className='development-plans-container'>
              <h3>Uma breve história sobre o(a) {employeeData.name.split(' ')[0]} no Grupo Empresarial Unimed Varginha.</h3>
              <section>
                <div>
                  <p>
                    {employeeData.name.split(' ')[0]} faz parte do nosso quadro de colaboradores{' '}
                    {formatDistance(parseISO(employeeData.admitted_at), new Date(), {
                      addSuffix: true,
                      locale: pt,
                    })}
                    , atualmente está trabalhando no setor de {employeeData.departments?.name.toLowerCase()}, ocupando o cargo de{' '}
                    {employeeData.positions?.description.toLowerCase()}.
                  </p>
                </div>
              </section>
            </div>
            <div className='chart-container'>
              Gráfico
              <div className='pdi-chart-container'>
                <Doughnut data={chartData} width={150} height={150} options={chartOptions} />
              </div>
            </div>
          </EmployeeSummaryContainer>
          <AddButton
            type='button'
            onClick={() => {
              setInitialData({ nothing: Math.random(1000), employee_id, continuousFeedbackDevelopmentPlans: [] });
            }}>
            NOVO
          </AddButton>
        </>
      )}
      <Section id='timeline' show={!employeeData.continuousFeedbacks?.length}>
        {employeeData.continuousFeedbacks &&
          employeeData.continuousFeedbacks.map((continuousFeedback) => (
            <Article headerColor={continuousFeedback.category} key={continuousFeedback.id}>
              <div className='inner'>
                <span className='date'>
                  <span className='day'>
                    {new Date(continuousFeedback.created_at).getDate()}
                    {/* <sup>th</sup> */}
                  </span>
                  <span className='month'>
                    {new Date(continuousFeedback.created_at).toLocaleString('pt-br', { month: 'short' }).replace('.', '')}
                  </span>
                  <span className='year'>{new Date(continuousFeedback.created_at).getFullYear()}</span>
                </span>
                <section className='header-container'>
                  <h5>{continuousFeedback.category === 1 ? 'Feedback Positivo' : 'Feedback de Desenvolvimento'}</h5>
                  <div>
                    {continuousFeedback.employee_confirmed === null && (
                      <>
                        <Tooltip arrow title='Confirmar Feedback'>
                          <div>  
                            <MdFingerprint
                              size={24}
                              color='#fff'
                              onClick={() => {
                                setEntityInProcess({ id: continuousFeedback.id });
                                toggleEmployeePasswordConfirm();
                              }}
                            />
                          </div>
                        </Tooltip>

                        <Tooltip arrow title='Editar'>
                          <div>
                            <MdEdit
                              onClick={() => {
                                setInitialData({
                                  ...continuousFeedback,
                                  nothing: Math.random(1000),
                                  category: [continuousFeedback.category.toString()],
                                  visible_to_employee: [Number(continuousFeedback.visible_to_employee).toString()],
                                });
                              }}
                              size={24}
                            />
                          </div>
                        </Tooltip>
                        <Tooltip arrow title='Excluir'>
                          <div>
                            <MdDelete
                              color='#fff'
                              onClick={() => {
                                setEntityInProcess({ id: continuousFeedback.id });
                                toggleDeletePopConfirm();
                              }}
                              size={24}
                            />
                          </div>
                        </Tooltip>
                      </>
                    )}
                    {continuousFeedback.visible_to_employee ? (
                      <Tooltip arrow title='O colaborador TEM acesso a este feedback'>
                        <div>
                          <MdVisibility
                            color='#b1d34b'
                            size={24}
                            onClick={() => {
                              setEntityInProcess({ id: continuousFeedback.id, visible_to_employee: [0] });
                              toggleShowToEmployeeConfirmation();
                            }}
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip arrow title='O colaborador NÃO tem acesso a este feedback'>
                        <div>
                          <MdVisibilityOff
                            color='#333'
                            size={24}
                            onClick={() => {
                              setEntityInProcess({ id: continuousFeedback.id, visible_to_employee: [1] });
                              toggleShowToEmployeeConfirmation();
                            }}
                          />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </section>
                <p>
                  <small>Descrição</small>
                  {continuousFeedback.description}
                </p>
                {continuousFeedback.continuousFeedbackDevelopmentPlans &&
                  continuousFeedback.continuousFeedbackDevelopmentPlans.map((actionPlan) => (
                    <p key={actionPlan.id}>
                      <small>Plano de Ação</small>
                      {actionPlan.action}
                      <small>
                        {formatDate(actionPlan.initial_date)} a {formatDate(actionPlan.final_date)}
                      </small>
                    </p>
                  ))}
              </div>
            </Article>
          ))}
      </Section>
      {!employeeData.continuousFeedbacks?.length && (
        <WithoutContinuousFeedbackContainer>
          {employeeData.continuousFeedbacks && <div>Você ainda não cadastrou feedbacks contínuos para este colaborador</div>}
        </WithoutContinuousFeedbackContainer>
      )}

      <SweetAlert
        confirmBtnText='Excluir'
        cancelBtnText='Cancelar'
        title='Excluir item?'
        onConfirm={removeContinuousFeedback}
        onCancel={toggleDeletePopConfirm}
        showCancel
        show={showDeleteConfirm}
        confirmBtnCssClass='delete-confirm-button'
        cancelBtnCssClass='delete-cancel-button'
      />

      <SweetAlert
        confirmBtnText='Sim'
        cancelBtnText='Cancelar'
        title='Deseja alterar visualização deste feedback?'
        onConfirm={toggleVisibletoEmployeeHandle}
        onCancel={toggleShowToEmployeeConfirmation}
        showCancel
        show={confirmShowToEmployee}
        confirmBtnCssClass='delete-confirm-button'
        cancelBtnCssClass='delete-cancel-button'
      />

      <SweetAlert
        confirmBtnText='Confirmar Feedback'
        cancelBtnText='Cancelar'
        title='Peça ao colaborador para inserir a senha:'
        onConfirm={verifyEmployeePasswordHandle}
        onCancel={toggleEmployeePasswordConfirm}
        showCancel
        show={showEmployeePasswordConfirm}
        confirmBtnCssClass='delete-confirm-button'
        cancelBtnCssClass='delete-cancel-button'>
        <Unform>
          <Input
            name='employeePassword'
            type='password'
            defaultValue={employeePassword}
            onChange={(e) => setEmployeePassword(e.target.value)}
            required
          />
        </Unform>
      </SweetAlert>

      <SweetAlert
        title='Operação realizada com sucesso'
        success
        confirmBtnCssClass='delete-confirm-button'
        onConfirm={toggleSuccessModal}
        show={showSuccessModal}
      />
    </MainContainer>
  );
}

Timeline.propTypes = {
  match: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
};

Timeline.defaultProps = {
  match: {},
};

export default memo(Timeline);
