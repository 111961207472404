import React, { useRef, useEffect, memo, useState } from 'react';

import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import { Input as InputElement, Container } from './styles';

function TextArea({ name, label, className, required, rows: minRows, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue = '', registerField, error } = useField(name);
  const [rows] = useState(minRows);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container className={className}>
      {label && <label htmlFor={fieldName}>{label}</label>}
      <InputElement
        id={fieldName}
        name={fieldName}
        ref={inputRef}
        required={required && 'required'}
        defaultValue={defaultValue}
        rows={rows}
        {...rest}
      />
      {error && <span className='input-error'>{error}</span>}
    </Container>
  );
}

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TextArea.defaultProps = {
  label: null,
  className: null,
  required: false,
  rows: 3,
};

export default memo(TextArea);
