import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100% -20px);
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  @media (max-width: 1220px) {
    padding: 0 20px;
  }

  @media (max-width: 768px) {
    padding: 0 15px;
    max-width: 100%;
  }

  ol.organizational-chart {
    display: flex;
    flex-direction: column;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  align-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 20px;

    a {
      margin-left: 0 !important;
    }
  }
`;

export const DeparmentContainer = styled.div`
  display: flex;
  flex: 1 1 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  height: calc(100vh -80px);

  form {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 10px;
  }

  span.choose-cycle {
    display: flex;
    text-align: center;
    align-items: center;
    align-content: center;
    width: 100%;
  }
`;
