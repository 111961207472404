import React, { useRef, memo } from 'react';
import { MdSearch } from 'react-icons/md';

import { Form } from '@unform/web';
import PropTypes from 'prop-types';

import Input from '~/components/Input';

import { Container } from './styles';

function SearchPanel({ handleSearch }) {
  const formRef = useRef(null);

  const handleSubmit = () => {
    formRef.current.submitForm();
  };

  return (
    <Container>
      <Form onSubmit={handleSearch} ref={formRef}>
        <Input name='searchSentence' />
      </Form>
      <button type='button' onClick={() => handleSubmit()}>
        <MdSearch size='24' color='#fff' />
      </button>
    </Container>
  );
}

SearchPanel.propTypes = {
  handleSearch: PropTypes.func.isRequired,
};

export default memo(SearchPanel);
