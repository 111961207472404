import Ws from '@adonisjs/websocket-client';

// const { token } = JSON.parse(JSON.parse(localStorage.getItem('persist:crescer')).auth);

const protocol = 'ws'; // process.env.NODE_ENV === 'development' ? 'ws' : 'wss'; // neste caso estou usando o mesmo mas seria wss
const url = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_WS_URL : process.env.REACT_APP_WS_URL_PRODUCTION;
const ws = Ws(`${protocol}://${url}`);
// ws.withApiToken(token).connect();
ws.connect();

export default ws;
