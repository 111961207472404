import React, { memo } from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';

function Switch({ accentColor, backgroundColor }) {
  return (
    <Container accentColor={accentColor} backgroundColor={backgroundColor}>
      <input type='checkbox' name='autoplay' id='autoplay' />
      <span className='slider round' />
    </Container>
  );
}

Switch.propTypes = {
  accentColor: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
};

export default memo(Switch);
