import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 0 20px 0;

  @media (max-width: 768px) {
    max-width: 100%;
    /* padding: 0 15px 15px 15px; */
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 210px;

  @media (max-width: 768px) {
    min-width: 100%;
  }

  .button-skeleton {
    border-radius: 5px;
    min-height: 38px;
    margin: 0 10px 10px 0;

    @media (max-width: 768px) {
      margin: 0 0 10px 0;
    }
  }

  div.options-skill-title {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 5px 5px;
    background: rgba(196, 203, 207, 1);
    margin: 0 10px 12px 0;
    color: #222;
    font-size: 15px;
    border-radius: 4px;

    &::before {
      content: '';
      position: absolute;
      top: 28px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid rgba(196, 203, 207, 1);
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const SkillContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 5px;
  border-radius: 5px;

  .skill-skeleton {
    border-radius: 5px;
    height: 400px;
    width: 100%;
  }
`;
