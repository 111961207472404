import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100%;

  div.card-skeleton {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
`;
