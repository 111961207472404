export const headers = [
  {
    title: 'Descrição',
    width: '50%',
    align: 'left',
    columnName: 'description',
    dataType: String,
  },
  {
    title: 'Valor Inicial',
    width: '20%',
    align: 'left',
    columnName: 'initial_value',
    dataType: Number,
  },
  {
    title: 'Valor Final',
    width: '20%',
    align: 'left',
    columnName: 'final_value',
    dataType: Number,
  },
];
