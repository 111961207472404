import React, { memo, useState, useEffect, useRef } from 'react';
import { MdSave, MdCancel } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Form } from '@unform/web';

import { ButtonNormal, Dialog, Input, Pagination, Panel, SearchPanel, Table, MainContainer } from '~/components';
import { LoadingTable } from '~/components/Shimmer';
import getTotalItemsPerPage from '~/functions/getTotalItemsPerPage';
import api from '~/services/api';
import { updateBreadcrumb } from '~/store/modules/user/actions';

import { FormContainer, ButtonContainer } from './styles';
import { headers } from './tableHeaders';

function Questions() {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(getTotalItemsPerPage(198, 34));
  const [lastPage, setLastPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [entityList, setEntityList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [initialData, setInitialData] = useState({});
  const [entityInProcess, setEntityInProcess] = useState({});
  const [process, setProcess] = useState('add');

  const profile = useSelector((state) => state.user.profile);
  const formRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    async function loadEntitiesData() {
      dispatch(
        updateBreadcrumb([
          { name: 'Início', path: '/' },
          { name: 'Configurações', path: '/sys-config' },
          { name: 'Perguntas', path: '/questions' },
        ]),
      );
      const {
        data: { data, lastPage: last, total: totalItems },
      } = await api.get(`questions?page=${page}&itemsPerPage=${itemsPerPage}`);
      setEntityList(data);
      setLastPage(parseInt(last, 10));
      setTotal(parseInt(totalItems, 10));
      setIsLoading(false);
    }

    loadEntitiesData();
  }, [dispatch, itemsPerPage, page]);

  useEffect(() => {
    setItemsPerPage(showForm ? 10 : getTotalItemsPerPage(198, 34));
  }, [showForm]);

  const loadEntityData = async (entityData) => {
    const { data } = await api.get(`questions/${entityData.id}`);
    formRef.current.setData(data);

    setInitialData(data);
  };

  function clearForm() {
    formRef.current.setFieldValue('id', null);
    formRef.current.reset();
  }

  async function removeEntity() {
    if (entityInProcess.id) {
      const response = await api.delete(`questions/${entityInProcess.id}`);
      toast.success(`${response.statusText}: Pergunta excluído com sucesso`);
      const userListWithoutDeleted = entityList.filter((entity) => entity.id !== entityInProcess.id);
      setEntityList(userListWithoutDeleted);
      setShowDialog(false);
    }
  }

  const onSubmit = async (data, { reset }) => {
    if (process === 'edit') {
      const { status, statusText } = await api.put(`questions/${data.id}`, {
        ...data,
        company_id: profile.company_id,
      });
      if (status === 200) {
        toast.success(`${statusText}: Dados atualizados com sucesso`);
      } else {
        toast.error(`${statusText}: Erro ao atualizar dados. Code: ${status}`);
      }
    } else {
      const entityData = {
        ...data,
        company_id: profile.company_id,
      };

      const { status, statusText, ...rest } = await api.post(`questions`, entityData).catch((err) => err);
      if (status === 201) {
        toast.success(`${statusText}: Dados cadastrados com sucesso`);
        setEntityList((entities) => [...entities, rest.data]);
        reset();
      } else {
        toast.error(`${rest.response.status}: ${rest.response.statusText}. Erro ao cadastrar dados`);
      }
    }
  };

  const handleSearch = async ({ searchSentence }) => {
    setIsLoading(true);
    const {
      data: { data, total: totalReturned },
    } = await api.get(
      `questions?page=${page}&itemsPerPage=${itemsPerPage}&searchSentence=${searchSentence}&searchBy=description`,
    );

    setEntityList(data);
    setTotal(parseInt(totalReturned, 10));
    setIsLoading(false);
  };

  return (
    <MainContainer>
      <SearchPanel handleSearch={handleSearch} />
      <FormContainer showForm={showForm}>
        <Form ref={formRef} onSubmit={onSubmit} className='form' initialData={initialData}>
          <div className='hidden-input'>
            <Input name='id' type='hidden' />
          </div>
          <Input name='description' placeholder='Descrição' label='Descrição' />
          <div className='hidden-input'>
            <Input name='company_id' type='hidden' />
          </div>
          <div />
          <div />
          <ButtonContainer>
            <ButtonNormal color='#fff' background='#00995d' style={{ width: '100px' }} type='submit'>
              <MdSave size={20} color='#ffffff' /> Salvar
            </ButtonNormal>
            <ButtonNormal
              color='#fff'
              background='#ed1651'
              style={{ width: '100px' }}
              onClick={() => {
                setShowForm(false);
                clearForm();
              }}>
              <MdCancel size={20} color='#ffffff' /> Cancelar
            </ButtonNormal>
          </ButtonContainer>
        </Form>
      </FormContainer>
      {isLoading ? (
        <LoadingTable />
      ) : (
        !showForm && (
          <Panel>
            <Table
              headers={headers}
              data={entityList}
              editing={showForm}
              actions={{
                showForm: () => setShowForm(true),
                setProcess,
                edit: loadEntityData,
                remove: { setEntityInProcess, setShowDialog },
                clear: clearForm,
                reset: () => {},
                add: true,
                search: true,
                buttons: ['edit', 'delete'],
                setEntityInProcess,
              }}
            />
          </Panel>
        )
      )}
      <Dialog
        open={showDialog}
        setOpen={setShowDialog}
        confirmAction={removeEntity}
        title='Confirma a exclusão da pergunta: '
        entityData={entityInProcess}
        actionName='Excluir'
        actionText='A exclusão dessa pergunta acarretará na exclusão de todos os dados já cadastrados para ele. Tenha certeza que deseja excluí-la.'
      />
      <Pagination page={page} setPage={setPage} lastPage={lastPage} total={total} />
    </MainContainer>
  );
}

export default memo(Questions);
