import styled from 'styled-components';

export const Container = styled.div`
  .form {
    margin-bottom: 15px;
    display: flex;
    gap: 15px;
  }
`;

export const AreaContainer = styled.div`
  border-bottom: 1px dotted #ccc;
  margin-bottom: 15px;

  h3 {
    color: #5b5c65;
    border-bottom: 1px dotted #ccc;
    font-size: 1.6rem;
  }

  > div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(100% / 4), 1fr));
    grid-template-rows: repeat(minmax(180px, 180px));
    grid-row-gap: 10px;
    grid-column-gap: 10px;
  }
`;

export const FeedbackContainer = styled.div`
  background: #fff;
  color: #333;
  margin: 15px 0;
  padding: 5px 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 190px;

  span.tag {
    color: #333;
    border-radius: 8px;
    margin: 5px 0;
    font-size: 1.2rem;
    font-weight: 700;
  }

  article {
    text-align: justify;
    font-weight: 300;
  }

  .feedback-header {
    margin-top: 4px;
    margin-bottom: 4px;
    background: ${(props) => (props.category === 1 ? '#00995d' : '#ed1651')};
    color: #fff;
    padding: 5px 8px;
    border-radius: 5px;
  }
`;
