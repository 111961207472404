/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef, useEffect, memo } from 'react';

import { DatePicker, KeyboardDatePicker, DateTimePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import { useField } from '@unform/core';
import { format, parse } from 'date-fns';
import PropTypes from 'prop-types';

import { getUTCDate } from '~/functions';

import { Container } from './styles';

const DatePickerMaterial = ({ name, dateFormat, label, type, ...rest }) => {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [selected, setSelected] = useState(defaultValue);

  useEffect(() => {
    setSelected(defaultValue ? getUTCDate(defaultValue) : null);
  }, [defaultValue]);

  function parseSelectValue(selectValue) {
    try {
      if (selectValue.length === 4) {
        selectValue = `01/01/${selectValue}`;
      } else if (selectValue.length === 7) {
        selectValue = `01/${selectValue}`;
      }
      if (selectValue.length === 0) return undefined;
      if (type === 'keyboardDateTime' || type === 'dateTime') {
        return format(parse(selectValue, 'dd/MM/yyyy HH:mm', new Date()), "yyyy-MM-dd'T'HH:mm:ss");
      }
      return format(parse(selectValue, 'dd/MM/yyyy', new Date()), "yyyy-MM-dd'T'HH:mm:ss");
    } catch (err) {
      return undefined;
    }
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'value',
      getValue: (pickerRef) => parseSelectValue(pickerRef.value),
      clearValue: (pickerRef) => {
        pickerRef.value = null;
        // pickerRef.clear();
      },
    });
  }, [ref.current, fieldName]); // eslint-disable-line

  function focusInput() {
    ref.current.focus();
  }

  const pickerType = {
    date() {
      return (
        <DatePicker
          inputRef={ref}
          name={fieldName}
          format={dateFormat}
          placeholder='25/12/2019'
          locale='pt-BR'
          animateYearScrolling={false}
          value={selected}
          selected={selected}
          onChange={(date) => setSelected(date)}
          onClose={() => focusInput()}
          InputProps={{
            disableUnderline: true,
          }}
          invalidDateMessage='Data inválida'
          {...rest}
        />
      );
    },
    dateTime() {
      return (
        <DateTimePicker
          inputRef={ref}
          name={fieldName}
          format={dateFormat}
          placeholder='25/12/2019 13:00'
          locale='pt-BR'
          animateYearScrolling={false}
          value={selected}
          selected={selected}
          onChange={(date) => setSelected(date)}
          onClose={() => focusInput()}
          InputProps={{
            disableUnderline: true,
          }}
          invalidDateMessage='Data inválida'
          {...rest}
        />
      );
    },
    keyboardDate() {
      return (
        <KeyboardDatePicker
          inputRef={ref}
          name={fieldName}
          format={dateFormat}
          placeholder='25/12/2019'
          locale='pt-BR'
          animateYearScrolling={false}
          value={selected}
          selected={selected}
          onChange={(date) => setSelected(date)}
          onClose={() => focusInput()}
          InputProps={{
            disableUnderline: true,
          }}
          invalidDateMessage='Data inválida'
          {...rest}
        />
      );
    },
    keyboardDateTime() {
      return (
        <KeyboardDateTimePicker
          inputRef={ref}
          name={fieldName}
          format={dateFormat}
          placeholder='25/12/2019 13:00'
          locale='pt-BR'
          animateYearScrolling={false}
          value={selected}
          selected={selected}
          onChange={(date) => setSelected(date)}
          onClose={() => focusInput()}
          InputProps={{
            disableUnderline: true,
          }}
          invalidDateMessage='Data inválida'
          {...rest}
        />
      );
    },
  };

  return (
    <Container>
      {label && <label htmlFor={name}>{label}</label>}
      {pickerType[type]()}
      {error && <span>{error}</span>}
    </Container>
  );
};

DatePickerMaterial.propTypes = {
  name: PropTypes.string.isRequired,
  dateFormat: PropTypes.string,
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
};

DatePickerMaterial.defaultProps = {
  dateFormat: 'dd/MM/yyyy',
  label: '',
};

export default memo(DatePickerMaterial);
