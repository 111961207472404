import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  height: 100%;

  @media (max-width: 1224px) {
    padding: 0 15px;
  }

  @media (max-width: 1200px) {
    padding: 0 20px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 0 15px;
  }

  .delete-confirm-button {
    padding: 10px 25px;
    border-radius: 5px;
    box-shadow: none !important;
    background: #00995d;
    color: #fff;

    &:hover {
      opacity: 0.8;
    }
  }

  .delete-cancel-button {
    color: #ed1651;
    border-bottom: 1px dotted #ed1651;
    margin-right: 25px !important;

    &:hover {
      opacity: 0.8;
    }
  }

  .sweet-alert {
    h2 {
      font-size: 2rem !important;
      color: #333 !important;
      padding-top: 10px !important;
    }
  }
`;
