import styled from 'styled-components';

export const FormContainer = styled.div`
  height: ${(props) => (props.showForm ? '100vh' : '0')};
  opacity: ${(props) => (props.showForm ? '1' : '0')};
  transition: height 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 2s ease;

  form {
    margin: 10px 0;
    padding-bottom: 30px;
    display: ${(props) => (props.showForm ? 'flex' : 'none')};
    flex-direction: column;
    gap: 10px;

    .first-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;
    }

    .hidden-input {
      display: none;
    }
    .hidden-input:target {
      display: block;
    }

    div {
      &:nth-child(9) {
        grid-column: span 3;
        grid-row: span 3;
      }
    }

    .sub-title-group {
      background: rgba(255, 255, 255, 0.1);
      border-left: 3px solid #0a5f55;
      padding: 10px;
      display: flex;
      align-items: center;
      grid-column: span 3;
      grid-row: span 3;
      /* border-radius: 2px; */
    }

    .description-group {
      padding: 2.5px 10px;
      display: flex;
      align-items: center;
      grid-column: span 3;
      grid-row: span 3;
      border-radius: 2px;
      border-bottom: 1px solid #888;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;
