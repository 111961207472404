export const headers = [
  {
    title: 'Ação',
    width: '20%',
    align: 'left',
    columnName: 'action',
    dataType: String,
  },
  {
    title: 'Descrição',
    width: '60%',
    align: 'left',
    columnName: 'description',
    dataType: String,
  },
  {
    title: 'Ativo',
    width: '10%',
    align: 'left',
    columnName: 'active',
    dataType: Boolean,
  },
];
