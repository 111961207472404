import styled from 'styled-components';

export const FormContainer = styled.div`
  height: ${(props) => (props.showForm ? '100vh' : '0')};
  opacity: ${(props) => (props.showForm ? '1' : '0')};
  transition: height 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 2s ease;

  form {
    margin: 10px 0;
    display: ${(props) => (props.showForm ? 'grid' : 'none')};

    grid-template-columns: 100%;
    grid-template-areas: 'form' 'table';

    grid-template-columns: repeat(3, 1fr);

    grid-gap: 10px;

    .hidden-input {
      display: none;
    }
    .hidden-input:target {
      display: block;
    }

    div {
      &:nth-child(23) {
        grid-column: span 3;
        grid-row: span 3;
      }
    }

    .sub-title-group {
      background: rgba(255, 255, 255, 0.1);
      border-left: 3px solid #0a5f55;
      padding: 10px;
      display: flex;
      align-items: center;
      grid-column: span 3;
      grid-row: span 3;
      /* border-radius: 2px; */
    }

    .description-group {
      padding: 2.5px 10px;
      display: flex;
      align-items: center;
      grid-column: span 3;
      grid-row: span 3;
      border-radius: 2px;
      border-bottom: 1px solid #888;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column !important;
  }
`;

export const CycleContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 15px 15px 15px;
  background: #ffff;
  border-radius: 8px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  /* height: 100%; */
  margin-bottom: 10px;

  section.cycle-descriptions {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    h2 {
      color: #444;
    }

    small {
      color: #888;
    }
  }

  section.cycle-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 35%;
    padding-left: 30px;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-content: center;
      border: none;
      border-radius: 50%;
      background: none;

      small {
        margin-top: 5px;
        color: #bbb;
        font-weight: 300;
      }

      svg {
        background: #bbb;
        border-radius: 50px;
        width: 50px;
        height: 50px;
        padding: 10px;
        transition: background 0.5s ease-out;
      }

      &:hover {
        svg {
          background: #411564;
        }

        small {
          color: #411564;
        }
      }
    }
  }

  .dots-button {
    font-size: 3rem;
    font-weight: 700;
    transform: rotate(90deg);
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 10px;
    color: #ddd;
    position: relative;
    transition: transform 0.5s ease-out;

    &:hover {
      color: #f47920;
      transform: rotate(180deg);
    }
  }
`;

export const FloatMenu = styled.div`
  display: ${(props) => (props.show ? 'flex' : 'none !important')};
  position: absolute;
  right: 140px;
  //top: 50px;
  background: rgba(3, 3, 3, 0.8);
  color: #ffff;
  padding: 5px 20px 20px 20px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;

  button {
    color: #ffff;
    text-align: start;
    padding: 10px;
    border-bottom: 1px dotted #fff !important;
    border-radius: 0 !important;
    width: 100%;
    display: flex !important;
    flex-direction: row !important;

    svg {
      background: none !important;
      border-radius: none !important;
      width: auto !important;
      height: auto !important;
      transition: background 0.5s ease-out;
    }

    &:hover {
      color: #ffcb08;
    }
  }
`;

export const ButtonAdicionar = styled.button`
  background: #ddd;
  color: #222;
  border: none;
  margin-bottom: 20px;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background 0.3s ease-out;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &:hover {
    background: #ccc;
  }
`;

export const UpContainer = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 20px;
    max-width: 100%;
  }

  & > div {
    width: 100%;
    height: 102px;
    margin-bottom: 10px;
  }
`;
