import styled from 'styled-components';

export const MainSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  section {
    width: 100%;

    header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 15px;
      align-items: center;
      min-height: 40px;

      button {
        max-width: 185px;
      }
    }

    &:last-of-type {
      margin-left: 15px;
    }
  }
`;

export const TrainingCard = styled.div`
  width: 100%;
  background: #fff;
  padding: 10px 15px 15px 15px;
  border-radius: 8px;
  margin-bottom: 15px;

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0 !important;

    svg {
      cursor: pointer;

      &:last-child {
        margin-left: 5px;
      }
    }
  }

  div {
    color: #555;
    font-weight: 300;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Tags = styled.span`
  background: #ed1651;
  color: #fff;
  padding: 2px 5px 2px 13px;
  font-weight: 300;
  border-radius: 8px;
  font-size: 1.2rem;

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 50%;
    margin-left: -9px;
    margin-top: 4px;
  }
`;
