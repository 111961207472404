import React, { memo, useState, useEffect, Fragment, useCallback } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { HiOutlineViewGridAdd } from 'react-icons/hi';
import { MdArrowDropDown, MdDelete } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Form as Unform, Form } from '@unform/web';

import { ButtonNormal, Dialog, MainContainer, FlatInput, Select } from '~/components';
import { LoadingTable } from '~/components/Shimmer';
import { mountOptionList, groupBy } from '~/functions';
import api from '~/services/api';
import { updateBreadcrumb } from '~/store/modules/user/actions';

import { BehaviorsContainer, ChangeContainerMode } from './styles';

function Behaviors() {
  const dispatch = useDispatch();

  const [entityList, setEntityList] = useState([]);
  const [pathList, setPathList] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [entityInProcess, setEntityInProcess] = useState({});
  const [showPath, togglePath] = useState({});
  const [showSkillForm, setShowSkillForm] = useState(false);

  const profile = useSelector((state) => state.user.profile);

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { name: 'Início', path: '/' },
        { name: 'Configurações', path: '/sys-config' },
        { name: 'Comportamentos', path: '/behavior' },
      ]),
    );
  }, [dispatch]);

  useEffect(() => {
    async function loadEntitiesData() {
      mountOptionList('paths', setPathList);
      mountOptionList('skills', setSkillList);
      const { data } = await api.get(`behaviors`);
      const thinObject = data.map((behavior) => ({
        skill_id: behavior.skill_id,
        path_id: behavior.path_id,
        id: behavior.id,
        path: behavior.paths.description,
        skill: behavior.skills.name,
        behavior: behavior.description,
      }));

      const groupByPath = groupBy(thinObject, 'path');

      const groupBySkill = Object.values(groupByPath).map((te) => ({ [te[0].path]: groupBy(te, 'skill') }));

      let pathToShow = {};
      Object.keys(groupByPath).forEach((path) => {
        pathToShow = { [path]: false, ...pathToShow };
      });

      togglePath(pathToShow);
      setEntityList(groupBySkill);
    }

    loadEntitiesData();
  }, []);

  async function addEntity({ path_id, skill_id }) {
    try {
      const { data: newBehavior } = await api.post('behaviors', {
        path_id,
        skill_id,
        description: `🪄 | Click aqui e insira a descrição aqui! ${Math.random(1000)}.${Math.random(1000)}`,
        company_id: profile.company_id,
      });

      const newEntity = {
        skill_id: newBehavior.skill_id,
        path_id: newBehavior.path_id,
        id: newBehavior.id,
        path: newBehavior.paths.description,
        skill: newBehavior.skills.name,
        behavior: newBehavior.description,
      };

      const entityTeste = entityList.map((path) => {
        if (Object.keys(path)[0] === newEntity.path) {
          const pathValues = Object.values(path)[0];
          let addedItems = {};
          if (pathValues[newEntity.skill]) {
            addedItems = { ...pathValues, [newEntity.skill]: [...pathValues[newEntity.skill], newEntity] };
          } else {
            addedItems = { ...pathValues, [newEntity.skill]: [newEntity] };
          }
          return { [newEntity.path]: addedItems };
        }
        return path;
      });

      setEntityList(entityTeste);

      toast.success(`Comportamento criado, edite sua descrição.`);
    } catch (error) {
      toast.error(`Erro ao inserir um novo comportamento: ${error.message}`);
    }
  }

  async function removeEntity() {
    if (entityInProcess.id) {
      try {
        await api.delete(`behaviors/${entityInProcess.id}`);

        const entityTeste = entityList.map((path) => {
          if (Object.keys(path)[0] === entityInProcess.path) {
            const pathValues = Object.values(path)[0];
            const filteredData = pathValues[entityInProcess.skill].filter((behavior) => behavior.id !== entityInProcess.id);
            const allItems = { ...pathValues, [entityInProcess.skill]: filteredData };

            // se a competência for ficar sem comportamentos temos que excluir ela também
            if (pathValues[entityInProcess.skill].length === 1) {
              const { [entityInProcess.skill]: removed, ...withoutBlanks } = allItems;
              return { [entityInProcess.path]: withoutBlanks };
            }
            return { [entityInProcess.path]: allItems };
          }
          return path;
        });

        setEntityList(entityTeste);

        toast.success(`Comportamento excluído com sucesso`);
        setShowDialog(false);
      } catch (error) {
        toast.error(`${error.message}. Verifique!`);
      }
    }
  }

  const handleInputBlur = async (data) => {
    try {
      await api.put(`behaviors/${data.id}`, {
        ...data,
      });
      toast.success(`Dados salvos com sucesso`);
    } catch ({ response }) {
      response.data.forEach((err) => {
        toast.error(`${err.message}. Verifique!`);
      });
    }
  };

  const toggleSweetAlert = () => {
    setShowSkillForm(!showSkillForm);
  };

  const getChildrenItem = useCallback(
    (path) => {
      const paths = entityList.find((pathItem) => pathItem[path]);
      if (!paths)
        return (
          <div className='skill-container' style={{ display: `${showPath[path] ? 'flex' : 'none'}` }}>
            Sem Itens
          </div>
        );
      const skillsOfPath = paths[path];

      return Object.keys(skillsOfPath).map((skill) => {
        const parentData = Object.values(skillsOfPath).find((sk) => sk?.filter((filter) => filter.skill === skill).length > 0)[0];
        return (
          <div
            key={`${path}.${Math.random(1000)}`}
            className='skill-container'
            style={{ display: `${showPath[path] ? 'flex' : 'none'}` }}>
            <section>
              <div>
                <small>Competência</small>
                <h5>{skill}</h5>
              </div>
              <div className='button-container'>
                <ButtonNormal
                  type='button'
                  color='#333'
                  background='#ddd'
                  onClick={() => addEntity({ path_id: parentData.path_id, skill_id: parentData.skill_id })}>
                  <HiOutlineViewGridAdd size={24} />
                  Adicionar Comportamento
                </ButtonNormal>
              </div>
            </section>
            <section>
              <Form>
                {Object.values(skillsOfPath)
                  .find((sk) => sk.filter((filter) => filter.skill === skill).length > 0)
                  .map((item) => (
                    <div className='skill-description-container' key={`${item.id}.${Math.random(1000)}`}>
                      <div>
                        <MdDelete
                          size={20}
                          color='#ed1651'
                          onClick={() => {
                            setEntityInProcess({ id: item.id, description: item.behavior, path: item.path, skill: item.skill });
                            setShowDialog(true);
                          }}
                        />
                      </div>
                      <FlatInput
                        name={`description.${item.id}`}
                        placeholder='Insira aqui o comportamento'
                        viewAs='p'
                        defaultValue={item.behavior}
                        submitFunction={handleInputBlur}
                        customData={{ path_id: item.path_id, skill_id: item.skill_id }}
                      />
                    </div>
                  ))}
              </Form>
            </section>
          </div>
        );
      });
    },
    [entityList, showPath],
  );

  const confirmSweetAlertButtonHandle = async () => {
    await addEntity({ path_id: entityInProcess.path_id, skill_id: entityInProcess.skill_id });
    toggleSweetAlert();
  };

  return (
    <MainContainer>
      <ChangeContainerMode>
        {entityList.length === 0 ? (
          <LoadingTable />
        ) : (
          pathList.map((path) => (
            <Fragment key={`${path}.${Math.random(1000)}`}>
              <BehaviorsContainer>
                <section className='skill-header'>
                  <section>
                    <div className='header-path'>
                      <small>Trajetória/Complexidade</small>
                      <h3>{path.description}</h3>
                    </div>
                    <ButtonNormal
                      type='button'
                      color='#5b5c65'
                      background='#ddd'
                      onClick={() => {
                        setEntityInProcess({});
                        setEntityInProcess({ path_id: path.id });
                        toggleSweetAlert();
                      }}>
                      <HiOutlineViewGridAdd size={24} color='#00995d' />
                      Adicionar Competência
                    </ButtonNormal>
                  </section>
                  <MdArrowDropDown
                    size={36}
                    color='#5b5c65'
                    onClick={() => togglePath({ ...showPath, [path.label]: !showPath[path.label] })}
                  />
                </section>
                {getChildrenItem(path.description)}
              </BehaviorsContainer>
            </Fragment>
          ))
        )}
        <Dialog
          open={showDialog}
          setOpen={setShowDialog}
          confirmAction={removeEntity}
          title='Confirma a exclusão do comportamento: '
          entityData={entityInProcess}
          actionName='Excluir'
          actionText='A exclusão desse comportamento acarretará na exclusão de todos os dados já cadastrados para ele. Tenha certeza que deseja excluí-lo.'
        />
        <SweetAlert
          confirmBtnText='Adicionar Competência'
          cancelBtnText='Cancelar'
          title='Selecione a competência que deseja adicionar:'
          onConfirm={confirmSweetAlertButtonHandle}
          onCancel={toggleSweetAlert}
          showCancel
          show={showSkillForm}
          confirmBtnCssClass='delete-confirm-button'
          cancelBtnCssClass='delete-cancel-button'>
          <Unform>
            <Select
              name='skill_id'
              placeholder='Selecione uma competência'
              options={skillList}
              isMulti={false}
              onChange={(e) => setEntityInProcess((entity) => ({ ...entity, skill_id: e.value }))}
              closeMenuOnSelect
            />
          </Unform>
        </SweetAlert>
      </ChangeContainerMode>
    </MainContainer>
  );
}

export default memo(Behaviors);
