import React, { memo, useEffect, useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import AnimatedNumber from 'animated-number-react';

import { LoadingCard } from '~/components/Shimmer';
import * as routes from '~/routes/config';
import api from '~/services/api';
import { updateEvaluationCycleOptions } from '~/store/modules/cycle/actions';
import { updateBreadcrumb } from '~/store/modules/user/actions';

import { Container, DescriptionContainer, EmployeesContainer, UpContainer, CardContainer } from './styles';

function SummaryAreaEmployees() {
  const dispatch = useDispatch();
  const cycle = useSelector((state) => state.cycle);

  const [employeeList, setEmployeeList] = useState([]);
  const [employeeEvaluated, setEmployeeEvaluated] = useState(0);

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { name: 'Início', path: '/' },
        { name: 'Resumo das Avaliações', path: '/summary-evaluations' },
        { name: 'Lista de Colaboradores', path: `/summary-evaluation-area/${cycle.options?.departmentId}` },
      ]),
    );
  }, [cycle.options?.departmentId, dispatch]);

  useEffect(() => {
    async function getData() {
      const { data: employees } = await api.get(`summaryareaemployees/${cycle.options?.departmentId}`);
      const sortedEmployees = employees.sort((a, b) => a.name.localeCompare(b.name, 'pt-BR'));
      setEmployeeList(sortedEmployees);
      setEmployeeEvaluated(
        employees.filter(
          (employee) =>
            !parseInt(employee.__meta__.evaluationCycleAnswers_count, 10) &&
            !parseInt(employee.__meta__.evaluationCycleJustificatives_count, 10) &&
            !parseInt(employee.__meta__.evaluationCycleComments_count, 10) &&
            employee.evaluationCycleAnswers?.length > 0,
        ).length,
      );
    }

    if (cycle.options?.departmentId) {
      getData();
    }
  }, [cycle.options?.departmentId]);

  const storeEmployeeData = (employeeId) => {
    dispatch(updateEvaluationCycleOptions({ departmentId: cycle.options.departmentId, employeeId, singleView: false }));
  };

  return (
    <Container>
      <DescriptionContainer>
        <div>
          <h3>{employeeList[0]?.departments.name ?? 'Carregando nome do setor...'}</h3>
          <span>Aqui estão todos os colaboradores que compõem este setor.</span>
        </div>
        <div className='area-summary'>
          <span>
            Colaborador:{' '}
            <b>
              <AnimatedNumber value={employeeList?.length} formatValue={(value) => parseInt(value, 10)} />
            </b>
          </span>
          <span>
            Já avaliado:{' '}
            <b>
              <AnimatedNumber value={employeeEvaluated} formatValue={(value) => parseInt(value, 10)} />
            </b>
          </span>
        </div>
      </DescriptionContainer>
      <EmployeesContainer>
        <UpContainer>
          <div>
            {employeeList.length ? (
              employeeList.map((employee) => (
                <CardContainer
                  onClick={() => storeEmployeeData(employee.id)}
                  to={routes.SUMMARY_EVALUATION_EMPLOYEE}
                  key={employee.id}
                  finished={`${
                    !parseInt(employee.__meta__.evaluationCycleAnswers_count, 10) &&
                    !parseInt(employee.__meta__.evaluationCycleJustificatives_count, 10) &&
                    !parseInt(employee.__meta__.evaluationCycleComments_count, 10) &&
                    employee.evaluationCycleAnswers?.length > 0
                  }`}
                  withoutform={`${!employee.evaluationCycleAnswers?.length > 0}`}>
                  <div className='employee-description'>
                    <div className='card-title'>
                      <h4>{employee.name}</h4>
                      <MdKeyboardArrowRight size={24} color='#333' />
                    </div>
                    <span>
                      <b>{employee.departments.name.toUpperCase()}</b>
                    </span>
                    <span>HIERARQUIA - {employee.hierarchies?.description.toUpperCase()}</span>
                    <span>CARGO - {employee.positions?.description.toUpperCase()}</span>
                    <span>TRAJETÓRIA - {employee.positions?.paths.description.toUpperCase()}</span>
                  </div>
                </CardContainer>
              ))
            ) : (
              <>
                <LoadingCard />
                <LoadingCard />
                <LoadingCard />
              </>
            )}
          </div>
        </UpContainer>
      </EmployeesContainer>
    </Container>
  );
}

export default memo(SummaryAreaEmployees);
