import React, { useEffect, useRef, memo } from 'react';

import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import { Container } from './styles';

const CheckboxInput = ({ name, label, ...rest }) => {
  const inputRefs = useRef([]);

  const { fieldName, registerField, defaultValue = [] } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs) => {
        return refs.checked;
      },
      clearValue: (refs) => {
        refs.checked = false;
      },
      setValue: (refs, values) => {
        refs.checked = values;
      },
    });
  }, [defaultValue, fieldName, registerField]);
  return (
    <Container>
      <input className='inp-cbx' id={name} defaultChecked={defaultValue} ref={inputRefs} type='checkbox' {...rest} />
      <label className='cbx' htmlFor={name}>
        {
        //eslint-disable-line
        }
        <span>
          <svg width='12px' height='10px' viewBox='0 0 12 10'>
            <polyline points='1.5 6 4.5 9 10.5 1' />
          </svg>
        </span>
        <span>{label}</span>
      </label>
    </Container>
  );
};

CheckboxInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default memo(CheckboxInput);
