import styled from 'styled-components';

// export const Container = styled.div`
//   max-width: calc(100vw - 129px);
//   margin: 0 auto;
//   max-width: 1200px;
//   padding: 0 0 20px 0;

//   @media (max-width: 1220px) {
//     max-width: calc(100% - 40px);
//   }

//   @media (max-width: 768px) {
//     max-width: 100%;
//     padding: 0 15px 15px 15px;
//   }
// `;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 210px;
  min-width: 210px;

  @media (max-width: 768px) {
    min-width: 100%;
  }

  button {
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    margin: 0 10px 10px 0;
    color: #222;
    cursor: pointer;
    border: none;
    font-size: 1.1em;
    transition: background 0.3s;

    @media (max-width: 768px) {
      margin: 0 0 10px 0;
    }

    &:hover {
      color: #fff;
      background: rgba(91, 92, 101, 0.6);
    }
  }

  button.active {
    color: #fff;
    background: #5b5c65;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 13px;
      right: -8px;
      width: 0;
      height: 0;
      border-left: 8px solid #5b5c65;
      border-bottom: 8px solid transparent;
      border-top: 8px solid transparent;
    }
  }

  div.options-skill-title {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background: rgba(255, 203, 8, 1);
    margin: 0 10px 12px 0;
    color: #682d00;
    font-size: 15px;
    border-radius: 4px;

    &::before {
      content: '';
      position: absolute;
      top: 28px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid rgba(255, 203, 8, 1);
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const DescriptionContainer = styled.div`
  background: #fff;
  padding: 8px 15px;
  border-radius: 5px;
  margin-bottom: 10px;
  min-height: 51px;

  h3 {
    color: #222;
    text-transform: uppercase;
  }

  span {
    color: #999;
    font-size: 12px;
  }

  @media (max-width: 768px) {
  }
`;

export const SkillContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  justify-content: space-evenly;
  background: #fff;
  padding: 15px;
  border-radius: 5px;
  display: ${(props) => (props.show ? 'flex' : 'none')};

  div.skill_name {
    margin-bottom: 15px;
    /* border: 1px solid rgba(91, 92, 101, 1);
    border-left: 10px solid rgba(91, 92, 101, 1); */
    padding: 5px 15px;
    /* background: #efefef; */
    border-radius: 5px;
  }

  h2 {
    color: #222;
    margin-bottom: 5px;
    border-bottom: 1px solid #ddd;
  }

  span.description {
    font-size: 1.7rem;
    color: #222;
    font-weight: 300;
  }
`;

export const AnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 10px;

  h4 {
    font-size: 1.1em;
    text-align: justify;
    color: #111;
    /* margin-bottom: 10px; */
    /* border-left: 3px solid #444; */
    padding: 7px 10px 6px 10px;
    background: rgba(200, 200, 200, 0.6);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  label {
    font-size: 1.1em;
    color: #000;
  }
`;

export const RatingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0 0 10px;
  background: #efefef;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const MobileInstructionContainer = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;

    div.mobile-info {
      border-left: 4px solid #ffcb08;
      padding: 5px 5px 5px 10px;
      font-size: 14px;
      background: rgba(255, 203, 8, 0.2);
      color: #333;
    }
  }
`;
