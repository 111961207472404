import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  margin-bottom: 20px;

  @media (max-width: 1224px) {
    padding: 0 20px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 0 15px;
  }
`;

export const ListHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background: #ccc;
  padding: 5px 10px;
`;

export const DepartmentContainer = styled.div``;

export const DepartmentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background: #dddddd;
  padding: 6px 15px 5px 15px;
  align-items: center;
  color: #444;
  border-bottom: 1px solid #ccc;
`;

export const EmployeeListContainer = styled.div`
  padding: 0 0 10px 0;

  div.employee-description {
  }
`;

export const EmployeeInformation = styled.div`
  border-bottom: 1px solid #ddd;
  padding: 5px 15px 4px 15px;
  background: #eee;
  font-size: 1.4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  /* color: #ed1651; */
  div {
    flex: 1;
    color: ${(props) => (props.finished === 'false' ? '#888' : '#ed1651')};
  }

  div.status-column {
    text-align: center;
  }
`;
