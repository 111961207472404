import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from '@unform/web';

import logo from '~/assets/logo_unimed.png';
import { Input } from '~/components';
import { signInRequest } from '~/store/modules/auth/actions';

import { Container } from './styles';

function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  async function handleSubmit({ username, password }) {
    dispatch(signInRequest(username.trim(), password.trim()));
  }

  return (
    <Container>
      <img src={logo} alt='Unimed' />
      <h1>Avaliação de Desempenho</h1>
      <Form onSubmit={handleSubmit}>
        <Input name='username' placeholder='Seu usuário' />
        <Input type='password' name='password' placeholder='Sua senha secreta' />
        <button type='submit'>{loading ? 'Carregando...' : 'Acessar'}</button>
      </Form>
      <footer>Desenvolvido por Tecnologia da Informação Unimed Varginha</footer>
    </Container>
  );
}

export default memo(SignIn);
