import React, { memo, useState, useEffect, useRef, useCallback } from 'react';
import { FaDiceD6, FaChartPie } from 'react-icons/fa';
import { MdSave, MdCancel, MdHourglassEmpty, MdEdit, MdTimeline, MdAdd } from 'react-icons/md';
import { RiSendPlane2Line, RiFingerprintLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Scope } from '@unform/core';
import { Form } from '@unform/web';

import { ButtonNormal, Checkbox, DatePicker, Dialog, Input, Pagination, MainContainer, Select, TreeView } from '~/components';
import { LoadingCard } from '~/components/Shimmer';
import { formatDate } from '~/functions';
import getTotalItemsPerPage from '~/functions/getTotalItemsPerPage';
import * as routes from '~/routes/config';
import api from '~/services/api';
import { updateEvaluationCycleInUse } from '~/store/modules/cycle/actions';
import { updateBreadcrumb, updateOptionsRequest } from '~/store/modules/user/actions';

import { FormContainer, ButtonContainer, CycleContainer, UpContainer, ButtonAdicionar, FloatMenu } from './styles';

function EvalutationCycles() {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [isSaving, setIsSaving] = useState(false);
  const [processing, setProcessing] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(getTotalItemsPerPage(198, 122));
  const [lastPage, setLastPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [entityList, setEntityList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showResetDialog, setShowResetDialog] = useState(false);
  const [initialData, setInitialData] = useState({});
  const [entityInProcess, setEntityInProcess] = useState({});
  const [treeNodes, setTreeNodes] = useState([]);
  const [checked, setChecked] = useState([]);
  const [process, setProcess] = useState('add');
  const [showContextMenu, setShowContextMenu] = useState({});

  const profile = useSelector((state) => state.user.profile);
  const formRef = useRef(null);
  const history = useHistory();

  const mountTreeList = useCallback(async (childrenEntity, setFunction) => {
    const { data: childrenData } = await api.get(`${childrenEntity}`);
    setFunction(childrenData);
  }, []);

  useEffect(() => {
    async function loadEntitiesData() {
      dispatch(
        updateBreadcrumb([
          { name: 'Início', path: '/' },
          { name: 'Configurações', path: '/sys-config' },
          { name: 'Ciclo de Avaliação', path: '/evaluation-cycle' },
        ]),
      );
      const {
        data: { data, lastPage: last, total: totalItems },
      } = await api.get(`evaluationcycles?page=${page}&itemsPerPage=${itemsPerPage}`);

      setEntityList(data || []);

      setTotal(parseInt(totalItems, 10));
      setLastPage(parseInt(last, 10));
    }

    loadEntitiesData();
  }, [dispatch, itemsPerPage, page]);

  useEffect(() => {
    setItemsPerPage(showForm ? 10 : getTotalItemsPerPage(198, 34));

    const getCompanyData = async () => {
      const {
        data: { name },
      } = await api.get(`companies/${profile.company_id}`);
      formRef.current.setFieldValue('company_id', profile.company_id);
      formRef.current.setFieldValue('companies.name', name);
    };
    if (showForm) {
      getCompanyData();
    }
  }, [profile.company_id, showForm]);

  useEffect(() => {
    if (!showForm) return;
    if (process === 'edit') {
      setTreeNodes([]);
    } else {
      mountTreeList('alldepartmenthierarchies', setTreeNodes);
    }
  }, [mountTreeList, process, showForm]);

  const loadEntityData = async (entityData) => {
    const { data } = await api.get(`evaluationcycles/${entityData.id}`);
    formRef.current.setData(data);

    mountTreeList('alldepartmenthierarchies', setTreeNodes);
    const checkedItems = data?.evaluationCycleAreas?.map((cycleAreas) => cycleAreas.department_id);
    setChecked(checkedItems);

    setInitialData(data);
  };

  function clearForm() {
    setTreeNodes([]);
    setChecked([]);
    formRef.current.setFieldValue('id', undefined);
    formRef.current.reset();
  }

  async function removeEntity() {
    if (entityInProcess.id) {
      const response = await api.delete(`evaluationcycles/${entityInProcess.id}`);
      toast.success(`${response.statusText}: Ciclo excluído com sucesso`);
      const userListWithoutDeleted = entityList.filter((entity) => entity.id !== entityInProcess.id);
      setEntityList(userListWithoutDeleted);
      setShowDialog(false);
    }
  }

  const onSubmit = async (
    {
      departmentHierarchiesTrash, // eslint-disable-line
      final_evaluation_period,
      final_manager_feedback,
      final_employee_evaluation,
      final_manager_evaluation,
      ...data
    },
    { reset },
  ) => {
    setIsSaving(true);

    const formData = {
      ...data,
      final_evaluation_period: new Date(
        new Date(final_evaluation_period).getFullYear(),
        new Date(final_evaluation_period).getMonth(),
        new Date(final_evaluation_period).getDate(),
        20,
        59,
        59,
      ),
      final_manager_feedback: new Date(
        new Date(final_manager_feedback).getFullYear(),
        new Date(final_manager_feedback).getMonth(),
        new Date(final_manager_feedback).getDate(),
        20,
        59,
        59,
      ),
      final_employee_evaluation: new Date(
        new Date(final_employee_evaluation).getFullYear(),
        new Date(final_employee_evaluation).getMonth(),
        new Date(final_employee_evaluation).getDate(),
        20,
        59,
        59,
      ),
      final_manager_evaluation: new Date(
        new Date(final_manager_evaluation).getFullYear(),
        new Date(final_manager_evaluation).getMonth(),
        new Date(final_manager_evaluation).getDate(),
        20,
        59,
        59,
      ),
      company_id: profile.company_id,
      department_hierarchies: checked,
    };

    if (process === 'edit') {
      const { status, statusText } = await api.put(`evaluationcycles/${data.id}`, formData);
      if (status === 200) {
        toast.success(`${statusText}: Dados atualizados com sucesso`);
        setShowForm(false);
      } else {
        toast.error(`${statusText}: Erro ao atualizar dados. Code: ${status}`);
      }
    } else {
      const { status, statusText, ...rest } = await api.post(`evaluationcycles`, formData).catch((err) => err);
      if (status === 201) {
        toast.success(`${statusText}: Dados cadastrados com sucesso`);
        setEntityList((entities) => [...entities, rest.data]);
        setShowForm(false);
        reset();
      } else {
        toast.error(`${rest.response.status}: ${rest.response.statusText}. Erro ao cadastrar dados`);
      }
    }
    setIsSaving(false);
  };

  const sendForm = async (entityId) => {
    setProcessing(`send-${entityId}`);
    const { data: evaluation_cycle } = await api.get(`evaluationcycles/${entityId}`);
    const participatingDepartments = evaluation_cycle?.evaluationCycleAreas?.map((cycleAreas) => ({
      id: cycleAreas.department_id,
      evaluation_cycle_id: entityId,
    }));

    try {
      const { data: formsReturn } = await api.post('sendcycleforms', { data: participatingDepartments });
      if (formsReturn.status) {
        toast.success('Formulários enviados com sucesso!');
      } else {
        toast.error('Erro ao enviar formulários');
      }
    } catch (error) {
      toast.error(error.message);
    }
    setProcessing(null);
  };

  const resetAllPasswords = async (entityId) => {
    setProcessing(`reset-${entityId}`);
    setShowResetDialog(false);

    const { data: resetReturn } = await api.put('resetone', { allUsers: true });

    try {
      if (resetReturn.status) {
        toast.success(resetReturn.message);
      } else {
        toast.error(resetReturn.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
    setProcessing(null);
  };

  const returnAllResultsHandler = () => {
    dispatch(updateOptionsRequest({ resultType: 'all' }));
  };

  const resetView = (spy, actual) => {
    Object.keys(spy).forEach((key) => {
      if (key === actual) return;
      spy[key] = false;
    });
    return spy;
  };

  const toggleContextMenu = (id) => {
    setShowContextMenu((menu) => ({ ...resetView(menu, id), [id]: !menu[id] }));
  };

  const handleDispatchCycleInUse = (data) => {
    dispatch(updateEvaluationCycleInUse(data));
  };

  return (
    <MainContainer>
      <ButtonAdicionar
        type='button'
        show={!showForm}
        onClick={() => {
          setShowForm(true);
          setProcess('add');
        }}>
        <div>
          <MdAdd size={48} color='#00995d' />
          <h1>Adicionar</h1>
        </div>
        <small>Adicione um novo ciclo de avaliação</small>
      </ButtonAdicionar>
      <FormContainer showForm={showForm}>
        <Form ref={formRef} onSubmit={onSubmit} className='form' initialData={initialData}>
          <div className='hidden-input'>
            <Input name='id' type='hidden' />
          </div>
          <Input name='description' placeholder='Descrição' label='Descrição' />
          <div className='hidden-input'>
            <Input name='company_id' type='hidden' />
          </div>
          <Scope path='companies'>
            <Input name='name' placeholder='Empresa' label='Empresa' disabled />
          </Scope>
          <Checkbox name='complexity_level' label='Nível de complexidade' />
          <DatePicker
            id='initial_evaluation_period'
            name='initial_evaluation_period'
            dateFormat='dd/MM/yyyy'
            type='keyboardDate'
            onBlur={() => null}
            label='Início do Cíclo'
          />
          <DatePicker
            id='final_evaluation_period'
            name='final_evaluation_period'
            dateFormat='dd/MM/yyyy'
            type='keyboardDate'
            onBlur={() => null}
            label='Fim do Ciclo'
          />
          <Checkbox name='justificative' label='Justificativa' />
          <DatePicker
            id='initial_employee_evaluation'
            name='initial_employee_evaluation'
            dateFormat='dd/MM/yyyy'
            type='keyboardDate'
            onBlur={() => null}
            label='Inicio Autoavaliação'
          />
          <DatePicker
            id='final_employee_evaluation'
            name='final_employee_evaluation'
            dateFormat='dd/MM/yyyy'
            type='keyboardDate'
            onBlur={() => null}
            label='Fim Autoavaliação'
          />
          <Checkbox name='comment' label='Comentário' />
          <DatePicker
            id='initial_manager_evaluation'
            name='initial_manager_evaluation'
            dateFormat='dd/MM/yyyy'
            type='keyboardDate'
            onBlur={() => null}
            label='Inicio Avaliação do Gestor'
          />
          <DatePicker
            id='final_manager_evaluation'
            name='final_manager_evaluation'
            dateFormat='dd/MM/yyyy'
            type='keyboardDate'
            onBlur={() => null}
            label='Fim Avaliação do Gestor'
          />
          <Select
            name='make_report_available'
            placeholder='Selecione uma opção?'
            label='Disponibilizar relatório'
            options={[]}
            isMulti={false}
            closeMenuOnSelect
          />
          <DatePicker
            id='initial_manager_feedback'
            name='initial_manager_feedback'
            dateFormat='dd/MM/yyyy'
            type='keyboardDate'
            onBlur={() => null}
            label='Inicio Feedback Gestor'
          />
          <DatePicker
            id='final_manager_feedback'
            name='final_manager_feedback'
            dateFormat='dd/MM/yyyy'
            type='keyboardDate'
            onBlur={() => null}
            label='Fim Feedback Gestor'
          />
          <Select
            name='average_type'
            placeholder='Selecione uma opção?'
            label='Tipo de média'
            options={[]}
            isMulti={false}
            closeMenuOnSelect
          />
          <Select
            name='feedback_type'
            placeholder='Selecione uma opção?'
            label='Tipo de Feedback'
            options={[]}
            isMulti={false}
            closeMenuOnSelect
          />
          <Input name='quantity_pair' label='Pares' />
          <Input name='quantity_subordinate' label='Subordindados' />
          <Input name='quantity_manager' label='Gestores' />
          <div className='sub-title-group'>Selecione as áreas que participarão do ciclo</div>
          <Scope path='departmentHierarchiesTrash'>
            {treeNodes.length > 0 && <TreeView nodes={treeNodes} checked={checked} setChecked={setChecked} />}
          </Scope>
          <div className='hidden-input'>
            <Input name='company_id' type='hidden' />
          </div>
          <div />
          <div />
          <div />
          <div />
          <ButtonContainer>
            <ButtonNormal
              color='#fff'
              background='#00995d'
              style={{ width: '100px' }}
              type='submit'
              disabled={isSaving ? 'disabled' : ''}>
              {!isSaving ? (
                <>
                  <MdSave size={20} color='#ffffff' />
                  Salvar
                </>
              ) : (
                <>
                  <MdHourglassEmpty size={20} color='#ffffff' /> Salvando...
                </>
              )}
            </ButtonNormal>
            <ButtonNormal
              color='#fff'
              background='#ed1651'
              style={{ width: '100px' }}
              onClick={() => {
                setShowForm(false);
                clearForm();
              }}>
              <MdCancel size={20} color='#ffffff' /> Cancelar
            </ButtonNormal>
          </ButtonContainer>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </Form>
      </FormContainer>
      {entityList.length === 0 ? (
        <UpContainer>
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
        </UpContainer>
      ) : (
        !showForm &&
        entityList.map((entity) => (
          <CycleContainer key={entity.id}>
            <section className='cycle-descriptions'>
              <h2>{entity.description}</h2>
              <small>Início do Ciclo: {formatDate(entity.initial_evaluation_period, { timeZone: 'UTC' })}</small>
              <small>Fim do Ciclo: {formatDate(entity.final_evaluation_period, { timeZone: 'UTC' })}</small>
              <small>ID: {entity.id}</small>
            </section>
            <section className='cycle-options'>
              <button
                type='button'
                onClick={() => {
                  setEntityInProcess(entity);
                  setProcess('edit');
                  loadEntityData(entity);
                  setShowForm(true);
                }}>
                <MdEdit size={32} color='#fff' />
                <small>Alterar</small>
              </button>
              <button
                type='button'
                onClick={() => {
                  handleDispatchCycleInUse({ id: entity.id, description: entity.description });
                  history.push(`${routes.FOLLOW}/${entity.id}`);
                }}>
                <MdTimeline size={32} color='#fff' />
                <small>Acompanhar</small>
              </button>
              <button type='button'>
                <FaChartPie size={32} color='#fff' />
                <small>Analisar</small>
              </button>
              <button
                type='button'
                onClick={() => {
                  handleDispatchCycleInUse({ id: entity.id, description: entity.description });
                  returnAllResultsHandler();
                  history.push(routes.RESULTS);
                }}>
                <FaDiceD6 size={32} color='#fff' />
                <small>Resultados</small>
              </button>
              <button type='button' className='dots-button' onClick={() => toggleContextMenu(entity.id)}>
                ...
              </button>
              <FloatMenu show={showContextMenu[entity.id]}>
                <button
                  type='button'
                  onClick={() => {
                    setShowContextMenu(false);
                    sendForm(entity.id);
                  }}>
                  <RiSendPlane2Line size={20} color='#ffcb08' />
                  <span>Enviar Formulários</span>
                </button>
                <button
                  type='button'
                  onClick={() => {
                    setShowContextMenu(false);
                    setShowResetDialog(true);
                  }}>
                  <RiFingerprintLine size={20} color='#ffcb08' />
                  <span>Resetar Todas Senhas</span>
                </button>
              </FloatMenu>
            </section>
          </CycleContainer>
        ))
      )}
      <Dialog
        open={showDialog}
        setOpen={setShowDialog}
        confirmAction={removeEntity}
        title='Confirma a exclusão do ciclo: '
        entityData={entityInProcess}
        actionName='Excluir'
        actionText='A exclusão desse formulário acarretará na exclusão de todos os dados já cadastrados para ele. Tenha certeza que deseja excluí-lo.'
      />
      <Dialog
        open={showResetDialog}
        setOpen={setShowResetDialog}
        confirmAction={resetAllPasswords}
        title='Você confirma o processo para resetar TODAS as senhas de usuário e enviar e-mail para cada usuário: '
        entityData={entityInProcess}
        actionName='Resetar'
        actionText='Todos os usuários terão as senhas resetadas, e receberão um e-mail com a nova senha.'
      />
      {!showForm && <Pagination page={page} setPage={setPage} lastPage={lastPage} total={total} />}
    </MainContainer>
  );
}

export default memo(EvalutationCycles);
