import styled from 'styled-components';

export const FormContainer = styled.div`
  height: ${(props) => (props.showForm ? '385px' : '0')};
  opacity: ${(props) => (props.showForm ? '1' : '0')};
  transition: height 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 2s ease;

  form {
    margin-top: 10px;
    display: ${(props) => (props.showForm ? 'grid' : 'none')};
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;

    .hidden-input {
      display: none;
    }
    .hidden-input:target {
      display: block;
    }
  }

  .button-clear-answers-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      text-align: justify;
      font-size: 1.4rem;
      padding: 0 0 0 10px;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

export const PasswordContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ToggleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;

  span {
    margin-bottom: 5px;
    color: #333;
  }
`;
