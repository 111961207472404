import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin: 0 auto;
  position: fixed;
  bottom: 20px;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;

  li button {
    font-size: 1.5rem !important;
    color: #333 !important;
    background-color: #fff;

    .Mui-selected {
      background-color: #aaa !important;
    }
  }

  section.total {
    color: #444;
    background: #fff;
    padding: 8px 10px 5px 10px;
    border-radius: 4px;
    font-weight: bold;
  }
  @media (max-width: 768px) {
    bottom: 5px;
  }
`;
