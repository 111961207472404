import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { MainContainer } from '~/components';
import { LoadingTable } from '~/components/Shimmer';
import * as routes from '~/routes/config';
import api from '~/services/api';
import { updateBreadcrumb } from '~/store/modules/user/actions';

import { ListHeader, DepartmentContainer, DepartmentHeader, EmployeeListContainer, EmployeeInformation } from './styles';

export default function ConciliationEmployeesList() {
  const dispatch = useDispatch();
  const [departmentList, setDepartmentList] = useState([]);
  const [conciliationList, setConciliationList] = useState([]);
  const user = useSelector((state) => state.user);
  const [storage, setStorage] = useState({});

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { name: 'Início', path: '/' },
        { name: 'Resumo das Avaliações', path: '/summary-evaluations' },
        { name: 'Lista de Colaboradores para Conciliar', path: '/conciliation' },
      ]),
    );
  }, [dispatch]);

  useEffect(() => {
    const dataReturned = JSON.parse(localStorage.getItem(`@crescer-unimed/${user.id}`));
    setStorage(dataReturned);
  }, [user.id]);

  useEffect(() => {
    async function getData() {
      const { data: employeesData } = await api.get(`conciliationlist`);
      const answerToConciliate = [3, -3];

      const removeUnanswered = employeesData.filter(
        (employee) => employee.evaluationCycleAnswers.filter((answer) => answer.user_answer === null).length > 0,
      );

      const employeesToConciliate = removeUnanswered.filter(
        (employee) =>
          employee.evaluationCycleAnswers.filter((answer) =>
            answerToConciliate.includes(answer.user_answer - answer.leader_answer),
          ).length > 0,
      );

      setConciliationList(employeesToConciliate);
      const deparmentList = employeesToConciliate.map((employee) => employee.departments?.name);
      const uniqueDeparmentList = new Set([...deparmentList]);
      const sortedDeparments = [...uniqueDeparmentList].sort((areaA, areaB) => areaA.localeCompare(areaB, 'pt-BR'));
      setDepartmentList(sortedDeparments);
    }

    getData();
  }, []);

  const storeEmployeeData = (employeeId) => {
    localStorage.setItem(`@crescer-unimed/${user.id}`, JSON.stringify({ ...storage, employeeId, singleView: false }));
  };

  return (
    <MainContainer>
      {conciliationList.length > 0 ? (
        <>
          <ListHeader>
            <h2>Colaboradores para conciliação</h2>
            <div>Setores: {departmentList.length}</div>
          </ListHeader>
          <div>
            {departmentList.map((department) => (
              <DepartmentContainer key={department}>
                <DepartmentHeader>
                  <h4>{department}</h4>
                </DepartmentHeader>
                <EmployeeListContainer>
                  {conciliationList.length > 0 &&
                    conciliationList.map((employee) => {
                      if (employee.departments?.name === department) {
                        return (
                          <EmployeeInformation key={employee.id}>
                            <Link onClick={() => storeEmployeeData(employee.id)} to={routes.SUMMARY_EVALUATION_EMPLOYEE}>
                              {employee.name}
                            </Link>
                          </EmployeeInformation>
                        );
                      }
                      return <div key={employee.id} />;
                    })}
                </EmployeeListContainer>
              </DepartmentContainer>
            ))}
          </div>
          <div />
        </>
      ) : (
        <LoadingTable />
      )}
    </MainContainer>
  );
}
