import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
import { MdSave, MdCancel } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Scope } from '@unform/core';
import { Form } from '@unform/web';
import { generate } from 'generate-password';
import RemoveAccent from 'remove-accents';

import {
  ButtonNormal,
  Checkbox,
  DatePicker,
  Dialog,
  Input,
  Pagination,
  Panel,
  SearchPanel,
  Select,
  Table,
  ToggleButton,
  MainContainer,
} from '~/components';
import { LoadingTable } from '~/components/Shimmer';
import { getTotalItemsPerPage, mountOptionList } from '~/functions';
import api from '~/services/api';
import { updateBreadcrumb } from '~/store/modules/user/actions';

import { FormContainer, ButtonContainer, FieldContainer, ToggleContainer } from './styles';
import { headers } from './tableHeaders';

function Users() {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(getTotalItemsPerPage(198, 38));
  const [lastPage, setLastPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [entityList, setEntityList] = useState([]);
  const [departmentList, setDeparmentList] = useState([]);
  const [hierarchyList, setHierarchyList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showResetDialog, setShowResetDialog] = useState(false);
  const [showClearFormDialog, setShowClearFormDialog] = useState(false);
  const [initialData, setInitialData] = useState({});
  const [roleList, setRoleList] = useState([]);
  const [roleUserList, setRoleUserList] = useState([]);
  const [entityInProcess, setEntityInProcess] = useState({});
  const [process, setProcess] = useState('add');
  const [processing, setProcessing] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [showReopenDialog, setShowReopenDialog] = useState(false);
  const formRef = useRef(null);

  const profile = useSelector((state) => state.user.profile);

  useEffect(() => {
    setItemsPerPage(showForm ? 2 : getTotalItemsPerPage(198, 38));
  }, [showForm]);

  const loadUsersData = useCallback(async () => {
    setIsLoading(true);
    dispatch(
      updateBreadcrumb([
        { name: 'Início', path: '/' },
        { name: 'Configuração', path: '/sys-config' },
        { name: 'Funcionários', path: '/user' },
      ]),
    );
    const {
      data: { data, lastPage: last, total: totalItems },
    } = await api.get(`users?page=${page}&itemsPerPage=${itemsPerPage}&searchSentence=${searchText}&searchBy=name`);
    setEntityList(data);
    setTotal(parseInt(totalItems, 10));
    setLastPage(parseInt(last, 10));
    setIsLoading(false);
  }, [dispatch, page]); // eslint-disable-line

  useEffect(() => {
    async function getData() {
      await mountOptionList('departments', setDeparmentList);
      await mountOptionList('hierarchies', setHierarchyList);
      await mountOptionList('positions', setPositionList);
      await mountOptionList('roles', setRoleList);
    }

    getData();
  }, []);

  const loadUserData = async (userData) => {
    const { data } = await api.get(`users/${userData.id}`);
    const { roles, ...rest } = data;

    formRef.current.setData(rest);
    const userRoles = roles?.map((rs) => rs.id);
    setRoleUserList(userRoles);
    setInitialData(rest);
  };

  useEffect(() => {
    loadUsersData();
  }, [loadUsersData]);

  const onSubmit = async (data, { reset }) => {
    try {
      setIsSaving(true);
      if (process === 'edit') {
        const { status, statusText } = await api.put(`users/${data.id}`, {
          ...data,
          roles: roleUserList,
          company_id: profile.company_id,
        });
        if (status === 200) {
          toast.success(`${statusText}: Dados atualizados com sucesso`);
          setShowForm(false);
        } else {
          toast.error(`${statusText}: Erro ao atualizar dados. Code: ${status}`);
        }
      } else {
        const userData = {
          ...data,
          password: generate({ length: 10, uppercase: false }),
          roles: roleUserList,
          active: true,
          company_id: profile.company_id,
        };
        delete userData.companies;
        const { status, statusText, ...rest } = await api.post(`register`, userData).catch((err) => err);

        if (status === 201 || status === 200) {
          toast.success(`${statusText}: Dados cadastrados com sucesso`);
          setRoleUserList([]);
          reset();
        } else {
          const {
            response: { data: returnedErrors },
          } = rest;
          if (returnedErrors) {
            returnedErrors.map((erro) => {
              toast.error(`${erro.message}!`);
              return null;
            });
          }
          toast.error(`${rest.response.status}: ${rest.response.statusText}. Erro ao cadastrar dados`);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setIsSaving(false);
  };

  const getCompanyCode = useCallback(
    async (registry) => {
      const {
        data: { code, name },
      } = await api.get(`companies/${profile.company_id}`);
      formRef.current.setFieldValue('username', `${code}.${registry}`);
      formRef.current.setFieldValue('company_id', profile.company_id);
      formRef.current.setFieldValue('companies.name', name);
    },
    [profile.company_id],
  );

  function clearForm() {
    formRef.current.setFieldValue('id', undefined);
    formRef.current.reset();
    setRoleUserList([]);
  }

  async function removeUser() {
    if (entityInProcess.id) {
      const response = await api.delete(`users/${entityInProcess.id}`);
      toast.success(`${response.statusText}: Usuário excluído com sucesso`);
      const userListWithoutDeleted = entityList.filter((user) => user.id !== entityInProcess.id);
      setEntityList(userListWithoutDeleted);
      setShowDialog(false);
    }
  }

  const handleSearch = useCallback(
    async ({ searchSentence }) => {
      setIsLoading(true);
      setSearchText(RemoveAccent(searchSentence));
      const {
        data: { data, total: totalReturned },
      } = await api.get(
        `users?page=${page}&itemsPerPage=${itemsPerPage}&searchSentence=${RemoveAccent(searchSentence)}&searchBy=name`,
      );

      setEntityList(data);
      setTotal(parseInt(totalReturned, 10));
      setIsLoading(false);
    },
    [itemsPerPage, page],
  );

  const addRoleToList = useCallback(
    (itemId) => {
      let newList = [...roleUserList];
      const index = newList.indexOf(itemId);
      if (index !== -1) {
        newList.splice(index, 1);
      } else {
        newList = [...newList, itemId];
      }
      setRoleUserList(newList);
    },
    [roleUserList],
  );

  const resetPassword = useCallback(async () => {
    const { id: userId } = entityInProcess;
    setProcessing(`reset-${userId}`);
    try {
      const password = generate({ length: 10, uppercase: false, symbols: false, numbers: true, exclude: ['l', 'o'] });
      await api.put('resetone', { id: userId, password });
      toast.success(`Senha enviada com sucesso!`);
      setShowResetDialog(false);
    } catch (error) {
      toast.error(`Falha ao enviar senha: ${error}`);
    }
    setProcessing(null);
  }, [entityInProcess]);

  const checkIfPositionWasChanged = (selectedValue) => {
    if (selectedValue?.value !== initialData.position_id && entityInProcess.id === initialData.id) {
      setShowClearFormDialog(true);
    }
  };

  const clearCycleAnswers = useCallback(async () => {
    const { id: employeeId } = entityInProcess;
    const response = await api.delete(`clearemployeeanswers/${employeeId}`);
    toast.success(`${response.statusText}: Questionário do colaborador excluído com sucesso`);
    setShowClearFormDialog(false);
  }, [entityInProcess]);

  const reopenAutoavaliationHandle = useCallback(async () => {
    const { id: userId } = entityInProcess;
    const { data: evaluationCycle } = await api.get(`evaluationcycles?company_id=${profile.company_id}`);
    try {
      const { data: result } = await api.put(`openautoevaluation/${userId}/${'086666d9-ddb9-41ed-ab35-26cdad68ff85'}`);
      if (result.status) {
        setShowReopenDialog(false);
        toast.success('Autoavaliação reaberta com sucesso!');
      }
    } catch (err) {
      toast.error(`Erro ao reabrir autoavaliação, verifique com o suporte. ${err.message}`);
    }
  }, [entityInProcess, profile.company_id]);

  return (
    <MainContainer>
      {!showForm && <SearchPanel handleSearch={handleSearch} />}
      {initialData && (
        <FormContainer showForm={showForm}>
          <Form onSubmit={onSubmit} className='form' ref={formRef} initialData={initialData}>
            <div className='hidden-input'>
              <Input name='id' type='hidden' />
            </div>
            <Input name='name' placeholder='Nome' label='Nome' />
            <Input
              name='registry'
              placeholder='Registro'
              label='Registro'
              onBlur={(event) => getCompanyCode(event.target.value)}
            />
            <Input name='username' placeholder='Usuário' label='Usuário' />
            <Input name='email' placeholder='Email' label='Email' />
            <Input name='cpf' placeholder='CPF' label='CPF' />
            <div className='hidden-input'>
              <Input name='company_id' type='hidden' />
            </div>
            <Scope path='companies'>
              <Input name='name' placeholder='Empresa' label='Empresa' disabled />
            </Scope>
            <Select
              name='department_id'
              placeholder='Setor'
              label='Setor'
              options={departmentList}
              isMulti={false}
              closeMenuOnSelect
            />
            <Select
              name='hierarchy_id'
              placeholder='Hierarquia'
              label='Hierarquia'
              options={hierarchyList}
              isMulti={false}
              closeMenuOnSelect
            />
            <Select
              name='position_id'
              placeholder='Cargo'
              onChange={(e) => initialData.position_id && e && checkIfPositionWasChanged(e)}
              label='Cargo'
              options={positionList}
              isMulti={false}
              closeMenuOnSelect
            />
            <FieldContainer>
              <span>Selecione o Perfil de Acessos</span>
              <ToggleContainer>
                {roleList &&
                  roleList.map((role) => (
                    <ToggleButton
                      key={role.value}
                      text={role.label}
                      itemId={role.value}
                      addToList={addRoleToList}
                      toggled={roleUserList?.includes(role.value)}
                    />
                  ))}
              </ToggleContainer>
            </FieldContainer>
            <DatePicker
              id='admitted_at'
              name='admitted_at'
              dateFormat='dd/MM/yyyy'
              type='keyboardDate'
              onBlur={() => null}
              label='Data de Admissão'
            />
            <DatePicker
              id='fired_at'
              name='fired_at'
              dateFormat='dd/MM/yyyy'
              type='keyboardDate'
              onBlur={() => null}
              label='Data de Demissão'
            />
            <Checkbox name='active' label='Ativo' />
            <div className='button-clear-answers-container' />
            <ButtonContainer>
              <ButtonNormal color='#fff' background='#00995d' style={{ width: '100px' }} type='submit'>
                {!isSaving && <MdSave size={20} color='#ffffff' />} {!isSaving ? 'Salvar' : 'Salvando...'}
              </ButtonNormal>
              <ButtonNormal
                color='#fff'
                background='#ed1651'
                style={{ width: '100px' }}
                onClick={() => {
                  setShowForm(false);
                  clearForm();
                  setInitialData({});
                }}>
                <MdCancel size={20} color='#ffffff' /> Cancelar
              </ButtonNormal>
            </ButtonContainer>
          </Form>
        </FormContainer>
      )}
      {isLoading ? (
        <LoadingTable />
      ) : (
        !showForm && (
          <Panel>
            <Table
              headers={headers}
              data={entityList}
              editing={showForm}
              processing={processing}
              actions={{
                showForm: () => setShowForm(true),
                setProcess,
                edit: loadUserData,
                remove: { setEntityInProcess, setShowDialog },
                clear: clearForm,
                reset: { setEntityInProcess, setShowResetDialog },
                reopen: { setEntityInProcess, setShowReopenDialog },
                add: true,
                search: true,
                buttons: ['edit', 'delete', 'reset', 'reopen-autoavaliation'],
                setEntityInProcess,
              }}
            />
          </Panel>
        )
      )}
      <Dialog
        open={showDialog}
        setOpen={setShowDialog}
        confirmAction={removeUser}
        title='Confirma a exclusão do colaborador: '
        entityData={entityInProcess}
        actionName='Excluir'
        actionText='A exclusão desse colaborador acarretará na exclusão de todos os dados já cadastrados para ele. Tem certeza que deseja excluí-lo.'
      />
      <Dialog
        open={showResetDialog}
        setOpen={setShowResetDialog}
        confirmAction={resetPassword}
        title='Confirma a troca da senha deste usuário: '
        entityData={entityInProcess}
        actionName='Resetar'
        actionText='Será gerada uma nova senha automaticamente e enviada ao e-mail cadastrado para o usuário'
      />

      <Dialog
        open={showClearFormDialog}
        setOpen={setShowClearFormDialog}
        confirmAction={clearCycleAnswers}
        title='Confirma a limpeza do questionário deste colaborador: '
        entityData={entityInProcess}
        actionName='Limpar'
        actionText='Todas as questões e avalições do ciclo atual deste colaborador serão apagadas, e você deverá reenviar os formulários na área de administração do ciclo.'
      />
      <Dialog
        open={showReopenDialog}
        setOpen={setShowReopenDialog}
        confirmAction={reopenAutoavaliationHandle}
        title='Confirma a reabertura da autoavaliação deste colaborador: '
        entityData={entityInProcess}
        actionName='Reabrir'
        actionText='O usuário conseguirá editar sua autoavaliação do ciclo atual.'
      />
      {!showForm && <Pagination page={page} setPage={setPage} lastPage={lastPage} total={total} />}
    </MainContainer>
  );
}

export default memo(Users);
