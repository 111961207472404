import React, { memo, useEffect, useState } from 'react';
import { MdPlaylistAdd, MdEdit, MdDelete } from 'react-icons/md';
import { useDispatch } from 'react-redux';

import { Tooltip } from '@material-ui/core';

import { MainContainer, ButtonNormal } from '~/components';
import { useToggle } from '~/hooks';
import { updateBreadcrumb } from '~/store/modules/user/actions';

import { MainSection, TrainingCard, ButtonContainer, Tags } from './styles';

function TrainingRequests() {
  const dispatch = useDispatch();
  const [initialData, setInitialData] = useState({});
  const [showModal, toggleModal] = useToggle();

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { name: 'Início', path: '/' },
        { name: `Treinamentos da sua Área`, path: '/training-request' },
      ]),
    );
  }, [dispatch]);

  return (
    <MainContainer>
      <MainSection>
        <section>
          <header>
            <h2>Solicitação de Treinamentos</h2>
            <ButtonNormal type='button' onClick={() => {}} background='#ddd' color='#333'>
              <MdPlaylistAdd size={24} color='#00995d' />
              Solicitar Treinamento
            </ButtonNormal>
          </header>
          <TrainingCard>
            <header>
              <h3>Trainamento de Liderança</h3>
              <Tags>Video</Tags>
              <Tags>Intercâmbio de Conhecimento</Tags>
              <ButtonContainer>
                <Tooltip arrow title='Editar'>
                  <div>
                    <MdEdit size={24} />
                  </div>
                </Tooltip>
                <Tooltip arrow title='Excluir'>
                  <div>
                    <MdDelete size={24} />
                  </div>
                </Tooltip>
              </ButtonContainer>
            </header>
            <div>Participantes 3</div>
            <div>In Loco</div>
            <div>31/08/2022</div>
          </TrainingCard>
          <TrainingCard>
            <header>
              <h3>Trainamento de Liderança</h3>
              <ButtonContainer>
                <Tooltip arrow title='Editar'>
                  <div>
                    <MdEdit size={24} />
                  </div>
                </Tooltip>
                <Tooltip arrow title='Excluir'>
                  <div>
                    <MdDelete size={24} color='#ed1651' />
                  </div>
                </Tooltip>
              </ButtonContainer>
            </header>
            <div>Participantes 3</div>
            <div>In Loco</div>
            <div>31/08/2022</div>
          </TrainingCard>
          <TrainingCard>
            <header>
              <h3>Trainamento de Liderança</h3>
              <ButtonContainer>
                <Tooltip arrow title='Editar'>
                  <div>
                    <MdEdit size={24} />
                  </div>
                </Tooltip>
                <Tooltip arrow title='Excluir'>
                  <div>
                    <MdDelete size={24} />
                  </div>
                </Tooltip>
              </ButtonContainer>
            </header>
            <div>Participantes 3</div>
            <div>In Loco</div>
            <div>31/08/2022</div>
          </TrainingCard>
        </section>
        <section>
          <header>
            <h2>Treinamentos</h2>
          </header>
          <TrainingCard>
            <header>
              <h3>Trainamento de Liderança</h3>
              <ButtonContainer>
                <Tooltip arrow title='Editar'>
                  <div>
                    <MdEdit size={24} />
                  </div>
                </Tooltip>
                <Tooltip arrow title='Excluir'>
                  <div>
                    <MdDelete size={24} />
                  </div>
                </Tooltip>
              </ButtonContainer>
            </header>
            <div>Participantes 3</div>
            <div>In Loco</div>
            <div>31/08/2022</div>
          </TrainingCard>
          <TrainingCard>
            <header>
              <h3>Trainamento de Liderança</h3>
              <ButtonContainer>
                <Tooltip arrow title='Editar'>
                  <div>
                    <MdEdit size={24} />
                  </div>
                </Tooltip>
                <Tooltip arrow title='Excluir'>
                  <div>
                    <MdDelete size={24} />
                  </div>
                </Tooltip>
              </ButtonContainer>
            </header>
            <div>Participantes 3</div>
            <div>In Loco</div>
            <div>31/08/2022</div>
          </TrainingCard>
          <TrainingCard>
            <header>
              <h3>Trainamento de Liderança</h3>
              <ButtonContainer>
                <Tooltip arrow title='Editar'>
                  <div>
                    <MdEdit size={24} />
                  </div>
                </Tooltip>
                <Tooltip arrow title='Excluir'>
                  <div>
                    <MdDelete size={24} />
                  </div>
                </Tooltip>
              </ButtonContainer>
            </header>
            <div>Participantes 3</div>
            <div>In Loco</div>
            <div>31/08/2022</div>
          </TrainingCard>
        </section>
      </MainSection>
    </MainContainer>
  );
}

export default memo(TrainingRequests);
