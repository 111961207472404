import React, { memo } from 'react';

import PropTypes from 'prop-types';

import Checkbox from '~/components/Checkbox';

import { toCamelCase } from '../../functions/toCamelCase';
import { Container, Tree, RootNotLeafContainer } from './styles';

function TreeView({ nodes, checked, setChecked }) {
  const removeArrayItem = (array, item) => {
    const index = array.indexOf(item);
    if (index > -1) {
      array.splice(index, 1);
    }

    return array;
  };

  return (
    <Container>
      {nodes &&
        nodes.map((node) => (
          <Tree key={node.value} content={node.label}>
            {node.leaf ? (
              <Checkbox
                name={node.label}
                label={node.label}
                defaultChecked={checked.includes(node.value)}
                onClick={(chk) => {
                  if (chk.target.checked) {
                    setChecked([...checked, node.value]);
                  } else {
                    setChecked(removeArrayItem(checked, node.value));
                  }
                }}
              />
            ) : (
              <RootNotLeafContainer>{toCamelCase(node.label.toLowerCase())}</RootNotLeafContainer>
            )}
            {node.children.map((childrenNode) => (
              <Tree key={childrenNode.value} content={childrenNode.label}>
                {childrenNode.leaf ? (
                  <Checkbox
                    name={childrenNode.label}
                    label={childrenNode.label}
                    defaultChecked={checked.includes(childrenNode.value)}
                    onClick={(chk) => {
                      if (chk.target.checked) {
                        setChecked([...checked, childrenNode.value]);
                      } else {
                        setChecked(removeArrayItem(checked, childrenNode.value));
                      }
                    }}
                  />
                ) : (
                  <RootNotLeafContainer>{toCamelCase(childrenNode.label.toLowerCase())}</RootNotLeafContainer>
                )}
                {childrenNode.children.map((grandsonNode) => (
                  <Tree key={grandsonNode.value} content={grandsonNode.label}>
                    {grandsonNode.leaf ? (
                      <Checkbox
                        name={grandsonNode.label}
                        label={grandsonNode.label}
                        defaultChecked={checked.includes(grandsonNode.value)}
                        onClick={(chk) => {
                          if (chk.target.checked) {
                            setChecked([...checked, grandsonNode.value]);
                          } else {
                            setChecked(removeArrayItem(checked, grandsonNode.value));
                          }
                        }}
                      />
                    ) : (
                      <RootNotLeafContainer>{toCamelCase(grandsonNode.label.toLowerCase())}</RootNotLeafContainer>
                    )}
                    {grandsonNode.children.map((greatGrandsonNode) => (
                      <Tree key={greatGrandsonNode.value} content={greatGrandsonNode.label}>
                        {greatGrandsonNode.leaf ? (
                          <Checkbox
                            name={greatGrandsonNode.label}
                            label={greatGrandsonNode.label}
                            defaultChecked={checked.includes(greatGrandsonNode.value)}
                            onClick={(chk) => {
                              if (chk.target.checked) {
                                setChecked([...checked, greatGrandsonNode.value]);
                              } else {
                                setChecked(removeArrayItem(checked, greatGrandsonNode.value));
                              }
                            }}
                          />
                        ) : (
                          <RootNotLeafContainer>{toCamelCase(greatGrandsonNode.label.toLowerCase())}</RootNotLeafContainer>
                        )}
                        {greatGrandsonNode.children.map((greatGreatGrandsonNode) => (
                          <Tree
                            key={greatGreatGrandsonNode.value}
                            content={greatGreatGrandsonNode.label}
                            type='item'
                            open
                            canHide>
                            {greatGreatGrandsonNode.leaf ? (
                              <Checkbox
                                name={greatGreatGrandsonNode.label}
                                label={greatGreatGrandsonNode.label}
                                defaultChecked={checked.includes(greatGreatGrandsonNode.value)}
                                onClick={(chk) => {
                                  if (chk.target.checked) {
                                    setChecked([...checked, greatGreatGrandsonNode.value]);
                                  } else {
                                    setChecked(removeArrayItem(checked, greatGreatGrandsonNode.value));
                                  }
                                }}
                              />
                            ) : (
                              <RootNotLeafContainer>{greatGreatGrandsonNode.label}</RootNotLeafContainer>
                            )}
                          </Tree>
                        ))}
                      </Tree>
                    ))}
                  </Tree>
                ))}
              </Tree>
            ))}
          </Tree>
        ))}
    </Container>
  );
}

TreeView.propTypes = {
  nodes: PropTypes.instanceOf(Object).isRequired,
  checked: PropTypes.instanceOf(Array).isRequired,
  setChecked: PropTypes.func.isRequired,
};

export default memo(TreeView);
