import styled from 'styled-components';

export default styled.div`
  background-image: linear-gradient(-90deg, #c4cbcf 0%, #eee 50%, #c4cbcf 100%) !important;

  background-size: 400% 400%;
  animation: shimmer 0.7s ease-in-out infinite !important;

  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;
