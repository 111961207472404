import React, { memo } from 'react';

import Pagination from '@material-ui/lab/Pagination';
import AnimatedNumber from 'animated-number-react';
import PropTypes from 'prop-types';

import { Container } from './styles';

function Page({ page, setPage, lastPage, total }) {
  return (
    <Container>
      <Pagination count={lastPage} page={page} onChange={(event, newPage) => setPage(newPage)} shape='round' />
      <section className='total'>
        TOTAL: <AnimatedNumber value={total} formatValue={(value) => parseInt(value, 10)} />
      </section>
    </Container>
  );
}

Page.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  lastPage: PropTypes.number.isRequired,
  total: PropTypes.number,
};

Page.defaultProps = {
  total: 0,
};

export default memo(Page);
