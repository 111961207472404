import React, { memo } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import PropTypes from 'prop-types';

import AuthLayout from '~/pages/_layouts/auth';
import DefaultLayout from '~/pages/_layouts/default';
import { store } from '~/store';

import * as RouteItems from './config';

function RouteWrapper({ component: Component, isPrivate, isAdmin, ...rest }) {
  const { signed } = store.getState().auth;
  const admin = useSelector((state) => state.user.admin);
  const roles = useSelector((state) => state.user?.profile?.roles);

  if (!signed && isPrivate) {
    return <Redirect to='/' />;
  }

  if (signed && !isPrivate) {
    if (roles.includes('administrator')) {
      return <Redirect to={RouteItems.DASHBOARD} />;
    }
    if (roles.includes('evaluator')) {
      return <Redirect to={RouteItems.LEADER_DASHBOARD} />;
    }
    if (roles.includes('evaluated')) {
      return <Redirect to={RouteItems.EMPLOYEE_DASHBOARD} />;
    }
  }

  if (isAdmin && !admin && !roles.includes('administrator')) {
    if (roles.includes('evaluator')) {
      return <Redirect to={RouteItems.LEADER_DASHBOARD} />;
    }

    if (roles.includes('evaluated')) {
      return <Redirect to={RouteItems.EMPLOYEE_DASHBOARD} />;
    }
  }

  const Layout = signed ? DefaultLayout : AuthLayout;

  function LayoutWithScroll(props) {
    return (
      <Layout>
        <Scrollbars>
          <Component {...props} />
        </Scrollbars>
      </Layout>
    );
  }
  function LayoutWithoutScroll(props) {
    return (
      <Layout>
        <Component {...props} />
      </Layout>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return signed ? LayoutWithScroll(props) : LayoutWithoutScroll(props);
      }}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  isAdmin: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.any]).isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  isAdmin: false,
};

export default memo(RouteWrapper);
