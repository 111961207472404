import React, { useState, useEffect, useMemo, memo } from 'react';
import { MdNotifications } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { parseISO, formatDistance } from 'date-fns';
import pt from 'date-fns/locale/pt';

import api from '~/services/api';
import ws from '~/services/websocket';

import { Container, Badge, NotificationList, Scroll, Notification, WithoutNoficiationContainer } from './styles';

function Notifications() {
  const [visible, setVisible] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const profile = useSelector((state) => state.user.profile);

  useEffect(() => {
    const channel = ws.getSubscription(`notification:${profile.id}`) || ws.subscribe(`notification:${profile.id}`);
    channel.on('message', (notification) =>
      setNotifications((not) => [
        ...not,
        {
          ...notification,
          timeDistance: formatDistance(parseISO(notification.created_at), new Date(), { addSuffix: true, locale: pt }),
        },
      ]),
    );
  }, [profile.id]);

  const hasUnread = useMemo(() => !!notifications.find((notification) => !notification.read), [notifications]);

  useEffect(() => {
    async function loadNotifications() {
      const { data } = await api.get(`notifications`);
      try {
        const notificationList = data
          ?.map((notification) => ({
            ...notification,
            timeDistance: formatDistance(parseISO(notification.created_at), new Date(), { addSuffix: true, locale: pt }),
          }))
          .filter((notification) => !notification.hidden);
        setNotifications(notificationList);
      } catch (error) {
        toast.error(`Falha ao verificar notificações! ${error.message}`);
      }
    }

    loadNotifications();
  }, []);

  function handleToggleVisible() {
    setVisible(!visible);
  }

  async function handleCheckAction(id, type) {
    await api.put(`notifications/${id}`, { [type]: true });

    setNotifications(
      notifications
        .map((notification) => (notification.id === id ? { ...notification, [type]: true } : notification))
        .filter((notification) => !notification.hidden),
    );
  }

  return (
    <Container>
      <Badge onClick={handleToggleVisible} hasUnread={hasUnread}>
        <MdNotifications color='#fff0c7' size={20} />
      </Badge>
      <NotificationList visible={visible}>
        <Scroll>
          {notifications.length > 0 ? (
            notifications.map(
              (notification) =>
                !notification.hidden && (
                  <Notification key={notification.id} unread={!notification.read}>
                    <p>{notification.content}</p>
                    <time>{notification.timeDistance}</time>
                    {!notification.read ? (
                      <button type='button' onClick={() => handleCheckAction(notification.id, 'read')}>
                        Marcar como lida
                      </button>
                    ) : (
                      <button
                        type='button'
                        className='hidden-button'
                        onClick={() => handleCheckAction(notification.id, 'hidden')}>
                        Remover
                      </button>
                    )}
                  </Notification>
                ),
            )
          ) : (
            <WithoutNoficiationContainer>NÃO HÁ NOTIFICAÇÕES</WithoutNoficiationContainer>
          )}
        </Scroll>
      </NotificationList>
    </Container>
  );
}

export default memo(Notifications);
