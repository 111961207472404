import React, { memo } from 'react';

import PropTypes from 'prop-types';

import { Button } from './styles';

const ButtonNormal = ({ children, text, color, background, ...rest }) => {
  return (
    <Button type='button' color={color} background={background} {...rest}>
      {children ?? text}
    </Button>
  );
};

ButtonNormal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  color: PropTypes.string,
  background: PropTypes.string,
  text: PropTypes.string,
};

ButtonNormal.defaultProps = {
  children: null,
  color: '#fff',
  background: '#00995d',
  text: null,
};

export default memo(ButtonNormal);
