import React, { useRef, useEffect, memo } from 'react';

import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import { Input as InputElement, Container } from './styles';

function Input({ name, label, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue = '', registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      {label && <label htmlFor={fieldName}>{label}</label>}
      <InputElement id={fieldName} ref={inputRef} defaultValue={defaultValue} {...rest} />
      {error && <span className='input-error'>{error}</span>}
    </Container>
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

Input.defaultProps = {
  label: null,
};

export default memo(Input);
