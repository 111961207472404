import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    color: #333;
    margin-bottom: 5px;
  }
`;

export const Input = styled.input`
  background: rgba(155, 155, 155, 0.2);
  border: 0;
  border-radius: 4px;
  height: 44px;
  padding: 0 15px;
  color: #333;
  /* margin: 0 0 10px; */

  &::placeholder {
    color: #888;
  }

  &:focus {
    background: rgba(200, 200, 200, 0.8);
  }
`;
