import { MdClose } from 'react-icons/md';

import styled from 'styled-components';

export const Container = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
`;

export const ModalWrapper = styled.div`
  width: 800px;
  max-height: calc(100vh - 60px);
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
  border-radius: 12px;

  @media (max-width: 799px) {
    width: calc(100vw - 40px);
  }
`;

export const ModalHeader = styled.header`
  width: 100%;
  display: flex;
  flex: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 8px 15px;
  border-radius: 10px 10px 0 0;
  color: #333;
  border-bottom: 1px dashed #ccc;

  section {
    display: flex;
    flex-direction: column;

    small {
      color: #777;
      font-size: 300;
    }
  }
`;

export const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.2s ease-in;

  &:hover {
    background: #ed1651;
    color: #fff;
  }
`;

export const ModalContainer = styled.section`
  padding: 10px 15px 15px 15px;
  color: #333;
  width: 100%;
  height: 100%;
`;
