import styled from 'styled-components';

export const FormContainer = styled.div`
  height: ${(props) => (props.showForm ? '120px' : '0')};
  opacity: ${(props) => (props.showForm ? '1' : '0')};
  transition: height 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 2s ease;

  form {
    margin-top: 10px;
    display: ${(props) => (props.showForm ? 'grid' : 'none')};

    grid-template-columns: 100%;
    grid-template-areas: 'form' 'table';

    grid-template-columns: 33% 33% 33%;
    grid-column-gap: 10px;
    grid-gap: 10px;

    .hidden-input {
      display: none;
    }
    .hidden-input:target {
      display: block;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;
