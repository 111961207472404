import { combineReducers } from 'redux';

import auth from './auth/reducer';
import cycle from './cycle/reducer';
import modal from './modal/reducer';
import user from './user/reducer';
import websocket from './websocket/reducer';

export default combineReducers({
  auth,
  cycle,
  user,
  modal,
  websocket,
});
