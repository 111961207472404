import React, { memo, useState, useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { MdThumbUp, MdThumbDown, MdPlaylistAdd } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { Scope } from '@unform/core';
import { Form as UnForm } from '@unform/web';
import PropTypes from 'prop-types';

import { ButtonNormal, CustomRadio, Input, TextBox, Rating, DatePicker } from '~/components';
import { getRandomInt, isObjectEmpty } from '~/functions';
import useToggle from '~/hooks/useToggle';
import api from '~/services/api';
import { toggleModalRequest } from '~/store/modules/modal/actions';

import { Container, Details } from './styles';

const Form = ({ employeeId, initialData: initialEmployeeData, refreshData }) => {
  const [initialData, setInitialData] = useState({});
  const [sendingData, toggleSendingData] = useToggle();
  const [entityInProcess, setEntityInProcess] = useState({});
  const [showDeleteConfirm, toggleDeletePopConfirm] = useToggle();
  const [showSuccessModal, toggleSuccessModal] = useToggle();
  const [showPdiContainer, setShowPdiContainer] = useState(false);
  const profile = useSelector((state) => state.user.profile);
  const dispatch = useDispatch();

  const options = [
    { id: 0, value: 0, title: 'Feedback de Desenvolvimento', svg: <MdThumbDown size={20} /> },
    { id: 1, value: 1, title: 'Feedback Positivo', svg: <MdThumbUp size={20} /> },
  ];

  const visibleOptions = [
    { id: 1, value: 0, title: 'Não disponibilizar ao colaborador' },
    { id: 2, value: 1, title: 'Disponibilizar ao colaborador' },
  ];

  const addActionPlanHandler = () => {
    const newActionPlan = {
      id: null,
      fake_id: `_${getRandomInt(100000)}.${getRandomInt(100000)}.${getRandomInt(100000)}.${getRandomInt(100000)}`,
      leader_id: profile.id,
      employee_id: employeeId,
    };

    setInitialData((data) => ({
      ...data,
      continuousFeedbackDevelopmentPlans: [...data.continuousFeedbackDevelopmentPlans, newActionPlan],
    }));
  };

  useEffect(() => {
    if (!isObjectEmpty(initialEmployeeData)) {
      setInitialData(initialEmployeeData);
      if (initialEmployeeData.continuousFeedbackDevelopmentPlans.length > 0) {
        setShowPdiContainer(true);
      }
    }
  }, [initialEmployeeData]);

  const handleSuccessButton = () => {
    toggleSuccessModal();
    dispatch(
      toggleModalRequest({
        title: null,
        entity_description: null,
        content: null,
        visible: false,
      }),
    );
  };

  const removeActionPlanHandler = async () => {
    if (entityInProcess.id || entityInProcess.fake_id) {
      try {
        if (entityInProcess.id) {
          await api.delete(`continuousfeedbackdevelopmentplan/${entityInProcess.id}`);
        }

        setInitialData((data) => ({
          ...data,
          continuousFeedbackDevelopmentPlans: data.continuousFeedbackDevelopmentPlans.filter(
            (action) => action.fake_id !== entityInProcess.fake_id,
          ),
        }));
        toggleDeletePopConfirm();
        refreshData();
      } catch (err) {
        toast.error(err.message);
      }
    }
  };

  const submitHandle = async (data) => {
    toggleSendingData();

    if (Number(data.category[0]) === 1) {
      delete data.continuousFeedbackDevelopmentPlans;
    }

    try {
      if (data.id) {
        await api.put(`continuousfeedback/${data.id}`, { ...data });
      } else {
        await api.post('continuousfeedback', { ...data });
      }
      refreshData();
      toggleSuccessModal();
    } catch (error) {
      toast.error(error.message);
    } finally {
      toggleSendingData();
    }
  };

  return (
    <Container>
      <UnForm onSubmit={submitHandle} initialData={initialData}>
        <Input name='id' type='hidden' />
        <Input name='employee_id' type='hidden' />
        <p>Tipo de Feedback</p>
        <CustomRadio name='category' options={options} required onClick={(e) => setShowPdiContainer(!+e.target.value)} />
        <TextBox name='description' label='Descrição' required />
        <p>Deseja disponibilizar este feedback na página do colaborador?</p>
        <Rating name='visible_to_employee' options={visibleOptions} required />
        {showPdiContainer && (
          <section className='pdi-container'>
            <header>
              <h4>Plano de Ação</h4>
              <ButtonNormal background='#ddd' color='#333' onClick={addActionPlanHandler}>
                <MdPlaylistAdd size={24} color='#00995d' />
                Adicionar Plano
              </ButtonNormal>
            </header>
            <div className='action-plan-list-container'>
              {initialData?.continuousFeedbackDevelopmentPlans?.length > 0 &&
                initialData?.continuousFeedbackDevelopmentPlans.map((plan, index) => (
                  <Scope path={`continuousFeedbackDevelopmentPlans[${index}]`} key={plan.fake_id}>
                    <Details open>
                      <summary>
                        Novo plano de ação{' '}
                        <button
                          type='button'
                          onClick={() => {
                            setEntityInProcess({ id: plan.id, fake_id: plan.fake_id });
                            toggleDeletePopConfirm();
                          }}>
                          Remover
                        </button>
                      </summary>
                      <Input type='hidden' name='id' />
                      <Input type='hidden' name='employee_id' />
                      <TextBox
                        name='action'
                        rows={2}
                        max-length={2000}
                        placeholder='Insira aqui a descrição do plano de ação'
                        className='action-description'
                        label='Ação (Obrigatório)'
                        required
                      />
                      <div className='date-picker-container'>
                        <DatePicker
                          id='initial_date'
                          name='initial_date'
                          dateFormat='dd/MM/yyyy'
                          type='keyboardDate'
                          onBlur={() => null}
                          label='Início (Obrigatório)'
                          required
                        />
                        <DatePicker
                          id='final_date'
                          name='final_date'
                          dateFormat='dd/MM/yyyy'
                          type='keyboardDate'
                          onBlur={() => null}
                          label='Previsão de Término (Obrigatório)'
                          required
                        />
                      </div>
                    </Details>
                  </Scope>
                ))}
            </div>
          </section>
        )}
        <ButtonNormal type='submit'>{sendingData ? 'Aguarde... Salvando dados!' : 'Salvar'}</ButtonNormal>
      </UnForm>

      <SweetAlert
        confirmBtnText='Excluir'
        cancelBtnText='Cancelar'
        title='Excluir item?'
        onConfirm={removeActionPlanHandler}
        onCancel={toggleDeletePopConfirm}
        showCancel
        show={showDeleteConfirm}
        confirmBtnCssClass='delete-confirm-button'
        cancelBtnCssClass='delete-cancel-button'
      />

      <SweetAlert
        title='Operação realizada com sucesso'
        success
        confirmBtnCssClass='delete-confirm-button'
        onConfirm={handleSuccessButton}
        show={showSuccessModal}
      />
    </Container>
  );
};

Form.propTypes = {
  employeeId: PropTypes.string.isRequired,
  initialData: PropTypes.instanceOf(Object),
  refreshData: PropTypes.func,
};

Form.defaultProps = {
  initialData: {},
  refreshData: () => {},
};

export default memo(Form);
