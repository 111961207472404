import styled from 'styled-components';

export const Container = styled.div`
  max-width: calc(100vw - 129px);
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 0 20px 0;

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 0 15px 15px 15px;
  }

  @media (max-width: 1220px) {
    padding: 0 20px 20px 20px;
  }

  .delete-confirm-button {
    border: none;
    background: #00995d;
    padding: 7px 15px;
    box-shadow: none !important;
    border-radius: 4px;
    color: #fff;
  }

  .delete-cancel-button {
    color: #ed1651;
    margin-right: 25px !important;
  }

  .sweet-alert {
    h2 {
      font-size: 2rem !important;
      color: #333 !important;
      padding-top: 10px !important;
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  form {
    width: 100%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 210px;
  min-width: 210px;

  @media (max-width: 768px) {
    min-width: 100%;
  }

  button {
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    margin: 0 10px 10px 0;
    color: #222;
    cursor: pointer;
    border: none;
    font-size: 1.1em;
    transition: background 0.3s;

    @media (max-width: 768px) {
      margin: 0 0 10px 0;
    }

    &:hover {
      color: #fff;
      background: #5b5c65;
    }
  }

  button.active {
    color: #fff;
    background: #5b5c65;

    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 13px;
      right: -8px;
      width: 0;
      height: 0;
      border-left: 8px solid #5b5c65;
      border-bottom: 8px solid transparent;
      border-top: 8px solid transparent;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  div.options-skill-title {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background: rgba(255, 203, 8, 1);
    margin: 0 10px 12px 0;
    color: #682d00;
    font-size: 15px;
    border-radius: 4px;

    &::before {
      content: '';
      position: absolute;
      top: 28px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid rgba(255, 203, 8, 1);
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fff;
  padding: 8px 15px;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 10px;
  min-height: 51px;
  width: 100%;

  h3 {
    color: #222;
    text-transform: uppercase;
  }

  span {
    color: #999;
    font-size: 12px;
  }

  .svg-report {
    margin-right: 5px;
  }

  section {
    width: 100%;
  }

  .right-button-container {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    justify-content: flex-end;

    > button,
    a {
      width: 100%;
      max-width: 180px;
      margin-left: 10px;
    }
  }

  @media (max-width: 768px) {
  }
`;

export const SkillContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 5px;
  justify-content: space-evenly;
  background: #fff;
  padding: 15px;
  border-radius: 5px;
  display: ${(props) => (props.show ? 'flex' : 'none')};

  div.skill_name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    button {
      margin-left: 15px;
      max-width: 153px;
    }
  }

  h2 {
    color: #222;
    margin-bottom: 5px;
    border-bottom: 1px solid #ddd;
    width: 100%;
  }

  span.description {
    font-size: 1.7rem;
    color: #222;
  }
`;

export const AnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 10px;

  h4 {
    font-size: 1.1em;
    text-align: justify;
    color: #111;
    /* border-left: 3px solid #444; */
    padding: 5px 10px 6px 10px;
    background: rgba(200, 200, 200, 0.6);
    /* border-radius: 4px; */
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  label {
    font-size: 1.1em;
    color: #000;
  }

  textarea {
    margin-bottom: 0px !important;
    border-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export const RatingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const MobileInstructionContainer = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;

    div.mobile-info {
      border-left: 4px solid #ffcb08;
      padding: 5px 5px 5px 10px;
      font-size: 14px;
      background: rgba(255, 203, 8, 0.2);
      color: #333;
    }
  }
`;

export const FieldsContainer = styled.div`
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 5px;
  border: ${(props) => (props.show ? 'transparent' : '1px solid #ddd')};
  border-radius: 4px;
  padding: 10px;
  height: ${(props) => (props.show ? '415px' : '59px')};
  background: ${(props) => (props.show ? 'rgba(200,200,200,0.2)' : 'transparent')};
  transition: height 0.5s ease-in, background 0.6s, border 0.6s;

  @media (max-width: 768px) {
    height: ${(props) => (props.show ? '385.81px' : '58px')};
  }

  h4.development-plan-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-column: 1/3;
    cursor: pointer;
    background: transparent;

    span {
      /* width: 80%; */
      border-bottom: ${(props) => (props.show ? '1px solid #ddd' : 'none')};
    }

    button {
      border: none;
      margin-left: 5px;
      background: none;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .development-plan-description {
    color: #666;
    font-weight: 300;
  }

  .action-description {
    grid-column: 1/3;
  }

  .expected {
    grid-column: 1/3;
  }


  .react-select-unimed__menu {
    z-index: 9999 !important;
  }

  .select-container {
    display: flex;
    flex-direction: column;
    grid-column: 1/3;
    margin-bottom: 10px;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      margin-bottom: 10px;

      gap: 15px;
    }
  }
`;
